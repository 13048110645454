import React,{useState,useEffect} from 'react'

import {BsFillGridFill,BsListUl} from "react-icons/bs";
import Challimg from "../../../Assets/Entity/Challenges/1111.png";
import Logo from "../../../Assets/Landing page/Group 36.svg";
import Labsimg2 from "../../../Assets/Entity/Lab/Challenges_-_Podium_c.png"
import {MdOutlineArrowForwardIos} from "react-icons/md";
import axios from 'axios';
import { NavLink, useNavigate,Link, useParams} from 'react-router-dom';
import Active from "../../../Assets/Entity/Challenges/Active.svg"

import vide from "../../../Assets/Dashboard/video.mp4";
import vide2 from "../../../Assets/Dashboard/prey ground image.png";
import vid from "../../../Assets/Dashboard/f.png";
import vid2 from "../../../Assets/Dashboard/h.png"
import vid3 from "../../../Assets/Dashboard/g.png"
import vid4 from "../../../Assets/Dashboard/e.png"
import insecure from "../../../Assets/Dashboard/Insecure design (img).png";
import Brokenacess from "../../../Assets/Placeit/Images/4.png";
import cryptographic from "../../../Assets/Placeit/Images/1.png";
import csrf from "../../../Assets/Placeit/Images/csrf.png";
import identiy from "../../../Assets/Placeit/Images/identity and authentication failure.png";
import insecures from "../../../Assets/Placeit/Images/2 2.png";
import securitylogging from "../../../Assets/Placeit/Images/security logging.png";
import securitymisconf from "../../../Assets/Placeit/Images/Security misconfiguration.png";
import software from "../../../Assets/Placeit/Images/software and data integrity failure.png";
import sqlinjection from "../../../Assets/Placeit/Images/3.png";
import ssrf from "../../../Assets/Placeit/Images/XSS.png";
import ssti from "../../../Assets/Placeit/Images/ssti.png";
import xss from "../../../Assets/Placeit/Images/2.png";
import one from "../../../Assets/Placeit/Images/4.png";
import two from "../../../Assets/Placeit/Images/triix.png";
import three from "../../../Assets/Placeit/Images/Haze.png"
import LazyLoad from "react-lazyload";
import { useLocation } from "react-router-dom";
import Loading from '../Modals/Loading2';

const Challenges = (props) => {
  const params=useParams()
    const [view, setview] = useState("Grid");
    const [slideIndex, setSlideIndex] = useState(2)
    const[challenges,setchallenges]=useState([]);
    const[modal,setopenmodal]=useState(false);

   
const navigate=useNavigate();
const API_Key = process.env.REACT_APP_API_URL;
let courseid = params.course_id;


useEffect(() => {
  setopenmodal(true);
     const fetchCategories = async () => {
      await axios
      .post(`${API_Key}/gotoCourse/listCategories/`, {
        
        courseid:courseid,
      },{
        headers:{
          mode: 'cors',
          'Access-Control-Allow-Origin':`${API_Key}`,
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': true,
        },
        credentials:"include"
      })
      .then((res) =>{ 
        
          // Reorder categories with "Premium" category last
          const sortedCategories = res.data.allCategories.sort((a, b) => {
            if (a.title.toLowerCase() === 'premium') return 1;
            if (b.title.toLowerCase() === 'premium') return -1;
            return 0;
          });
  
          setchallenges(sortedCategories);
      if(res.status===200){
        setopenmodal(false);
      }
      })
      .catch((err) => console.log(err));
     };
     fetchCategories();
   }, []);
  // let chall_id=props.params.match.chall_id
   
    

  return (
    <div>
{modal &&  <section className="modalBackground3 justify-content-center">

<div className="container  justify-content-center">

  <div className="row justify-content-center " >
  
  <img src={vide2} alt="jhjs" className='challengecarimg'/>
</div>
</div> 
</section>}
   

    <div className='Entity_background p-1'>
    <div className='Entitycommontopper'>
    <div className='container pb-2'>
   <div className='row justify-content-center pb-5'>
   <div className='col-lg-8 col-md-8 col-12 '>
   <h1 className='Entitycommonhead'>Challenges<span><hr className='Entitycommonheadhr'></hr></span></h1>
   <p className='Entitycommonheadpara'>Just as you would not build a house without solid foundations,  you cannot expect to thrive in cybersecurity without having a strong hold on basics. Find these Challenges as opportunities to ace up your basic hacking foundation, and learn the ins and outs of cyber security. This is the time to understand the machine vulnerabilities and witness next-level experience.</p>
   
   </div>
   <div className='col-lg-3 col-md-4 col-12   mt-4 mb-0 offset-1'>
   <video width="230" height="200" autoPlay loop style={{transform:"scale(1.8)"}}>
   <source src={vide} type="video/mp4"/>
  </video>
  
 
 
   
   </div>
    
    </div>
   
    </div>
    
   </div>
  <div className='container '>
  <div className='row float-right'>
  <div className='col-lg-10 ' id='viewdiv'>
 

 <div className='row justify-content-center '>
  <div className='col-5'><button className='btn' onClick={() => setview("Grid")}><BsFillGridFill color={view==="Grid"?"white":"grey"} size={15}/></button> </div>
  <div className='col-7'> <button  className='btn' onClick={() => setview("List")}><BsListUl  color={view==="List"?"white":"grey"} size={15}/></button> </div>
  </div>

  
 
  </div>
  </div>
  <div className='container mt-5 '>
  {view === "Grid" && 
  <div class="container p-5 mt-5">
    <div class="row mt-5">
{challenges.map((challenge,id)=>( 
   
    <div className='col-lg-3 col-md-3 col-12 mt-5' key={id}>
     <div className="challengegridcard mt-5">
     <Link style={{textDecoration:"none"}} to={`/challengebycat/${challenge._id}`}
      state= {{courseid:courseid}}
     > <div className='row justify-content-center p-5' >
     <div className='col-lg-12 ' id='cardimgback55'>
     
     <div className='cardimback26 text-center'>
     <img src={challenge.image} style={{height:"17rem",transform:"Scale(1.5)"}} alt="alt" />
     </div>
     
     </div>
     <h2 className='text-center cardchalhead mt-5 '>{challenge.title}</h2>
     {/*<p className='text-center cardchalpara'>{challenge.desc}</p>*/}
    
     </div>
     </Link>
     </div>
     
  </div>


  ))}
      
        
       
  
     
         
       
        

         </div>
        
       {/* <LazyLoad >
         <div className='row mt-5'>
         <div className='col-lg-3 col-md-3 col-10 mt-5'>
       <div className="challengegridcard mt-5">
       <div className='row justify-content-center p-5' >
       <div className='col-lg-12 ' >
       
     
       <img src={two} style={{height:"17rem",transform:"Scale(1.5)"}} alt="alt" />
    
       
       </div>
       <h2 className='text-center cardchalhead mt-5'>TRIX</h2>
       <p className='text-center cardchalpara mt-2'>unchanged. It was popularised in the 1960s with t</p>
      
       </div>
       </div>
       </div>
       <div className='col-lg-3 col-md-3 col-10 mt-5'>
       <div className="challengegridcard mt-5">
       <div className='row justify-content-center p-5' >
       <div className='col-lg-12  col-md-12 col-12 justify-content-center' id='testghg' >
       
     
       <img src={three} style={{height:"17rem",transform:"Scale(1.5)"}} alt="alt" />
    
       
       </div>
       <h2 className='text-center cardchalhead mt-5'>Cryptographic Failure</h2>
       <p className='text-center cardchalpara mt-2'>unchanged. It was popularised in the 1960s with t</p>
      
       </div>
       </div>
       </div>
       <div className='col-lg-3 col-md-3 col-10 mt-5'>
       <div className="challengegridcard mt-5">
       <div className='row justify-content-center p-5' >
       <div className='col-lg-12 ' >
       
     
       <img src={one} style={{height:"17rem",transform:"Scale(1.5)"}} alt="alt" />
    
       
       </div>
       <h2 className='text-center cardchalhead mt-5 '>SQL Injection</h2>
       <p className='text-center cardchalpara mt-2'>unchanged. It was popularised in the 1960s with t</p>
      
       </div>
       </div>
       </div>
       <div className='col-lg-3 col-md-3 col-10 mt-5'>
       <div className="challengegridcard mt-5">
       <div className='row justify-content-center p-5' >
       <div className='col-lg-12 ' >
       
     
       <img src={xss} style={{height:"17rem",transform:"Scale(1.5)"}} alt="alt" />
    
       
       </div>
       <h2 className='text-center cardchalhead mt-5 '>CSRF</h2>
       <p className='text-center cardchalpara mt-2'>unchanged. It was popularised in the 1960s with t</p>
      
       </div>
       </div>
       </div>
       <div className='col-lg-3 col-md-3 col-10 mt-5'>
       <div className="challengegridcard mt-5">
       <div className='row justify-content-center p-5' >
       <div className='col-lg-12 ' >
       
     
       <img src={sqlinjection} style={{height:"17rem",transform:"Scale(1.5)"}} alt="alt" />
    
       
       </div>
       <h2 className='text-center cardchalhead mt-5 '>Identity & authnication failure</h2>
       <p className='text-center cardchalpara mt-2'>unchanged. It was popularised in the 1960s with t</p>
      
       </div>
       </div>
       </div>
       <div className='col-lg-3 col-md-3 col-10 mt-5'>
       <div className="challengegridcard mt-5">
       <div className='row justify-content-center p-5' >
       <div className='col-lg-12 ' >
       
     
       <img src={insecures} style={{height:"17rem",transform:"Scale(1.5)"}} alt="alt" />
    
       
       </div>
       <h2 className='text-center cardchalhead mt-5 '>insecure design</h2>
       <p className='text-center cardchalpara mt-2'>unchanged. It was popularised in the 1960s with t</p>
      
       </div>
       </div>
       </div>
       <div className='col-lg-3 col-md-3 col-10 mt-5'>
       <div className="challengegridcard mt-5">
       <div className='row justify-content-center p-5' >
       <div className='col-lg-12 ' >
       
     
       <img src={securitymisconf} style={{height:"17rem",transform:"Scale(1.5)"}} alt="alt" />
    
       
       </div>
       <h2 className='text-center cardchalhead mt-5 '>Security Misconfiguration</h2>
       <p className='text-center cardchalpara mt-2'>unchanged. It was popularised in the 1960s with t</p>
      
       </div>
       </div>
       </div>
       <div className='col-lg-3 col-md-3 col-10 mt-5'>
       <div className="challengegridcard mt-5">
       <div className='row justify-content-center p-5' >
       <div className='col-lg-12 ' >
       
     
       <img src={securitylogging} style={{height:"17rem",transform:"Scale(1.5)"}} alt="alt" />
    
       
       </div>
       <h2 className='text-center cardchalhead mt-5 '>SQL Injection</h2>
       <p className='text-center cardchalpara mt-2'>unchanged. It was popularised in the 1960s with t</p>
      
       </div>
       </div>
       </div>
       <div className='col-lg-3 col-md-3 col-10 mt-5'>
       <div className="challengegridcard mt-5">
       <div className='row justify-content-center p-5' >
       <div className='col-lg-12 ' >
       
     
       <img src={ssti} style={{height:"17rem",transform:"Scale(1.5)"}} alt="alt" />
    
       
       </div>
       <h2 className='text-center cardchalhead mt-5 '>SQL Injection</h2>
       <p className='text-center cardchalpara mt-2'>unchanged. It was popularised in the 1960s with t</p>
      
       </div>
       </div>
       </div>
       <div className='col-lg-3 col-md-3 col-10 mt-5'>
       <div className="challengegridcard mt-5">
       <div className='row justify-content-center p-5' >
       <div className='col-lg-12 ' >
       
     
       <img src={software} style={{height:"17rem",transform:"Scale(1.5)"}} alt="alt" />
    
       
       </div>
       <h2 className='text-center cardchalhead mt-5 '>SQL Injection</h2>
       <p className='text-center cardchalpara mt-2'>unchanged. It was popularised in the 1960s with t</p>
      
       </div>
       </div>
       </div>
       <div className='col-lg-3 col-md-3 col-10 mt-5'>
       <div className="challengegridcard mt-5">
       <div className='row justify-content-center p-5' >
       <div className='col-lg-12 ' >
       
     
       <img src={ssrf} style={{height:"17rem",transform:"Scale(1.5)"}} alt="alt" />
    
       
       </div>
       <h2 className='text-center cardchalhead mt-5 '>SQL Injection</h2>
       <p className='text-center cardchalpara mt-2'>unchanged. It was popularised in the 1960s with t</p>
      
       </div>
       </div>
       </div>
       <div className='col-lg-3 col-md-3 col-10 mt-5'>
       <div className="challengegridcard mt-5">
       <div className='row justify-content-center p-5' >
       <div className='col-lg-12 ' >
       
     
       <img src={csrf} style={{height:"17rem",transform:"Scale(1.5)"}} alt="alt" />
    
       
       </div>
       <h2 className='text-center cardchalhead mt-4 '>CSRF</h2>
       <p className='text-center cardchalpara mt-2'>unchanged. It was popularised in the 1960s with t</p>
      
       </div>
       </div>
       </div>
  
         </div>
</LazyLoad>*/}
        
</div>

  }

  {view === "List" &&<div class="container p-5 mt-5">
  <div class="row mt-5">
 
   {challenges.map((challenges,id)=>( 
     
   
    <div  className='col-lg-6 col-md-6 col-12 p-5' key={id}>
    <Link style={{textDecoration:"none"}} to={{
      pathname: "/challengebycat/" + challenges._id,
      state: {courseid:courseid}
    }} > <div className=" row challengelistcard p-5 ">
   <div className='col-lg-2 col-md-10 col-3'>
   <div className='lablogodivh'>
 <img src={challenges.image} alt="logo" style={{width:"13rem",transform:"scale(1.5)"}}/>
 </div>
   </div>
   <div className='col-lg-8 col-md-8 col--6 pt-3'><h2 className='challisthead'>{challenges.title}</h2></div>
   <div className='col-lg-1 col-3 pt-3'>
 <div className='Labbtn'>
 {/* <NavLink to={"/challengebycat/" + challenges._id} onClick={courseids}><MdOutlineArrowForwardIos  className='span' color='white' size={8} style={{marginTop:"-2.4rem"}} /></NavLink>*/}

<MdOutlineArrowForwardIos  className='span' color='white' size={8} style={{marginTop:"-2.4rem"}} />

 
 </div>
 </div>
</div> 
</Link>
</div>
))} 
 
      
  
      
       
     
       </div></div>
  }
  </div>

  </div>
  </div>

    </div>
  )
}

export default  (Challenges);