import React from "react";
import { GrFormCheckmark } from "react-icons/gr";
import { FaStar } from "react-icons/fa";
import { MdOutlineLibraryBooks } from "react-icons/md";
import { LiaHandshakeSolid, LiaBookSolid } from "react-icons/lia";
import { FaCalendar } from "react-icons/fa";
import { Formik, Field, ErrorMessage, Form } from "formik";
import Vip from "../../Assets/Dashboard/logovip.png";
import { useNavigate } from "react-router-dom";
import "./SummerTraining.css";
import "./SummerTrainingPage.css";
import axios from "axios";
import * as Yup from "yup";
import "yup-phone";
import { motion } from "framer-motion";
import { FaAws } from "react-icons/fa";
function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}

const IndustrialTrainingHeroSection = () => {
  const navigate = useNavigate();
  const API_Key = process.env.REACT_APP_API_URL;
  const API_Key2 = process.env.REACT_APP_RAZORPAY_ID;
  const initialValues = {
    firstname: "",
    email: "",
    mobilenumber: "",
    institution: "",
    duration:"6months"
  };
  const validationSchema = Yup.object({
    firstname: Yup.string().matches(/^[A-Za-z\s]+$/, "Only alphabets are allowed").required("Required"),
    email: Yup.string().email("Invalid email").required("Required"),
    mobilenumber: Yup.string()
      .phone("IN", true, "Enter valid Mobile Number")
      .required("Required"),
    institution: Yup.string().matches(/^[A-Za-z\s]+$/, "Only alphabets are allowed").required("Required"),
  });
  const handleSubmit = async (values) => {
    const data = {
      fullname: values.firstname,
      email: values.email,
      mobilenumber: values.mobienumber,
      institution: values.institution,
      type: "industrial",
      duration:values.duration
    };
    console.log(data,"dataaaaaaaaa")
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/training_Course_Createorder`,
      data
    );
  
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );
    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }
    const options = {
      key: `${API_Key2}`,
      currency: "INR",
      amount: response.data.amount,
      order_id: response.data.id,
      name: "Hackersprey",
      image: Vip,
      handler: function (response) {
        const money = {
          order_id: response.razorpay_order_id,
          payment_id: response.razorpay_payment_id,
        };
     

        const signature = {
          "x-razorpay-signature": [response.razorpay_signature],
        };

        fetch(`${API_Key}/verifyPayment_enrollCource`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Access-Control-Allow-Origin": `${API_Key}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Credentials": "true",
            "x-razorpay-signature": [response.razorpay_signature],
          },

          credentials: "include",
          body: JSON.stringify(money),
        })
          .then(async (response) => {
            if (response.status === 200) {
              navigate("/industrial-training-success");
            }
          })
          .catch((error) => {
            console.error("Error:", error);
            //setModalOpen2(true);
          })
          .catch((error) => {
            console.error("Error:", error);
            //setModalOpen2(true);
          });
      },
      prefill: {
        name: "none",
        email: "none",
        phone_number: "none",
      },
    };
    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  };

  return (
    <div className="hero-section-wrapper">
    <div className="training-hero-section">

      <div className="hero-section-left">
        <h6 className="webinar-hero-left-heading">Certified Online Training</h6>

        <motion.p
          initial={{ scale: 0.8, opacity: 0.4 }}
          whileInView={{ scale: 1, opacity: 1 }}
          transition={{ duration: 0.8, ease: "easeInOut" }}
          className="hero-heading"
        >
          3/6 Months Industrial Training In Cybersecurity
        </motion.p>
        <motion.p
          initial={{ opacity: 0.1 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 2 }}
          className="hero-subheading"
        >
          Get Job-Ready in just 6 Months with our Industrial Training
        </motion.p>
        <div className="summer-training-feat">
          <p>
            <FaStar className="herosection-icon " />
            5
          </p>

          <p>
            <MdOutlineLibraryBooks className="herosection-book" />
            Practical Learning
          </p>

          <p>
            <LiaHandshakeSolid className="herosection-place " />
            Placement Assistance
          </p>

          <p>
            <FaAws className="herosection-aws " />
            Cloud Access For Hacking Challenges
          </p>
        </div>
      </div>
      <motion.div initial={{ x: 300, opacity: 0.4 }}
        whileInView={{ x: 0, opacity: 1 }}
        viewport={{ once: true }}
        transition={{ duration: 1, ease: "easeInOut" }} className="hero-section-right ">
        <p className="hero-section-price">Become an Expert in Cyber Security</p>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {(formik) => {
          
            return (
              <Form className="webinar-form">
                {/*  {register ? <p className="webinar-success-component">Registration Successfull!!</p> : null} */}
                <Field
                  className="w-input-field2"
                  type="text"
                  id="firstname"
                  name="firstname"
                  placeHolder="Name"
                />
                <ErrorMessage
                  component="div"
                  className="webinar-error-component"
                  name="firstname"
                ></ErrorMessage>
                <Field
                  className="w-input-field2"
                  type="text"
                  id="email"
                  name="email"
                  placeHolder="Email"
                />
                <ErrorMessage
                  component="div"
                  className="webinar-error-component"
                  name="email"
                ></ErrorMessage>
                <Field
                  className="w-input-field2"
                  type="text"
                  id="mobilenumber"
                  name="mobilenumber"
                  placeHolder="Mobile Number"
                />
                <ErrorMessage
                  component="div"
                  className="webinar-error-component"
                  name="mobilenumber"
                ></ErrorMessage>
                <Field
                  className="w-input-field2"
                  type="text"
                  id="institution"
                  name="institution"
                  placeHolder="Institution/Organisation"
                />
                <ErrorMessage
                  component="div"
                  className="webinar-error-component"
                  name="institution"
                ></ErrorMessage>

                <ErrorMessage
                  component="div"
                  className="webinar-error-component"
                  name="experience"
                ></ErrorMessage>
            < div className="industrial-training-checkbox" role="group" aria-labelledby="my-radio-group">
                  <label  className="industrial-training-checkbox-wrapper">
                    <Field  type="radio" name="duration" value="3months" />
                    3 Months
                  </label>
                  <label className="industrial-training-checkbox-wrapper">
                    <Field className="ind-training-checkbox" type="radio" name="duration" value="6months" />
                    6 Months
                  </label>
                </div>

                <button
                  type="submit"
                  className="web-register-btn3"
                  disabled={!formik.isValid}
                >
                  Pay & Register
                </button>
              </Form>
            );
          }}
        </Formik>
      </motion.div>
    </div>

    </div>

  );
};

export default IndustrialTrainingHeroSection;
