import React, { useEffect, useState,useRef } from 'react'

import "./Entity.css";

import{AiOutlinePieChart,AiOutlineFieldTime,AiOutlineFlag, AiOutlineTeam} from "react-icons/ai"
import{RiPriceTag3Line} from "react-icons/ri"
import Gotolink from "../../../Assets/Entity/Lab/Go To Link (1).svg";
import Videoimg from "../../../Assets/Entity/Webfare/innerphoto3.png"
import{SlLocationPin} from "react-icons/sl"
import{TbChartInfographic} from "react-icons/tb"
import{CgCommunity} from "react-icons/cg"






import { NavLink, useNavigate } from 'react-router-dom';



import Loading from '../Modals/Loading';
import Bugbountystart from '../Modals/Teammodals/Bugbountystart';
import Submitbountyflag from '../Modals/Teammodals/Submitbountyflag';
import Youcantstartctf from '../Modals/Teammodals/Youcantstartctf';
import havoclogo from "../../../Assets/Entity/Webfare/innerphoto2.png"
import Certialreadysent from '../Modals/Teammodals/Certialreadysent';
import Certificatealreadygenerate from '../Modals/Teammodals/Certificatealreadygenerate';
import Zeroscoreteam from '../Modals/Teammodals/Zeroscoreteam';
import Notparticipateinteam from '../Modals/Teammodals/Notparticipateinteam';
const Havoc = (props) => {


const [bugbounty,setbugbounty]=useState([]);
const[modal,setopenmodal]=useState(false);
const[modal2,setopenmodal2]=useState(false);
const[modal3,setopenmodal3]=useState(false);
const[modal4,setopenmodal4]=useState(false);
const[modal5,setopenmodal5]=useState(false);
const[modal6,setopenmodal6]=useState(false);
const[modal7,setopenmodal7]=useState(false);
const[modal8,setopenmodal8]=useState(false);

  const navigate=useNavigate();
const PuG_1sD0g="{PuG_1s@D0g}"
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
 
 
  const API_Key = process.env.REACT_APP_API_URL;
const submitflag = () =>{
    setopenmodal(true);
  }
 
  const gotolink = async(e)=>{
    setopenmodal2(true);
    const {id}=e.target;
    
  
  
    const datas={id:id}
    fetch(`${API_Key}/start_bugbountry` , {
      method: 'POST',
      headers: {
        Accept: 'application/json',
              mode:"cors",
                      'Access-Control-Allow-Origin':`${API_Key}`, 
                      'Content-Type': 'application/json',
                      'Access-Control-Allow-Credentials': true
            },
            credentials:"include",
      body: JSON.stringify(datas),
    })
  
    //Then with the data from the response in JSON...
    .then(async(res) => { 
     
       const data=await res.json();
       
       //window.alert(data);
       if(res.status===200){
  setopenmodal2(false);
  setopenmodal3(true);
  
       }else if(res.status===403){
        setopenmodal2(false);
        setopenmodal4(true);
       }
        
   
    })
    //Then with the error genereted...
    .catch((error) => {
      console.error('Error:', error);
    });
  
  }

  const fetchbugbounty = async () => {
   
   
  
    try{
      const res = await fetch(`${API_Key}/getbugbounty`,{
        method:"GET",
        headers:{
          mode: 'cors',
          'Access-Control-Allow-Origin':`${API_Key}`,
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': true,
        },
        credentials:"include"
      
       
  
         
      });
      const data = await res.json();
      setbugbounty(data);
  
    
  
      if(res.status !== 200 ){
          const error = new Error(res.error);
          throw error;
          
      }
      
   
  
  
  }catch(err){
    console.log(err);
    navigate("/signin");
  
  } 
  
  }
  useEffect(() => {
    fetchbugbounty();
  },[ ] );

 
 
    const[item,setitem]=useState("A")



    /*const downloadcerti = async ()=>{
      setopenmodal2(true);
      try{
        const res = await fetch(`${API_Key}/getusercertificate`,{
          method:"GET",
          headers:{
            mode: 'cors',
            'Access-Control-Allow-Origin':`${API_Key}`,
            'Accept': 'application/pdf',
            'Content-Type': 'application/pdf',
            'Access-Control-Allow-Credentials': true,
          },
          credentials:"include"
        
         
    
           
        }).then(response => {

          if(response.status===200){
            setopenmodal2(false);
          response.blob().then(blob => {
              // Creating new object of PDF file
              const fileURL = window.URL.createObjectURL(blob);
              // Setting various property values
              let alink = document.createElement('a');
              alink.href = fileURL;
              alink.download = 'Certificate.pdf';
              alink.click();
          })
        }else if(response.status==403){
          setopenmodal2(false);
          setopenmodal5(true);
        }else if(response.status===404){
         setopenmodal2(false);
         setopenmodal6(true);
        }
        else if(response.status===405){
          setopenmodal2(false);
          setopenmodal7(true);
         }
         else if(response.status===406){
          setopenmodal2(false);
          setopenmodal8(true);
         }
      })
        
     
    
    
    }catch(err){
      console.log(err);
    
    
    } 

      
    }*/          
  return (
    <div>
    
 {modal && <Submitbountyflag   id={bugbounty._id} setOpenModal={setopenmodal} />}
 {modal2 && <Loading    setOpenModal={setopenmodal2} />}
 {modal3 && <Bugbountystart  ip={bugbounty.ip}  setOpenModal={setopenmodal3} />}
 {modal4 && <Youcantstartctf  setOpenModal={setopenmodal4} />}
 {modal5 && <Certificatealreadygenerate  setOpenModal={setopenmodal5} />}
 {modal6 && <Notparticipateinteam  setOpenModal={setopenmodal6} />}
 {modal7 && <Zeroscoreteam  setOpenModal={setopenmodal7} />}
 {modal8 && <Certialreadysent  setOpenModal={setopenmodal8} />}
    
    <div className='Entity_background2 p-1'>
    <div className='Entitycommontopper3'>
 
    <div className='container'>
    <div className='row justify-content-center'>
   <div className='col-lg-8 col-md-8 col-12 p-5'>
   <h1 className='Entitycommonhead'>{bugbounty.title}<span><hr className='Entitycommonheadhr'></hr></span></h1>
   <p className='Entitycommonheadpara'>Welcome to Hack Master Series, a web pentesting CTF. Unleash your cybersecurity skills, collaborate with peers, and conquer challenges in this exciting event. Our objective is to foster ethical hacking, teamwork, and knowledge-sharing, while offering a realistic and challenging CTF experience. See your live rankings in the CTF leaderboard and showcase your talent in the world of cybersecurity!  </p>
   
   </div>
   <div className='col-lg-1'></div>
   <div className='col-lg-3 col-md-4 col-12 p-2 mt-5 mb-4 '>
   <img src={Videoimg} style={{paddingTop:"5px"}} alt="labs"  ></img>
   
   </div>
    
    </div>
   
    </div>
    
   </div>
 
  <div className='container mt-5 p-1'>
 
   <div className='row p-5'>
   
  {/*</div> <div className='col-lg-3 col-md-3 col-10'>
   <div className='row justify-content-center mt-2 mb-5 p-4 havocbtn'   ><span className='mr-3'><AiOutlineSearch size={30}/></span><h1 className='enterpreyhead'> OverView</h1></div>

   </div>
   <div className='row'>
   
   <div className='col-lg-3 col-md-3 col-10'>
   <div className='row justify-content-center mt-2 mb-5 p-4 havocbtn'   ><span className='mr-3'><AiOutlinePlusCircle size={30}/></span><h1 className='enterpreyhead'> Create Your Team</h1></div>
   </div>
   </div>
   <div className='row'>
   
   <div className='col-lg-3 col-md-3 col-10'>
   <div className='row justify-content-center mt-2 mb-5 p-4 havocbtn2'   ><span className='mr-3'><SlGraph size={30}/></span><h1 className='enterpreyhead'> Ranking</h1></div>
  </div>*/}
  <div className='col-lg-12 col-md-9 col-12 '>
 <div className='row p-2'>


  <div className=' col-lg-2 col-md-12 col-12 m-0' style={{background:"none"}} onClick={() => setitem("A")}>
  <div className={item ==="A" ? "row justify-content-center mt-2 mb-5 p-4 topbtn2":"row justify-content-center mt-2 mb-5 p-4 topbtn"}    > <h1 className='enterpreyhead'> OverView</h1></div>
  </div>





  
  <div className=' col-lg-2 col-md-12 col-12 m-0' style={{background:"none"}} onClick={() => setitem("B")}>
 <NavLink to="/hackmasterseries/team" style={{textDecoration:"none"}}><div className={item ==="B" ? "row justify-content-center mt-2 mb-5 p-4 topbtn2":"row justify-content-center mt-2 mb-5 p-4 topbtn"}   ><h1 className='enterpreyhead'> Team</h1></div></NavLink> 

  </div>




  <div className=' col-lg-2 col-md-12 col-12 m-0' style={{background:"none"}} onClick={() => setitem("C")}>
  <NavLink to="/hackmasterseries/leaderboard" style={{textDecoration:"none"}}> <div className={item ==="C" ? "row justify-content-center mt-2 mb-5 p-4 topbtn2ctf":"row justify-content-center mt-2 mb-5 p-4 topbtn"}   ><h1 className='enterpreyhead'> Leaderboard</h1></div></NavLink>
  </div>
 
  </div>
  </div>
   </div>

 
  
  
  

  
 

   

            
 
  
 
  
  
  
  </div>
 
  <div className='container mt-0 p-1'>
  <div className='row'>

 {item === "A" && 
 <div className='container p-5'>
<div className='row'>
<div className='col-lg-8 col-md-8 col-12'>
<h1 className='overviewhead2'>Event Overview<div className='row'>
<span><hr className='havochr ml-4'></hr></span></div></h1>
<div className='row p-4 '>
<p className='havocpara'>
{bugbounty.desc}
</p>
</div>

{/*<div className='row mt-5'>
<div className=' col-lg-3 col-md-4 col p-3' id='havocbountycard' >
<div className='row justify-content-center mt-4'>
<AiOutlineTrophy color='#F7A80F' size={30}/>

</div>
<div className='row justify-content-center'>
<h1 className='text-center eventhead' >Event Prize</h1>
</div>
<div className='row justify-content-center'>
<p className='text-center eventpara'>$21000</p>
</div>



</div>

<div className=' col-lg-3 col-md-4 col p-3' id='havocbountycard' >
<div className='row justify-content-center mt-4'>
<AiOutlineTeam color='#F7A80F' size={30}/>

</div>
<div className='row justify-content-center'>
<h1 className='text-center eventhead' >Team Size</h1>
</div>
<div className='row justify-content-center'>
<p className='text-center eventpara'>1-5</p>
</div>



</div>
<div className=' col-lg-3 col-md-4 col p-3' id='havocbountycard' >
<div className='row justify-content-center mt-4'>
<AiOutlinePieChart color='#F7A80F' size={30}/>

</div>
<div className='row justify-content-center'>
<h1 className='text-center eventhead' >Challenges</h1>
</div>
<div className='row justify-content-center'>
<p className='text-center eventpara'>10</p>
</div>



</div>


</div>*/}
<div className='row mt-5 p-4'>
<h1 className='overviewhead'>Information<div className='row'>
<span><hr className='havochr ml-4'></hr></span></div></h1>

</div>
<div className='row  justify-content-center mt-4'>
<div className='col-lg-3 col-md-3 col-4' id='havocbountycard2'>
<span><h1 className='eventhead2'><AiOutlinePieChart  className='mr-2' color='#F7A80F' size={30}/>CTF Style</h1></span>
<p className='eventpara2'>Web penetration</p>
</div>
<div className='col-lg-3 col-md-3 col-4' id='havocbountycard2'>
<span><h1 className='eventhead2'><AiOutlineFieldTime  className='mr-2' color='#F7A80F' size={30}/>Duration</h1></span>
<p className='eventpara2'>5 Hours</p>
</div>
<div className='col-lg-3 col-md-3 col-4' id='havocbountycard2'>
<span><h1 className='eventhead2'><RiPriceTag3Line  className='mr-2' color='#F7A80F' size={30}/>Entry Fee</h1></span>
<p className='eventpara2'>&#8377;150</p>
</div>
<div className='col-lg-10 col-md-3 col-4' id='havocbountycard2'>
<span><h1 className='text-center eventhead2'><SlLocationPin  className='mr-2' color='#F7A80F' size={30}/>Location</h1></span>
<p className='eventpara2 text-center' style={{marginLeft:"-20px"}}>INDIA </p>
</div>
<div className='col-lg-3 col-md-3 col-4' id='havocbountycard2'>
<span><h1 className='eventhead2'><TbChartInfographic  className='mr-2' color='#F7A80F' size={30}/>Difficulty</h1></span>
<p className='eventpara2'>Easy To Medium</p>
</div>
<div className='col-lg-3 col-md-3 col-4' id='havocbountycard2'>
<span><h1 className='eventhead2'><AiOutlineFlag  className='mr-2' color='#F7A80F' size={30}/> Flags</h1></span>
<p className='eventpara2 ' style={{marginLeft:"4px"}}>10</p>
</div>
<div className='col-lg-3 col-md-3 col-10' id='havocbountycard2'>
<span><h1 className='eventhead2'><AiOutlineTeam  className='mr-2' color='#F7A80F' size={30}/>Team Size</h1></span>
<p className='eventpara2'>1-5 Members</p>
</div>
</div>
{/*<div className='row mt-5  p-4'>
<h1 className='overviewhead'>Key Points<div className='row'>
<span><hr className='havochr ml-4'></hr></span></div></h1>

</div>
<div className='row p-2 mt-0'>
<ul>
<li className='havoclist'><span><img src={havoclogo} style={{height:"3rem",marginRight:"1rem"}} alt="logo"/></span> The flag's structure:<b> hackersprey{PuG_1sD0g}</b>  </li>
<li className='havoclist'><span><img src={havoclogo} style={{height:"3rem",marginRight:"1rem"}} alt="logo"/></span>You are allowed to submit a maximum of 10 flags within a 20-minute timeframe. </li>

<li className='havoclist'><span><img src={havoclogo} style={{height:"3rem",marginRight:"1rem"}} alt="logo"/></span>Admin Bot for CTF website is live. Expect ticket responses within 2 minutes. </li>
<li className='havoclist'><span><img src={havoclogo} style={{height:"3rem",marginRight:"1rem"}} alt="logo"/></span>SQLite Database is being used at the backend. </li>
<li className='havoclist'><span><img src={havoclogo} style={{height:"3rem",marginRight:"1rem"}} alt="logo"/></span><b>Tools of interest:</b> Burpsuite, Beeceptor, Dir Buster , WebShells etc </li>


<li className='havoclist'><span><img src={havoclogo} style={{height:"3rem",marginRight:"1rem"}} alt="jkj"/>For CTF access, it's highly advised to run the CTF in your web browser's Incognito Mode.




</span></li>

<li className='havoclist'><span><img src={havoclogo} style={{height:"3rem",marginRight:"1rem"}} alt="jkj"/>Remember, no spaces in flag submissions. The system rejects flags with spaces.</span></li>


</ul>

</div>*/}



</div>
<div className='col-lg-1 col-md-1 col-1 mt-0'></div>
<div className='col-lg-3 col-md-9 col-12 mt-3'>
<br></br>
<div className='row justify-content-center mt-5' >
  <div className='col-lg-12 col-md-12 col-10' id='havocsubmitbtn'>
    <button className='btn' disabled={!bugbounty.ip} onClick={gotolink} id={bugbounty._id}>
      <span><img src={Gotolink} style={{ height: "2rem", marginRight: "1.22rem" }} /></span>Start CTF
    </button>
  </div>
</div>

<div className='row justify-content-center mt-5' >
  <div className='col-lg-12 col-md-12 col-10' id='havocsubmitbtn'>
    <button className='btn' disabled={!bugbounty.ip} onClick={submitflag}>
      <span><AiOutlineFlag color='white' size={25} style={{ marginRight: "1.2rem" }} /></span>Submit Flag
    </button>
  </div>
</div>




<a href='https://discord.gg/TU4pqYweYF' target="_blank" style={{textDecoration:"none"}}>
<div className='row justify-content-center  mt-5' >
<div className='col-lg-12 col-md-12 col-10' id='havocsubmitbtn'>
<button className='btn'><span><CgCommunity color='white' size={30} style={{marginRight:"1.2rem"}}/></span>Join our discord</button>
</div>
</div></a>
{/*<div className='row justify-content-center  mt-5' >
<div className='col-lg-12 col-md-12 col-10' id='havocsubmitbtn'>
<button className='btn' onClick={downloadcerti}><span><CgCommunity color='white' size={30} style={{marginRight:"1.2rem"}}/></span>DownLoad Certificate</button>
</div>
</div>*/}
</div>
</div>
</div>





}


   </div>
  </div>
  </div> 
  </div>

  )
}

export default Havoc