import React from 'react';
import './RelatedCourse.css';
import StackImg from "../../Assets/webinar/stackxploit.png";
import WebpwnImg from "../../Assets/webinar/webpwn.png";
import { Link } from 'react-router-dom';

const RelatedCourse = () => {
  return (
    <div className='related-course-container'>

        <h1 className='related-course-heading'>Other Courses You May Like</h1>
        <div className="red-line-class">
            <hr className="binaryhr"></hr>
          </div>
          <div className='related-course-card-container'>
            <div className='related-course-card'>
              <img src={StackImg}/>
              <h1>Stack Xploit</h1>
              <div className='related-course-points'>
                <p>1. Hands-on experience in bypassing security mechanisms found in linux binaries</p>
                <p>2. Engage in self-paced learning with the StackXploit Cyber Security course</p>
                <p>3. Industry-standard curriculum</p>
                <p>4. Elevate your expertise in cyber security</p>
              </div>
              <div style={{display:"flex", alignItems:"center",justifyContent:"center"}}><Link to="/stack-xploit-cyber-security-course"><button>Know More</button></Link></div> 
            </div>
            <div className='related-course-card'>
              <img src={WebpwnImg}/>
              <h1>WEBPWN</h1>
              <div className='related-course-points'>
                <p>1. A Professional Web Hacking Course</p>
                <p>2. Comprehensive coverage and practical exercises on all OWASP Top 10 vulnerabilities</p>
                <p>3. 70+ Real World Hacking Challenges</p>
                <p>4. Access To PreyGround</p>
              </div>
              <div style={{display:"flex", alignItems:"center",justifyContent:"center"}}><Link to="/webpwn-professional-web-hacking-course"><button className=''>Know More</button></Link></div>
             
              
            </div>

          </div>
    </div>
  )
}

export default RelatedCourse