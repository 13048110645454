import React, { useState, useEffect } from "react";

import { useForm } from "react-hook-form";
import Dot from "../../../Assets/images/Activity.svg";
import Vip from "../../../Assets/Dashboard/logovip.png";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../Modals/Loading";
import Susbxriptionsaves from "../Modals/Susbxriptionsaves";
import Fakesubscription from "../Modals/Fakesubscription";
import Wrongcoupon from "../Modals/Wrongcoupon";
import Couponexpired from "../Modals/Couponexpired";
import Alreadysubcriptionadded from "../Modals/Alreadysubcriptionadded";
import Navbar from "../../Navbar/Navbar";
function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}



const __DEV__ = document.domain === "localhost";
const VerifyPayment2 = (props) => {
  const [GSTAmount,setGSTAmount]=useState(null);
  const navigate = useNavigate();
  const params = useParams()
  const [plans, setplans] = useState([]);
  const [actualprice, setactualprice] = useState("");
  const [discountshow, setdiscountshow] = useState(false);
  const [modal, setopenmodal] = useState(false);
  const [modal2, setopenmodal2] = useState(false);
  const [modal3, setopenmodal3] = useState(false);
  const [modal4, setopenmodal4] = useState(false);
  const [modal5, setopenmodal5] = useState(false);
  const [email, setemail] = useState("");
  const [modal6, setopenmodal6] = useState(false);
  const [emailError, setEmailError] = useState("");
  const API_Key = process.env.REACT_APP_API_URL;
  const API_Key2 = process.env.REACT_APP_RAZORPAY_ID;
  const getplansbyid = async () => {
    let plan_id = params.plan_id;
    const response = await fetch(`${API_Key}/getplansbyidbyauth/` + plan_id, {
      method: "GET",
      headers: {
        mode: "cors",
        "Access-Control-Allow-Origin": `${API_Key}`,
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
      credentials: "include",
    })
      .then(async (response) => {
        const data = await response.json();

        setplans(data);
      })

      .catch((error) => {
        console.error("Error:", error);
        //setModalOpen3(true);
        navigate("/signin");
      });
  };
  const handleGSTAmount=(gstprice,planprice)=>{
    if(!gstprice){
      return ""
    }
    else{
      return (planprice * 0.18).toFixed(2)
    }
  }
  useEffect(() => {
    getplansbyid();
  }, []);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    trigger,
  } = useForm();
  const onSubmit = async (data) => {
    setopenmodal(true);
    const plan_id = plans._id;
    fetch(`${API_Key}/matchcoupon/` + plan_id, {
      method: "POST",
      headers: {
        mode: "cors",
        "Access-Control-Allow-Origin": `${API_Key}`,
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
      credentials: "include",
      body: JSON.stringify(data),
    })
      .then(async (response) => {
        const data = await response.json();
        if (response.status === 200) {
          setopenmodal(false);
          setactualprice(data.discountedPrice);
          setdiscountshow(true);
        } else if (response.status === 404) {
          setopenmodal(false);
          setopenmodal4(true);
        } else if (response.status === 403) {
          setopenmodal(false);
          setopenmodal5(true);
        }

        //Then with the data from the response in JSON...
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }
  const subscibe = async (event) => {

    //const {id}=event.target;
    event.preventDefault();
    if (!email) {
      setEmailError("Please enter your email.");
      return;
    }
    // Check for a valid email format
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    if (!emailRegex.test(email)) {
      setEmailError("Please enter a valid email address.");
      return;
    }

    // Clear email error if no issues
    setEmailError("");
    const data = { planid: plans._id, courseid: plans.courseid, email: email };
    fetch(`${API_Key}/purchasePlan_withoutAuth`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Access-Control-Allow-Origin": `${API_Key}`,
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": "true",
      },
      credentials: "include",
      body: JSON.stringify(data),
    })
      .then(async (response) => {
        const datas = await response.json();
        if (response.status === 201) {
          setopenmodal(false);
          setopenmodal6(true);
        }

        const acutalamounts = datas.acutalamount;
        const discountamount = datas.discountamount;
        const data = {
          email: datas.email,
          planid: datas.planid,
          currency: datas.currency,
          receipt: datas.receipt,
          amount: actualprice || datas.amount,
          courseid: datas.courseid,
        };
        console.log("after purchase plan", datas.amount)
        fetch(`${API_Key}/createOrderWithoutAuth`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Access-Control-Allow-Origin": `${API_Key}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Credentials": "true",
          },
          credentials: "include",
          body: JSON.stringify(data),
        }).then(async (response) => {
          const datas = await response.json();

          console.log(response.status, "statussss");

          console.log("amount", datas.amount / 100 !== acutalamounts);
          console.log("discamount", datas.amount / 100 !== discountamount);

          const res = await loadScript(
            "https://checkout.razorpay.com/v1/checkout.js"
          );

          if (!res) {
            alert("Razorpay SDK failed to load. Are you online?");
            return;
          }

          if (response.status === 403) {
            setopenmodal3(true);
            setopenmodal(false);
          } else {
            console.log("options", datas.amount, typeof datas.amount)
            const options = {
              key: `${API_Key2}`,
              currency: datas.currency,
              amount: datas.amount,
              order_id: datas.id,
              name: "Hackersprey",
              description: datas.receipt,
              image: Vip,
              handler: function (response) {
                const money = {
                  email: datas.email,
                  order_id: response.razorpay_order_id,
                  payment_id: response.razorpay_payment_id,
                };

                const signature = {
                  "x-razorpay-signature": [response.razorpay_signature],
                };

                fetch(`${API_Key}/verifyPaymentWithoutAuth`, {
                  method: "POST",
                  headers: {
                    Accept: "application/json",
                    "Access-Control-Allow-Origin": `${API_Key}`,
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Credentials": "true",
                    "x-razorpay-signature": [response.razorpay_signature],
                  },

                  credentials: "include",
                  body: JSON.stringify(money),
                })
                  .then(async (response) => {
                    if (response.status === 200) {
                      navigate("/Paymentsuccess");
                    }
                  })
                  .catch((error) => {
                    console.error("Error:", error);
                    //setModalOpen2(true);
                  })
                  .catch((error) => {
                    console.error("Error:", error);
                    //setModalOpen2(true);
                  });
              },
              prefill: {
                name: "none",
                email: "none",
                phone_number: "none",
              },
            };
            const paymentObject = new window.Razorpay(options);
            paymentObject.open();
          }
        });
      })

      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
        //setModalOpen2(true);
      });
  };
  return (
    <>
      {modal && <Loading setOpenModal={setopenmodal} />}
      {modal2 && <Susbxriptionsaves setOpenModal={setopenmodal2} />}
      {modal3 && <Fakesubscription setOpenModal={setopenmodal3} />}
      {modal4 && <Wrongcoupon setOpenModal={setopenmodal4} />}
      {modal5 && <Couponexpired setOpenModal={setopenmodal5} />}
      {modal6 && <Alreadysubcriptionadded setOpenModal={setopenmodal6} />}
      <section className="Dynamicbackground">
 

      <div className="container mt-5">
        <div class="Heading-main">
          <span>CHECKOUT</span>
          <h2>CHECKOUT</h2>
          <hr className="offset-md-5 offset-4"></hr>
        </div>
        <div  className="row justify-content-center">
       
        </div>
      </div>
    </section>
      <div className="mainconsection p-2">
        
        { /* <div className="container">
            <div className="row justify-content-center">
              <h1 className="commonhead">
                Select a Payment Method
                <div className="row justify-content-center">
                  <hr className="commonheadhr"></hr>
                </div>
              </h1>
            </div>
            <div className="row justify-content-center">
              <p className="Commonpara">
                Tailor your learning journey by selecting a plan that
                accelerates your hacking skills and knowledge !{" "}
              </p>
            </div>
  </div>*/}
      
        <div className="container-fluid mt-5 p-5">
          <div className="row justify-content-center p-5  ">
            <div className="col-lg-7 col-md-10 col-10">
              <h1 className="verifysubcriptionhead text-center">
                Claim Your Discount
              </h1>
              <div className="row justify-content-center mt-4">
                <p className="Commonpara">
                  {" "}
                  Maximize your learning experience! Have a valid coupon? Redeem
                  it now for special discounts on your chosen HackersPrey
                  Program.{" "}
                </p>
              </div>
              <h1 className="verifysubcriptionhead2 text-center">
                Plan Features
              </h1>
              {plans.features &&
                plans.features.map((feature, id) => (
                  <div className="row  mt-4">
                    <ul>
                      <li className="subplanfeaturepara">
                        <span className="mr-3">
                          {" "}
                          <img
                            src={Dot}
                            alt="dot"
                            style={{ height: "1.5rem" }}
                          />
                        </span>
                        {feature.feature}
                      </li>
                    </ul>
                  </div>
                ))}
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-input col-lg-12">
                  <div className="col">
                    <label className="feedbacklabel">Enter Coupon Code</label>
                    <input
                      name="couponCode"
                      type="text"
                      autoComplete="off"
                      className={`form-control mb-1 ${errors.couponCode && "invalid"
                        }`}
                      placeholder="Enter Code"
                      {...register("couponCode", {
                        required: "Coupon Code is Required",
                        pattern: /^[a-zA-Z 0-9 ]*$/,
                        message: "Please Enter  Code",
                      })}
                      onKeyUp={() => {
                        trigger("couponCode");
                      }}
                    />
                    {errors.couponCode && (
                      <small className="errormes ml-2 ">
                        {errors.couponCode.message}
                      </small>
                    )}
                  </div>

                  <div className="row justify-content-center">
                    <button className="btn feedbackbtn">Apply Coupon</button>
                  </div>
                </div>
              </form>
            </div>

            <div className="col-lg-3 col-10 m-5">
              <h1 className="text-center verifysubcriptionhead3 mb-5">
                Order Summary
              </h1>
              <div className="card p-5" id="subscard">
                <div className="row justify-content-center">
                  <div className="col-lg-5 col">
                    {" "}
                    <img src={plans.image} alt="Vip" />
                  </div>
                  <div className="col-lg-7 col pt-3">
                    {" "}
                    <h2 className="subplanhead">{plans.name}</h2>
                    <h6 className="subplanpara ">
                      {" "}
                      <span className="subplanparadspan">&#x20B9;</span>
                      {plans.price}
                      <span className="subplanparadspan"></span>
                    </h6>
                  </div>
                </div>
                <div className="row  justify-content-center mt-2">
                  <div className="col-lg-12">
                    <hr className="subplanhr"></hr>
                  </div>
                </div>
                <div className="row  justify-content-center mt-1">
                  <div className="col-lg-12">
                    <div className="form-input">
                      <input
                        name="email"
                        type="text"
                        autoComplete="off"
                        className={`form-control mb-5 ${emailError && "invalid"}`}
                        placeholder="Enter Your Email"
                        value={email}
                        onChange={(e) => {
                          setemail(e.target.value);
                          setEmailError(""); // Clear previous error when user types
                        }}
                      />
                      {emailError && <small className="errormes2 ml-2 ">{emailError}</small>}





                    </div>
                  </div>
                </div>


                <div className="row  justify-content-center mt-1">
                  <div className="col-6">
                    <h2 className="verifycardhead">plan charge</h2>
                  </div>
                  <div className="col-6">
                    <h2 className="verifycardhead2">&#x20B9;{plans.price}</h2>
                  </div>
                </div>

                <div className="row  justify-content-center mt-3">

                  <div className="col-6">
                    <h2 className="verifycardhead">GST(18%)</h2>
                  </div>
                  <div className="col-6">
                    <h2 className="verifycardhead2"> {handleGSTAmount(plans?.gst_price,plans?.price)}</h2>
                  </div>
                </div>

                <div className="row  justify-content-center mt-3">

                  <div className="col-6">
                    <h2 className="verifycardhead">Months</h2>
                  </div>
                  <div className="col-6">
                    <h2 className="verifycardhead2">{plans.validity}</h2>
                  </div>
                </div>
                <div className="row  justify-content-center mt-3">
                  <div className="col-lg-12">
                    <hr className="subplanhr"></hr>
                  </div>
                </div>
                <div className="row  justify-content-center mt-1">
                  <div className="col-6">
                    <h2 className="verifycardhead">Discount</h2>
                  </div>
                  <div className="col-6">
                    <h2 className="verifycardhead2">
                      {" "}
                      {discountshow ? "10%" : "--"}{" "}
                    </h2>
                  </div>
                </div>
                <div className="row  justify-content-center mt-3">
                  <div className="col-6">
                    <h2 className="verifycardhead">Sub Total</h2>
                  </div>
                  <div className="col-6">
                    <h2 className="verifycardhead2">
                      {discountshow ? actualprice : "--"}
                    </h2>
                  </div>
                </div>
                <div className="row  justify-content-center mt-3">
                  <div className="col-lg-12">
                    <hr className="subplanhr"></hr>
                  </div>
                </div>
                <div className="row  justify-content-center mt-3">
                  <div className="col-6">
                    <h2 className="verifycardhead">Total</h2>
                  </div>
                  <div className="col-6">
                    <h2 className="verifycardhead2">
                      {actualprice || plans.gst_price}
                    </h2>
                  </div>
                </div>
                <div className="row justify-content-center  mt-4">
                  <div className="col-lg-8">
                    <button
                      className="btn  subsplansubscribebtn"
                      onClick={subscibe}
                    >
                      PAY NOW
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VerifyPayment2;
