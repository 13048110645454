import React, { useEffect, useState } from "react";
import DPE from "../../Assets/acwassets/security.png";
import IDADS from "../../Assets/acwassets/lms.png";
import LPE from "../../Assets/acwassets/domain.png";
import LPES from "../../Assets/acwassets/alert.png";
import LMS from "../../Assets/acwassets/shield.png";
import OSINT from "../../Assets/acwassets/search.png";
import RippleImg from "../../Assets/acwassets/ripple-effect.svg";
import TickImg from "../../Assets/acwassets/tick.svg";
import { IoIosCloseCircleOutline } from "react-icons/io";

import { BsChatText } from "react-icons/bs";
import { FiPhoneCall } from "react-icons/fi";
import "./ACWP.css";
import CertificateSection from "./CertificateSection";
import ProgramOverview from "./ProgramOverview";
import WhyHackersprey from "../IndustrialTraining/WhyHackersprey";
import Footer from "../Footer/Footer";
import SummerTrainingCertificate from "../IndustrialTraining/SummerTrainingCertificate";
import IndustrialBenefits from "../IndustrialTraining/IndustrialBenefits";
import { Helmet } from "react-helmet";
import ACWPHeroSection from "./ACWPHeroSection";
import MarqueueComponent from "./MarqueueComponent";
import { Link, useLocation } from "react-router-dom";
import ACWPModal from "./ACWPModal";
import CTASection from "./CTASection";

import StackXploit2FAQs from "../Dyanmicpages/BinaryCurriculum2/StackXploit2FAQs";
import { motion } from "framer-motion";
import BinaryCurriculum from "../Dyanmicpages/BinaryCurriculum/BinaryCurriculum";
import RelatedCourse from "./RelatedCourse";
import ACWPCertification from "./ACWPCertification";
const faq = [
  {
    sectionName:
      "What is the duration of this red teaming course?",
    sectionDesc:
      "The duration for this Red Teaming Training Course is a minimum of 3 months, depending on your pace and commitment.",
  },
  {
    sectionName:
      "Do I need to know the programming for this red teaming training course?",
    sectionDesc:
      "No, programming knowledge is not mandatory for this Red Teaming Training Course, as most tools and techniques are covered during the program. However, familiarity with Basic Networking Knowledge, Linux, and Windows Operating System can be a plus for advanced topics.",
  },
  {
    sectionName:
      "Is this ACWP course suitable for beginners? ",
    sectionDesc:
      "Yes, the ACWP course is suitable for beginners who are eager to learn. With a fully practical approach and comprehensive coverage of topics, it provides all the tools and guidance needed to build expertise from the ground up.",
  },
  {
    sectionName: "What makes this course better than other Red Teaming courses?",
    sectionDesc:
      "Unlike many other courses, this Red Teaming Training course provides a comprehensive curriculum, flexible learning, superior certifications compared to foreign programs, and career-focused outcomes, including placement opportunities.",
  },
  {
    sectionName:
      "Who should enroll in this ACWP Red Teaming Course?",
    sectionDesc:
      "Our ACWP Red Teaming Course is ideal for security enthusiasts, professionals seeking advancement, current or aspiring cybersecurity professionals, graduates, diploma holders, beginners with an interest in cybersecurity, and IT professionals.",
  },
  {
    sectionName:
      "What skills will I gain from this Red Teaming Training course?",
    sectionDesc:
      "From this Red Teaming Training Course, you will gain skills in key areas such as information gathering, OSINT, network pentesting, web exploitation, initial access vectors, launching phishing campaigns, achieving persistence, lateral movement, AV evasion, bypassing 2FA, and advanced Active Directory attacks.",
  },
  {
    sectionName: "Will I receive a certificate upon completion?",
    sectionDesc:
      "Yes, upon successful completion of this  Red Teaming Course, you will receive a certification that validates your expertise and skills in network pen testing, web exploitation, AV Evasion,  and more.",
  },
  {
    sectionName: "How can I enroll in the ACWP Red Teaming Course?",
    sectionDesc:
      "You can enroll in this Red Teaming Course by visiting our website and following the registration process. For more details, contact our support team.",
  },
 
];
const hackerspreyPoint = [
  "Program covers all cybersecurity essentials and advanced topics.",
  "We keep you updated on the latest developments in cybersecurity.",
  "We conduct regular doubt-clearing sessions, ensuring no questions go unanswered.",
  "Our faculty consists of working professionals with extensive industry experience.",
  "We immerse our students in a real-world working environment.",
  "We emphasize practical skills over rote memorization.",
  "We provide access to state-of-the-art labs and real-world hacking challenges.",
  "Our comprehensive program is designed to prepare you for a successful career in a reputed organization.",
];
const conventionalPoint = [
  "Provides a quicker overview but lacks comprehensive depth.",
  "Outdated curriculum with minimal focus on recent cybersecurity trends.",
  "Rare or non-existent doubt-clearing sessions, leaving students with unresolved queries.",
  "Faculty with limited or no real-world industry experience.",
  "Theoretical learning with little to no exposure to real-world applications.",
  "Focus on theoretical knowledge and rote memorization over practical application.",
  "Lack of access to advanced labs and minimal exposure to real-world hacking scenarios.",
  "Limited preparation for real-world challenges, leading to fewer career opportunities in reputed firms.",
];
const headerDescription =
  "Hackersprey’s Advance Cyber Warfare Program (ACWP) is the ultimate Red Teaming Training course, setting a new benchmark in the cybersecurity industry. Designed to meet the evolving needs of individuals, corporates, defense sectors, and academic institutions, this program is highly in demand for its unmatched quality and impact. With a curriculum that integrates Web Exploitation, Network Pentesting, AV evasion, Privilege Escalation, Active Directory Exploitation, it prepares you for real-world challenges while offering certifications that surpass many foreign programs in recognition and value.";
const headings = [
  "Program Structure",
  "Curriculum and Updates",
  "Student Support",
  "Faculty Expertise",
  "Learning Environment",
  "Teaching Methodology",
  "Lab and Practical Access",
  "Career Preparation",
];

const benefits = [
  {
    subtitle: "01",

    title: "Hands-On Experience",
    description:
      "Fully practical program with real-world hacking scenarios.",
  },
  {
    subtitle: "02",
    title: "Dedicated Lab Access",
    description:
      "Exclusive lab environment to refine your cyber warfare skills.",
  },
  {
    subtitle: "03",
    title: "Expertise in Initial Access",
    description:
      "Learn techniques to gain initial foothold in a target network.",
  },
  {
    subtitle: "04",
    title: "Advanced Persistence Tactics",
    description:
      "Achieve and maintain access effectively.",
  },
  {
    subtitle: "05",
    title: "Cutting-Edge AV Evasion",
    description:
      "Develop skills to bypass modern antivirus solutions.",
  },
  {
    subtitle: "06",
    title: "Bypass 2FA Security",
    description:
      "Understand methods to overcome two-factor authentication.",
  },
  {
    subtitle: "07",
    title: "Lateral Movement Skills",
    description:
      "Navigate through networks post-access with precision.",
  },
  {
    subtitle: "08",
    title: "24-Hour Certification Exam",
    description:
      "Validate your expertise with an intensive online certification.",
  },
  {
    subtitle: "09",
    title: "Career Advancement",
    description:
      "Stand out in the cybersecurity field with industry-relevant skills and certification.",
  },
];
const curriculum = [
  {
    sectionName: "Information Gathering & OSINIT",
   
  },
  {
    sectionName: "Launching a Phishing Campaign",
  },
  {
    sectionName: "Network Scanning & Enumeration",
  },
  {
    sectionName: "Privilege Escalation",
    sectionDesc: ""
  },
  {
    sectionName: "Web Exploitation",
    sectionDesc: ""
  },
  {
    sectionName: "Achieving Persistence",
    sectionDesc: ""
  },
  {
    sectionName: "Initial Access Vectors",
    sectionDesc: ""
  },
  {
    sectionName: "Lateral Movement & Active Directory Attacks",
    sectionDesc: ""
  },
  {
    sectionName: "AV Evasion",
    sectionDesc: ""
  },
  {
    sectionName: "Bypassing 2-Factor Authentication",
    sectionDesc: ""
  }
];

const ACWPage = () => {
  const [showCTAModal,setShowCTAModal]=useState(true);
  const [isSyllabusDownload, setIsSyllabusDownload] = useState(false);
  const [showCallbackForm, setShowCallbackForm] = useState(false);
  const [isCallbackFormRegister,setIsCallbackFormRegister]=useState(false)
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const handleCloseCTAModal=()=>{
    setShowCTAModal(true)
  }
  const handleShowModal = () => {
    setIsSyllabusDownload(true);
    setShowCallbackForm(true);
  };
  const handleModalOpenWithoutShowingSyllabus = () => {
    setIsSyllabusDownload(false);
    setShowCallbackForm(true);
  };
  useEffect(()=>{
    setTimeout(()=>{
      setShowCallbackForm(true)
    },[8000])
  },[])
  const handleShowCTAModal=()=>{
    if(showCTAModal===true){
      setShowCTAModal(!showCTAModal)
    }
    
   

  }
  return (
    <>
      {showCallbackForm ? (
        <ACWPModal
          switchSyllabus={() => setIsSyllabusDownload(false)}
          isSyllabusDownload={isSyllabusDownload}
          onClick={() => setShowCallbackForm(false)}
          isCallbackFormRegister={isCallbackFormRegister}
          setIsCallbackFormRegister={()=>setIsCallbackFormRegister(false)}
        />
      ) : (
        <></>
      )}
      <Helmet>
        <title>Master Red Teaming with Hackersprey's ACWP - Enroll Today</title>
        <link
          rel="canonical"
          href="https://www.hackersprey.com/acwp-red-teaming-training-course"
        ></link>
        <meta
          name="description"
          content="Join Hackersprey ACWP Red Teaming Course for top Cybersecurity Certification. Master networking, Active Directory & web exploitation with practical training."
        />
        <meta
          name="keywords"
          content="Cybersecurity Certification Course, Red Teaming Course, Red Teaming Training course, Red Team Cyber Security Course, Red Team Certification, Red Teaming Training, cybersecurity networking, penetration testing training, Online Cybersecurity course, network penetration testing, network security, web penetration testing, active directory training, web app pentesting"
        />
        <meta name="locale" content="en_IN" />
        <meta name="geo.region" content="IN-CH" />
        <meta name="geo.region" content="IN" />
        <meta name="geo.placename" content="Chandigarh, India" />
        <meta
          name="geo.position"
          content="30.73265000183138, 76.80071801068048"
        />
        <meta name="ICBM" content="30.73265000183138, 76.80071801068048" />
        <meta name="author" content="Hackersprey" />
        <meta name="copyright" content="Hackersprey" />
        <meta
          name="classification"
          content="cybersecurity courses online and offline"
        />
        <meta name="distribution" content="global" />
        <meta name="robots" content="all, follow" />
        <meta name="googlebot" content="all, follow" />
        <meta name="bingbot" content="all, follow" />
        <meta name="yahoo-slurp" content="all, follow" />
        <meta name="googlebot-image" content="all" />
        <meta
          name="identifier-URL"
          content="https://www.hackersprey.com/acwp-red-teaming-training-course"
        />
        <meta
          name="url"
          content="https://www.hackersprey.com/acwp-red-teaming-training-course"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="Advance Cyber Warfare Program - Cybersecurity Certification Course"
        />
        <meta
          property="og:description"
          content="Cybersecurity Certification Course, cybersecurity networking, penetration testing training, active directory, Online Cybersecurity course, network penetration testing, network security, web penetration testing, active directory training, web app pentesting."
        />
        <meta
          property="og:url"
          content="https://www.hackersprey.com/acwp-red-teaming-training-course"
        />
        <meta property="og:image" content="" />
        <meta
          property="og:image:alt"
          content="Advance Cyber Warfare Program - Cybersecurity Certification Course"
        />
        <meta property="og:site_name" content="Hackersprey" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@hackerspre30858" />
        <meta
          name="twitter:title"
          content="Advance Cyber Warfare Program - Cybersecurity Certification Course"
        />
        <meta
          name="twitter:description"
          content="Cybersecurity Certification Course, cybersecurity networking, penetration testing training, active directory, Online Cybersecurity course, network penetration testing, network security, web penetration testing, active directory training, web app pentesting."
        />
        <meta name="twitter:image" content="" />
        <meta
          name="twitter:url"
          content="https://www.hackersprey.com/acwp-red-teaming-training-course"
        />
      </Helmet>
      <ACWPHeroSection setIsCallbackFormRegister={()=>setIsCallbackFormRegister(true)} showCallbackForm={()=>setShowCallbackForm(true)} />
      <MarqueueComponent />

      <motion.section className="acwp-overview-section">
        <motion.h1
          initial={{ y: 50, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 1 }}
          className="acwp-heading"
        >
          Overview Of ACWP
        </motion.h1>
        <div className=" row justify-content-center">
          <hr className="acwphr2"></hr>
        </div>
        <motion.section
          initial={{ y: 50, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 1 }}
          className="acwp-overview-section-content"
        >
          <p className="acwp-overview-left-section">
          Hackersprey’s Advance Cyber Warfare Program is a unique Red Team cybersecurity course designed to equip you with the essential skills and knowledge for today’s landscape. Combining comprehensive content with practical labs in a self-paced format, this program prepares you for success in penetration testing and red teaming. You'll start with network scanning, move on to Open Source Intelligence (OSINT), and learn web exploitation techniques, as well as methods for gaining initial access, evading Windows Defender and antivirus solutions. The curriculum also covers privilege escalation and Active Directory attacks, ensuring you're ready to tackle real-world challenges and make a significant impact in the cybersecurity field.
          
          </p>
          <div className="acwp-overview-right-section">
            <div className="acwp-right-section-point-section">
              <div className="acwp-right-section-point-wrapper">
                {" "}
                <img
                  alt="Advance Cyber Warfare Program - Cybersecurity Certification Course"
                  src={OSINT}
                />
              </div>
              <p>OSINT</p>
            </div>
            <div className="acwp-right-section-point-section">
              <div className="acwp-right-section-point-wrapper">
                {" "}
                <img
                  alt="Advance Cyber Warfare Program - Cybersecurity Certification Course"
                  src={DPE}
                />
              </div>
              <p>Network Scanning</p>
            </div>
            <div className="acwp-right-section-point-section">
              <div className="acwp-right-section-point-wrapper">
                {" "}
                <img
                  alt="Advance Cyber Warfare Program - Cybersecurity Certification Course"
                  src={LPE}
                />
              </div>
              <p>Web Exploitation</p>
            </div>
            <div className="acwp-right-section-point-section">
              <div className="acwp-right-section-point-wrapper">
                {" "}
                <img
                  alt="Advance Cyber Warfare Program - Cybersecurity Certification Course"
                  src={LMS}
                />
              </div>
              <p>Initial Access Vector  & AV Evasion</p>
            </div>
            <div className="acwp-right-section-point-section">
              <div className="acwp-right-section-point-wrapper">
                <img
                  alt="Advance Cyber Warfare Program - Cybersecurity Certification Course"
                  src={LPES}
                />
              </div>
              <p>Local Privilege Escalation </p>
            </div>
            <div className="acwp-right-section-point-section">
              <div className="acwp-right-section-point-wrapper">
                {" "}
                <img
                  alt="Advance Cyber Warfare Program - Cybersecurity Certification Course"
                  src={IDADS}
                />
              </div>
              <p>Lateral Movement</p>
            </div>
          </div>
        </motion.section>
      </motion.section>
      <div style={{color:"white"}}><BinaryCurriculum course="acwp" showModal={() => handleShowModal()} curriculum={curriculum}/></div>
   

      <section className="acwp-course-for-section">
        <img
          alt="Advance Cyber Warfare Program - Cybersecurity Certification Course"
          src={RippleImg}
          className="acwp-course-for-section-ripple"
        />
        <motion.div
          initial={{ y: 50, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 1 }}
          className="acwp-course-for-section-wrapper"
        >
          <div className="acwp-course-for-section-left">
            <h1>Is this program for you?</h1>
            <p className="acwp-course-for-section-left-desc">
              The Hackersprey's (ACWP) Red Teaming Course is tailored for
              ambitious individuals looking to build or elevate their careers in
              the rapidly growing field of cybersecurity. If you’re passionate
              about ethical hacking, cyber defense, and penetration testing,
              this Red Teaming Training course is designed to equip you with the
              essential skills and knowledge needed to thrive in the field of
              cybersecurity.
              <br />
              <br />
              If you're ready to take your skills to the next level and be
              recognized by the best employers in the defense, corporate, and
              educational sectors, this Red Team Cyber Security Course is the
              perfect fit for you.
            </p>
            <hr style={{height:"2px",backgroundColor:"#4A4A6A",marginTop:"1.5rem",}}/>
            <h2 className="acwp-course-for-section-left-desc" style={{marginTop:"1.5rem",marginBottom:"1rem",color:"#FFFFFF",fontWeight:"700",fontSize:"25px"}}>Prerequisites:</h2>
            <div>
            <div className="acwp-course-for-section-right-points-pre">
              <img
                alt="Advance Cyber Warfare Program - Cybersecurity Certification Course"
                src={TickImg}
              />
              <p className="acwp-course-for-section-right-points-desc">
              Basic Networking Knowledge
              </p>
            </div>
            <div className="acwp-course-for-section-right-points-pre">
              <img
                alt="Advance Cyber Warfare Program - Cybersecurity Certification Course"
                src={TickImg}
              />
              <p className="acwp-course-for-section-right-points-desc">
              Basic Knowledge of Linux and Windows Operating System
              </p>
            </div>
            <div className="acwp-course-for-section-right-points-pre">
              <img
                alt="Advance Cyber Warfare Program - Cybersecurity Certification Course"
                src={TickImg}
              />
              <p className="acwp-course-for-section-right-points-desc">
              Willingness to Learn
              </p>
            </div>
            </div>
            {/* <Link className="acwp-enroll-btn" to="/acwcourseprice">*/}
              {" "}
              <p  onClick={() => {setShowCallbackForm(true);setIsCallbackFormRegister(true)}} className="acwp-enroll-btn-inside">Enroll Now</p>
          
            {/* <p
              onClick={() => setShowCallbackForm(true)}
              className="acwp-enroll-btn-inside2"
            >
              Is this program right for me?
            </p>*/}
          </div>

          <div className="acwp-course-for-section-right">
            <div className="acwp-course-for-section-right-points">
              <img
                alt="Advance Cyber Warfare Program - Cybersecurity Certification Course"
                src={TickImg}
              />
              <p className="acwp-course-for-section-right-points-desc">
                Beginners in Cybersecurity
              </p>
            </div>
            <div className="acwp-course-for-section-right-points">
              <img
                alt="Advance Cyber Warfare Program - Cybersecurity Certification Course"
                src={TickImg}
              />
              <p className="acwp-course-for-section-right-points-desc">
                IT Professionals Seeking Advancement
              </p>
            </div>
            <div className="acwp-course-for-section-right-points">
              <img
                alt="Advance Cyber Warfare Program - Cybersecurity Certification Course"
                src={TickImg}
              />
              <p className="acwp-course-for-section-right-points-desc">
                Corporate Security Enhancers
              </p>
            </div>
            <div className="acwp-course-for-section-right-points">
              <img
                alt="Advance Cyber Warfare Program - Cybersecurity Certification Course"
                src={TickImg}
              />
              <p className="acwp-course-for-section-right-points-desc">
                Defense Sector Professionals
              </p>
            </div>
            <div className="acwp-course-for-section-right-points">
              <img
                alt="Advance Cyber Warfare Program - Cybersecurity Certification Course"
                src={TickImg}
              />
              <p className="acwp-course-for-section-right-points-desc">
                Graduate or Diploma Holders
              </p>
            </div>
            <div className="acwp-course-for-section-right-points">
              <img
                alt="Advance Cyber Warfare Program - Cybersecurity Certification Course"
                src={TickImg}
              />
              <p className="acwp-course-for-section-right-points-desc">
                Security Enthusiasts
              </p>
            </div>
            <div className="acwp-course-for-section-right-points">
              <img
                alt="Advance Cyber Warfare Program - Cybersecurity Certification Course"
                src={TickImg}
              />
              <p className="acwp-course-for-section-right-points-desc">
                Professionals Seeking Advancement
              </p>
            </div>
          </div>
        </motion.div>
      </section>

      <div style={{ color: "white" }}>
        <IndustrialBenefits
          description="Elevate your cybersecurity career with the Advance Cyber Warfare Program, a premier Red Teaming course designed to outshine conventional online cybersecurity courses. Unlike theory-heavy programs, this hands-on training focuses on real-world skills like network pentesting, AV evasion, phishing campaigns, and Active Directory attacks. With dedicated lab access and a rigorous 24-hour certification exam, you'll gain unmatched expertise to tackle advanced security challenges and stand apart in the field. This isn’t just a course—it’s a transformation."
          heading="Benefits Of ACWP"
          benefits={benefits}
        /> 
        <SummerTrainingCertificate
        onClick={() => {setShowCallbackForm(true);setIsCallbackFormRegister(true)}}
          type="acw"
          heading="Get Certified with Advance Cyber Warfare Program"
          content="Elevate your expertise in cyber security with our comprehensive ACWP course, designed to prepare you for real-world cybersecurity challenges. All participants will receive a prestigious Hackersprey ACWP Certification. This certification highlights your readiness for advanced roles in the cybersecurity industry and opens doors to exciting career opportunities."
        />
     
        <RelatedCourse/>
        <div style={{ marginBottom: "4rem" }}>
          <StackXploit2FAQs
            style={{ color: "white" }}
            faq={faq}
            heading="FAQ's"
          />
        </div>
      </div>
      
      <div onClick={()=>handleShowCTAModal()} className={`${showCTAModal ? 'acwp-call-to-action-fixed':'acwp-call-to-action-fixed2'}`}>
     
     
      {showCTAModal ?  <FiPhoneCall className="acwp-call-to-action-fixed-icon" />:
      <div style={{position:'relative'}}>
 <IoIosCloseCircleOutline onClick={()=>handleCloseCTAModal()} className="acwp-call-to-action-fixed-closeicon"/>
      <div className="acwp-call-to-action-fixed-modal">
       
       <FiPhoneCall className="acwp-call-to-action-fixed-icon2" />
       <div>
      
         <div className="acwp-call-to-action-fixed-icon2-call">For Voice Call</div>
         <div><span><a className="acwp-call-to-action-fixed-icon2-call-link" href="tel:+917888995975">+917888995975</a></span>/<span><a className="acwp-call-to-action-fixed-icon2-call-link" href="tel:+911724038499">+911724038499</a></span></div>
        
       </div>
 
 
       </div> </div>} 
      </div>





      <div onClick={() => handleModalOpenWithoutShowingSyllabus()} className='acwp-call-to-action-fixed-form'>
     
     
       <BsChatText className="acwp-call-to-action-fixed-icon" />
     </div>


      <Footer />
    </>
  );
};

export default ACWPage;
