import React, { useEffect, useState } from "react";
import Logo from "../../Assets/Entity/Course/webpwn.png";
import Logo1 from "../../Assets/Entity/Course/1657192139607webdrub.png";
import Logo2 from "../../Assets/Entity/Course/revex.png";
import { MdCircle } from "react-icons/md";
import { BsArrowRight } from "react-icons/bs";
import ActiveDirectory from "../../Assets/courses/acw.png";
import "./Dynamicpage.css";
import { Helmet } from "react-helmet";

import { Link } from "react-router-dom";

import Footer from "../Footer/Footer";
import BinaryLogo from "../../Assets/binary/sxplogo.png";
const courses = [
  {
    courseName: "Advance Cyber Warfare Program",
    courseType: "Paid",
    courseDescription:
      "Unlock the future of cybersecurity with our Advance Cyber Warfare Program, a cutting-edge red teaming course designed for those who aspire to master the art of offensive security. This program offers unparalleled insights into the tactics, techniques, and procedures used in real-world cyber warfare, making it the ideal red team cybersecurity course for professionals and enthusiasts aiming to stay ahead in the ever-evolving digital landscape.",
    courseFeatures: [
      "Hands On Experience",
      "Dedicated Lab Access",
      "Expertise in Initial Access",
      "Advance Persisitence Tactics",
      "Cutting Edge AV Evasion",
      "Bypass 2FA Security",
    ],
    courseLink: "/acwp-red-teaming-training-course",
    coursePhoto: ActiveDirectory,
  },

  {
    courseName: "StackXploit",
    courseType: "Paid",
    courseDescription:
      "StackXploit Program is an immersive self-paced learning experience tailored for mastering linux exploitation techniques, including stack-based methodologies. Dive deep into the intricacies of exploiting linux binaries with a focus on buffer overflow and binary exploitation. Learn to generate your own payloads and gain hands-on experience in bypassing diverse security mechanisms commonly found in linux binaries through real-world practice challenges. Embark on this journey to acquire unparalleled expertise in linux exploitation.",
    courseFeatures: [
      "Self Paced",
      "Access To PreyGround",
      "Comprehensive Curriculum",
      "Participation Certificate",
      "Hands-On Practice",
    ],
    courseLink: "/stack-xploit-cyber-security-course",
    coursePhoto: BinaryLogo,
  },
  {
    courseName: "WEBPWN",
    courseType: "Paid",
    courseDescription:
      "WEBPWN is a comprehensive and professional web hacking program designed to empower cybersecurity professionals, ethical hackers, and aspiring web security experts with the knowledge and skills needed to identify and mitigate the most critical web application vulnerabilities. This program not only covers the OWASP Top 10 vulnerabilities but also offers a wide range of challenges to help you practice and hone your skills.",
    courseFeatures: [
      "70 Real World Hacking Challenges",
      "Access To PreyGround",
      "Three Practice Labs",
      "Participation Certificate",
      "Global Ranking",
    ],
    courseLink: "/webpwn-professional-web-hacking-course",
    coursePhoto: Logo,
  },

  {
    courseName: "WebDrub",
    courseType: "Free",
    courseDescription:
      "Hackersprey WEBDRUB is a free program to practice basic to intermediate hacking skills. It offers eight web pentesting challenges and a hacking lab that teaches students how to find and attack common web vulnerabilities and steal sensitive information from web applications. Students can polish their hacking skills with Hackersprey's webdrub program.",
    courseFeatures: [
      "Certificate on cracking the Tough Nut Lab",
      "Reading Resources",
      "Completely Hands On",
      "24X7 Access",
      "Engaging Challenges",
    ],
    courseLink: "/webdrub",
    coursePhoto: Logo1,
  },
  {
    courseName: "REVEX",
    courseType: "Free",
    courseDescription:
      "Revex is a free course designed to help learners practice their reverse engineering skills. The course consists of five challenges that gradually increase in difficulty, allowing learners to develop their skills and gain practical experience in reverse engineering. Each challenge is designed to simulate a real-world scenario and requires learners to use their knowledge of reverse engineering tools and techniques to analyze and understand the functionality of the target program.",
    courseFeatures: [
      "Reading Resources",
      "Completely Hands On",
      "24X7 Access",
      "Engaging Challenges",
    ],
    courseLink: "/revex",
    coursePhoto: Logo2,
  },
];

const Course = () => {
  const [toggle, settoggle] = useState("All");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const filteredCourses = () => {
    if (toggle === "Paid") {
      return courses.filter((course) => course.courseType === "Paid");
    } else if (toggle === "Free") {
      return courses.filter((course) => course.courseType === "Free");
    } else {
      return courses;
    }
  };

  return (
    <div>
      <Helmet>
        <title>Our Hacking Course – Learn Hacking | Hackers Prey</title>
        <meta
          name="description"
          content="Hackers Prey is an excellent place to learn advanced hacking skills and concepts. Learn hacking courses online and gain various skill sets and capabilities."
        />
      </Helmet>

      <div className="section-courses">
        <section className="Dynamicbackground">
          <div className="container mt-5">
            <div class="Heading-main">
              <span>COURSES</span>
              <h2>GET A COURSE!</h2>
              <hr className="offset-md-5"></hr>
            </div>
          </div>
          <div className="container mt-4">
            <div className="row justify-content-center mt-5">
              <p className="course_para">
                Take up the self-learning program to fuel up the hacker inside
                you.{" "}
              </p>
            </div>
          </div>
        </section>

        <div className="toggle-button">
          <p
            onClick={() => settoggle("All")}
            className={toggle === "All" ? "toggle-list-click" : "toggle-list"}
          >
            All
          </p>
          <p
            onClick={() => settoggle("Paid")}
            className={toggle === "Paid" ? "toggle-list-click" : "toggle-list"}
          >
            Paid
          </p>
          <p
            onClick={() => settoggle("Free")}
            className={toggle === "Free" ? "toggle-list-click" : "toggle-list"}
          >
            Free
          </p>
        </div>

        <div className="course-section">
          {filteredCourses().map((course, index) => (
            <div className="course-sec">
              <div key={index} className="course-list ">
                <div className="absolute animate-background"></div>
                <div className="absolute animate-background2"></div>

                <div className="course-content">
                  <div>
                    <p className="course-title">{course.courseName}</p>
                    <div className="horizontal-line"></div>
                  </div>
                  <div className="course-desc">
                    <p>{course.courseDescription}</p>
                    <p className="course-outline">Features</p>
                    <ul className="course-outline-list">
                      {course.courseFeatures.map((feature) => (
                        <li className="course-outline-list-item">
                          <MdCircle color="#d50000" /> {feature}
                        </li>
                      ))}
                    </ul>
                  </div>
                  <Link to={course.courseLink}>
                    {" "}
                    <p className="course-button">
                      Know More <BsArrowRight height={25} width={30} />
                    </p>{" "}
                  </Link>
                </div>
                <div className="image-block">
                  <img
                    src={course.coursePhoto}
                    className="image-shadow"
                    height={250}
                    width={250}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Course;
