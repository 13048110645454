import React, { useState, useEffect } from "react";
import "react-calendar/dist/Calendar.css";
import Alreadyslotbbok from "./Alreadyslotbbok";
import { useNavigate } from "react-router-dom";
import Loading from "../Loading";
import InvalidDate from "./InvalidDate";
import Bookslotsuccess from "./Bookslotsuccess";
import {ImCross} from "react-icons/im";
import axios from "axios";
import Mascot from "../../../../Assets/MASCOT/Mascot SVG/trophymascot.png";
import Sadmascot from "../../../../Assets/MASCOT/Mascot SVG/sadmascot.png"
const API_Key = process.env.REACT_APP_API_URL;
const ViewResult = ({ setOpenModal, courseId,examId }) => {
  const [loading,setLoading]=useState(true)
  const [result,setResult]=useState(null);
  const [resultmessage,setResultmessage]=useState('')
  const navigate=useNavigate();
  const fetchResult = async () => {
    // Update the component only if it's still mounted
    try {
      const res = await fetch(`${API_Key}/view_results`, {
        method: "POST",
        headers: {
          mode: "cors",
          "Access-Control-Allow-Origin": `${API_Key}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Credentials": true,
        },
        body:JSON.stringify({examId:examId}),
        credentials: "include",
      });
      const data = await res.json();
      if(data.success){
        setLoading(false)
        setResult(data.data)
     
      }
      else{
        setLoading(false)
        setResultmessage(data.message)

      }
    
      // Update the state only if it's still mounted
      
    } catch (err) {
      console.log(err);
      navigate("/signin");
    }
  };
  useEffect(() => {
   fetchResult()
  }, [])
  const API_Key = process.env.REACT_APP_API_URL;

  const downloadCertificate=async()=>{
    const data=await axios.get(`${API_Key}/get_certificate`, {
      responseType: 'arraybuffer',
      headers: {
          Accept: 'application/pdf',
      },
      withCredentials:true
    })
    const blob = new Blob([data.data], { type: 'application/pdf' });
     
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    console.log('urlis',url)
    a.href = url;
    a.download = 'output.pdf';
    a.click();
   
  }

  

  return (
    <div>
      {loading===true ? <Loading/>:<></>}
     
      <section className="modalBackground justify-content-center">
        <div className="container  justify-content-center">
          <div className="row justify-content-center">
            <div className="col-lg-8 col-md-10 col-10  modalContainer">
            <div className="heading-div">
            <ImCross  onClick={() => {
            setOpenModal(false);
          }} fontSize={7} className="mt-5"/>
              </div>
              <h2 className="text-center alertmainhead2">
                Result{" "}
                <div className="row justify-content-center">
                  <span>
                    <hr className="text-center alertcommonhr"></hr>
                  </span>
                </div>
              </h2>
              {result===null ? <p className="exam-text">No exam found</p> : <div className="view-result-content">
             { result?.percentage>=70 ? 
              
              <div className="row justify-content-center"><p className="text-heading">Congratulations! You've successfully passed your certification examination. Well done on achieving this significant milestone! </p></div> : <div className="row justify-content-center"><p className="text-heading">Hi Pranay, it appears you didn't pass the exam. Please click on 'Retake Exam' to try again.</p></div>}
             
             <div className="row justify-content-center">
             {result?.percentage >= 70 ? (
              <div className="col-lg-3 col-md-6 col-10">
                <img src={Mascot} alt="alt" />
              </div>
            ) : <div className="col-lg-3 col-md-6 col-10">
            <img src={Sadmascot} alt="alt" />
          </div>}
   
             <div className="col-lg-8 col-md-8 col-10">
             <div className="row">
             <div className="col-lg-4 col-md-4 col" >
             <div id="marksdiv">
             <h1 id="marksdivhead1">{result?.total_score}</h1>
             </div>
             <div className="row justify-content-center">
             <h2 id="marksdivhead">Total Marks</h2>
             </div>
             </div>
             <div className="col-lg-4 col-md-4 col" >
             <div id="marksdiv">
             <h1 id="marksdivhead1">{result?.score_obtained}</h1>
             </div>
             <div className="row justify-content-center">
             <h2 id="marksdivhead"> Marks Obtained</h2>
             </div>
             </div>
             <div className="col-lg-4 col-md-4 col" >
             <div id="marksdiv">
             <h1 id="marksdivhead1">{result?.percentage?.toFixed(1)}</h1>
             </div>
             <div className="row justify-content-center">
             <h2 id="marksdivhead">Percentage</h2>
             </div>
             </div>
             </div>
            
             </div>
             <div className="col-lg-1"></div>
             </div>
             <div className="row justify-content-center">
             
             {result?.percentage>=70 ?   <div
              className="certi-button col-lg-4 col-md-4 col-10 mb-5"
              id="havocsubmitbtn"
            >
              <button className="btn" onClick={()=>downloadCertificate()}>
               
                Download Certificate
              </button>
            </div> :<div
              className="col-lg-4 col-md-4 col-10 mb-5"
              id="havocsubmitbtn"
            >
              <button onClick={()=>setOpenModal(false)} className="btn">
               
              Retake Exam
              </button>
            </div>}
             </div>
          
             
              </div>}
             
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ViewResult;
