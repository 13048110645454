import React, { useEffect } from "react";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import Congrats from "../../Assets/MASCOT/Mascot SVG/Asset 29.png";

import { FaArrowRight } from "react-icons/fa";

import { NavLink } from "react-router-dom";
const Subscriptionsucess2 = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <section className="commonbackground">
        <div id="registrationdivwaves">
          <div className="container">
            <div className="row justify-content-center ">
              <div
                className=" col-lg-8 col-md-8 col-8 p-5 mt-5 mb-5"
                id="registerdiv"
              >
                <div className="row p-5">
                  <div className="col-lg-4 col-md-5 col-0  ">
                    <div className="justify-content-center pl-5">
                      <img
                        src={Congrats}
                        style={{ height: "45vh" }}
                        alt="sorry"
                      />
                    </div>
                  </div>
                  <div className="col-lg-8 col-md-5 col-12   ">
                    <div className="justify-content-center">
                      <h2 className="text-center alertmainhead2">
                        Enrollment Successful!
                        <div className="row justify-content-center">
                          <span>
                            <hr className="text-center alertcommonhr"></hr>
                          </span>
                        </div>
                      </h2>
                    </div>
                    <p className="resetpara">
                      To access the Industrial Training, simply log in to
                      the Hackersprey portal and navigate to the Training
                      section.
                    </p>

                    <div className="row justify-content-center"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Subscriptionsucess2;
