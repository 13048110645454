import React,{useState} from 'react'
import BinaryCurriculum2 from '../Dyanmicpages/BinaryCurriculum2/BinaryCurriculum2'
import { GrFormCheckmark } from "react-icons/gr";
import { FaAngleRight } from "react-icons/fa6";

const curriculum = [
    {
      sectionName: "Week 1",
      sectionDesc:[{
        topic:"Basics of Network Theory:",
        desc:"Covers IPv4 addressing, OSI Model, and NAT/PAT concepts."
      },
      {
        topic:"Linux Fundamentals:",
        desc:" Introduction to the Linux operating system and basic command-line usage."
      },
      {
        topic:"Introduction to Cyber Security:",
        desc:"Overview of malware types such as viruses, worms, and methods of infection."
      },
      {
        topic:"Information Gathering:",
        desc:"Techniques for gathering information about target systems, including active and passive methods."
      },
      {
        topic:"Network Scanning:",
        desc:"Utilizing scanning tools to discover devices and services on a network."
      },
      
    ]
    },
  
    {
      sectionName: "Week 2",
      sectionDesc:[{
        topic:"Netcat, Ncat & Shell:",
        desc:"Introduction to network utility tools like Netcat and Ncat, along with basic shell commands."
      },
      {
        topic:"Malware Delivery Techniques:",
        desc:"Understanding methods of malware delivery, including Word macro-based attacks."
      },
      {
        topic:"Working with RATs:",
        desc:" Exploring Remote Access Trojans and their functionalities."
      },
      {
        topic:"AV Evasion Techniques:",
        desc:"Discussion on techniques to evade antivirus detection, such as process hollowing and running portable executables."
      },
      {
        topic:"Password Dumping:",
        desc:"Techniques for extracting passwords from target systems."
      },
      
    ]
    },
  
    {
      sectionName: "Week 3",
      sectionDesc:[{
        topic:"CSRF File Upload Vulnerability & Web Shells:",
        desc:" Understanding Cross-Site Request Forgery vulnerabilities and utilizing web shells for exploitation."
      },
      {
        topic:"LFI/RFI:",
        desc:"Learning about Local File Inclusion and Remote File Inclusion vulnerabilities."
      },
      {
        topic:"SQLi:",
        desc:"Exploring SQL Injection vulnerabilities and exploitation techniques."
      },
      {
        topic:"XSS:",
        desc:"Understanding Cross-Site Scripting vulnerabilities and their impact."
      },
      {
        topic:"Insecure Deserialization:",
        desc:"Discussion on the risks associated with insecure deserialization in web applications."
      },
      {
        topic:"IDOR:",
        desc:"Understanding Insecure Direct Object Reference vulnerabilities."
      }
      
    ]
    },
  
  
    {
      sectionName: "Week 4",
      sectionDesc:[{
        topic:"Buffer overflow:",
        desc:"Introduction to Buffer Overflow."
      },
      {
        topic:"",
        desc:"Shellcoding"
      },
      {
        topic:"",
        desc:"Format Strings"
      },
      {
        topic:"",
        desc:"Return Oriented Programming"
      },
      {
        topic:"",
        desc:"Ret2libc"
      },
      
      
    ]
    },
  
  
    {
      sectionName: "Week 5",
      sectionDesc:[{
        topic:"",
        desc:"Introduction to Android Pentest"
      },
      {
        topic:"",
        desc:"In-depth exploration of Active Directory environments, including common attack vectors and defenses. "
      },
      {
        topic:"",
        desc:"Application Security "
      },
      {
        topic:"",
        desc:"Reverse application and Android manifest file security issues. "
      },
      {
        topic:"",
        desc:"Insecure Platform Usage  & Data Storage "
      },
      {
        topic:"",
        desc:"Debugging"
      },
      
      
      
    ]
    },
    {
      sectionName: "Week 6",
      sectionDesc:[{
        topic:"Active Directory:",
        desc:"In-depth exploration of Active Directory environments, including common attack vectors and defenses."
      },
      
      
    ]
    },
  ];

  const benefits=[
    'Access to industry-recognized certifications upon completion',
    'Supplementary videos and tools to support and enhance learning',
    'Updated Curriculum with the latest technologies and advancements',
    
    'Real-World Hacking Challenges',
    'Practice labs for practical application of theoretical knowledge',
    'Acceleration of career growth opportunities',
    'Personalized mentorship and guidance',
    
    'Development of job-ready skills and competencies',
]
const TrainingCurriculum = () => {
  const [showCourse,setShowCourse]=useState(0)
  return (
    <div className='training-cont'>
   <h3 className='training-heading5'>Course Curriculum Of Our 6 Weeks Summer Training / Internship In Cybersecurity</h3>
   
    <div className='training-highlight-section4'>
      
    <div className='training-highlight-section-left-new'> 
   
    <p className='training-desc2'>
      <p className={`${showCourse===0 ? 'training-desc-week-click': 'training-desc-week' }`} onClick={()=>setShowCourse(0)}>Week 1: Foundations of Network Explorationimport <FaAngleRight/></p>
      <p className={`${showCourse===1 ? 'training-desc-week-click': 'training-desc-week' }`} onClick={()=>setShowCourse(1)}>Week 2: Exploring Network Utilities and Malicious Tactics <FaAngleRight/></p>
      <p className={`${showCourse===2 ? 'training-desc-week-click': 'training-desc-week' }`} onClick={()=>setShowCourse(2)}>Week 3: Web Application Vulnerabilities Unveiled <FaAngleRight/></p>
      <p className={`${showCourse===3 ? 'training-desc-week-click': 'training-desc-week' }`} onClick={()=>setShowCourse(3)}>Week 4: Buffer Overflow <FaAngleRight/></p>
      <p className={`${showCourse===4 ? 'training-desc-week-click': 'training-desc-week' }`} onClick={()=>setShowCourse(4)}>Week 5:  Android Pentesting <FaAngleRight/></p>
      <p className={`${showCourse===5 ? 'training-desc-week-click': 'training-desc-week' }`} onClick={()=>setShowCourse(5)}>Week 6: Active Directory <FaAngleRight/></p>
    </p>
    </div>
    <div className='training-highlight-section-right4'> 
    {curriculum.map((x,index)=>{
      if(index===showCourse){
       return  x.sectionDesc.map((sec,index)=>(
          <div key={index} className="display-flex">
          <div className="lab-desc-list-icon-wrapper">
          <GrFormCheckmark className="lab-desc-list-icon" />
          </div>
          <p className="training-list"><span>{sec.topic}</span> {sec.desc}</p>
          </div>  
                ))
      }
      

    })}
   
           
    </div>

</div>
</div>
  )
}

export default TrainingCurriculum
