import React, { useState, useEffect } from "react";

import Mainpic from "../../../Assets/SlotBook/mainbook.png";
import { useNavigate, NavLink } from "react-router-dom";
import "./Bookslot.css";


import { CgCommunity } from "react-icons/cg";

import email1 from "../../../Assets/Dashboard/Mails/Screenshot 2023-06-12 at 9.30.18 AM.png";
import { Faqs2 } from "../../Home/Homedata";
import webpwn from "../../../Assets/Entity/Course/webpwn.png";
import noslot from "../../../Assets/Dashboard/noslot.png";
import BookingModal from "../Modals/BookSlotModal/BookingModal";
import Examsubmitflags from "../Modals/BookSlotModal/Examsubmitflags";
import Loading from "../Modals/Loading";
import ViewResult from "../Modals/BookSlotModal/ViewResult";
import StartExam from "../Modals/BookSlotModal/StartExam";
import Maccot from "../../../Assets/MASCOT/Mascot SVG/5.png"
import { CiServer } from "react-icons/ci";
import { IoStatsChartOutline } from "react-icons/io5";
function getDateOnly(dateTimeString) {
  // Parse the string into a Date object
  const dateTime = new Date(dateTimeString);
  console.log(dateTime, "date.....");
  // Extract individual components
  const year = dateTime.getFullYear();
  const month = String(dateTime.getMonth() + 1).padStart(2, "0");
  const day = String(dateTime.getDate()).padStart(2, "0");

  // Create the date string in the format YYYY-MM-DD
  const dateOnlyString = `${day}-${month}-${year}`;

  return dateOnlyString;
}

const Bookslot = (props) => {
  const API_Key = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  let isMounted = true;

  const [item, setitem] = useState("A");
  const [item2, setitem2] = useState("A");
  const [item3, setitem3] = useState("");
  const [modal, setopenmodal] = useState(false);
  const [openSlotBookModal, setopenSlotBookModal] = useState(false);
  const [openFlagSubmitModal, setopenFlagSubmitModal] = useState(false);
  const [openExamModal, setopenExamModal] = useState(false);
  const [coursespaid, setCoursespaid] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState("");
  const [slotInfo, setSlotInfo] = useState(null);
  const [showResultModal, setShowResultModal] = useState(false);
  const [selectedExam, setSelectedExam] = useState("");

  const [showStartExamModal,setShowStartExamModal]=useState(false);
  const [examModalError,setExamModalError]=useState(false)


  const [clicked, setClicked] = useState(false);
  const[showinstance,setshowinstance]=useState(false);
  const toggle = (index) => {
    if (clicked === index) {
      //if clicked question is already active, then close it
      return setClicked(null);
    }

    setClicked(index);
  };
  const fetchinstance = async () => {
    // Update the component only if it's still mounted
    if (isMounted) {
      setopenmodal(true);
    }
    try {
      const res = await fetch(`${API_Key}/getexam_time`, {
        method: "GET",
        headers: {
          mode: "cors",
          "Access-Control-Allow-Origin": `${API_Key}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Credentials": true,
        },
        credentials: "include",
      });
      const data = await res.json();
      // Update the state only if it's still mounted

if(res.status===200){
setshowinstance(true);
}
    } catch (err) {
      console.log(err);
      navigate("/signin");
    }
  };
  const fetchpaidCourses = async () => {
    // Update the component only if it's still mounted
    if (isMounted) {
      setopenmodal(true);
    }
    try {
      const res = await fetch(`${API_Key}/getcourse_details`, {
        method: "GET",
        headers: {
          mode: "cors",
          "Access-Control-Allow-Origin": `${API_Key}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Credentials": true,
        },
        credentials: "include",
      });
      const data = await res.json();
      // Update the state only if it's still mounted
      if (isMounted) {
        setCoursespaid(data.allcourseData);
        setopenmodal(false);
      }
    } catch (err) {
      console.log(err);
     navigate("/signin");
    }
  };

  const fetchSlots = async () => {
    // Update the component only if it's still mounted
    if (isMounted) {
      setopenmodal(true);
    }
    try {
      const res = await fetch(`${API_Key}/get_examslot_details`, {
        method: "GET",
        headers: {
          mode: "cors",
          "Access-Control-Allow-Origin": `${API_Key}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Credentials": true,
        },
        credentials: "include",
      });
      const data = await res.json();
      console.log("data", data.data);
      setSlotInfo(data.data);
      console.log(data.data,"sukh")
      // Update the state only if it's still mounted
      if (isMounted) {
        setopenmodal(false);
      }
    } catch (err) {
      console.log(err);
     
    }
  };
  const handleResult = async (examid) => {
    setSelectedExam(examid);
    setShowResultModal(!showResultModal);
  };

  useEffect(() => {
    // Set the component as mounted when it mounts
    isMounted = true;

    fetchpaidCourses();
    fetchinstance();

    // Cleanup function to set the component as unmounted when it unmounts
    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    fetchSlots();

    // Cleanup function to set the component as unmounted when it unmounts
    return () => {
      isMounted = false;
    };
  }, []);

  // ... (rest of your component code)

  const handleBookSlot = (courseid) => {
    setSelectedCourse(courseid);
    setopenSlotBookModal(!openSlotBookModal);
  };
  const handleStartExam = async () => {
    try {
      console.log("exam id", slotInfo.slot[0].courseId[0].exam_id);
      const res = await fetch(`${API_Key}/start_exam`, {
        method: "POST",
        headers: {
          mode: "cors",
          "Access-Control-Allow-Origin": `${API_Key}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Credentials": true,
        },
        credentials: "include",
        body: JSON.stringify({ id: slotInfo.slot[0].courseId[0].exam_id }),
      });
      const data = await res.json();
      console.log(res,"dataaaaaaa")
      // Update the state only if it's still mounted
      if(res.status===200){
        setShowStartExamModal(true);
        setExamModalError(["Exam Started Successfully !!"]);
      }
      else{
        setShowStartExamModal(true);
        setExamModalError(["Exam Not Started"]);
      }
    } catch (err) {
      console.log(err);
      navigate("/signin");
    }
  };



  const createinstanceforexam = async () => {
    //setopenmodal(true);
   fetch(`${API_Key}/createexaminstance`, {
      method: 'POST',
      headers:{
        mode: 'cors',
       'Content-Type': 'application/json',
       'Access-Control-Allow-Credentials': `${API_Key}`,
       'Access-Control-Allow-Credentials': true,
       'x-api-key':'YrhiLjoPqR6ws0k8gkoKq6UwQlm7Dila1HaWev9V'
      },
      credentials:"include",
   
    })
    .then((response) => { 
  if(response.status===200){
    //setModalOpen2(true);
    setopenmodal(false);
  }
  
    }).catch((error) => {
      console.error('Error:', error);
     // setModalOpen2(true);
     
    
       
     });
   };
   const checkinstancestatus = async () => {
    // Update the component only if it's still mounted
    if (isMounted) {
      setopenmodal(true);
    }
    try {
      const res = await fetch(`${API_Key}/checkexaminstance`, {
        method: "GET",
        headers: {
          mode: "cors",
          "Access-Control-Allow-Origin": `${API_Key}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Credentials": true,
        },
        credentials: "include",
      });
      const data = await res.json();
      // Update the state only if it's still mounted
      console.log(data);
    } catch (err) {
      console.log(err);
      //history.push("/signin");
    }
  };
   const stopinstanceforexam = async () => {
    //setopenmodal(true);
   fetch(`${API_Key}/stopexaminstance`, {
      method: 'POST',
      headers:{
        mode: 'cors',
       'Content-Type': 'application/json',
       'Access-Control-Allow-Credentials': `${API_Key}`,
       'Access-Control-Allow-Credentials': true,
       'x-api-key':'YrhiLjoPqR6ws0k8gkoKq6UwQlm7Dila1HaWev9V'
      },
      credentials:"include",
   
    })
    .then((response) => { 
  if(response.status===200){
    //setModalOpen2(true);
    setopenmodal(false);
  }
  
    }).catch((error) => {
      console.error('Error:', error);
     // setModalOpen2(true);
     
    
       
     });
   };

  return (
    <div>

      {showStartExamModal ? <StartExam examModalError={examModalError}  setOpenModal={()=>setShowStartExamModal(false)}/> : <></>}

      {showResultModal ? (
        <ViewResult
          setOpenModal={() => setShowResultModal(false)}
          examId={selectedExam}
        />
      ) : (
        <></>
      )}
      {modal && <Loading setOpenModal={setopenmodal} />}
      {openSlotBookModal ? (
        <BookingModal
          courseId={selectedCourse}
          setOpenModal={() => setopenSlotBookModal(!openSlotBookModal)}
        />
      ) : (
        <></>
      )}
      {openFlagSubmitModal ? (
        <Examsubmitflags
          id={slotInfo.slot[0].courseId[0].exam_id}
          setOpenModal={() => setopenFlagSubmitModal(!openFlagSubmitModal)}
        />
      ) : (
        <></>
      )}
   
      <div className="commontopper p-1">
        <br></br> <br></br> <br></br>
        <div className="container">
          <div className="row justify-content-center mt-5">
            <div className="col-lg-8 col-md-7 col-10 mt-5">
              <h1 className="slotbookhead">Exam Slot Booking</h1>
              <p className="slotbookpara">
              Unlock exclusive Certification Opportunities at HackersPrey! Trust your ethical hacking skills, book your exam session, and earn a prestigious professional certification valid for 3 years upon successful clearance. This certification experience is reserved for users with a paid subscription, fostering a community of passionate cybersecurity enthusiasts on their journey to excellence .
              </p>
            </div>

            <div className="col-lg-4 col-md-4 col-10 mt-5">
              <img src={Mainpic} alt="mainpic" />
            </div>
          </div>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-lg-9 col-md-9 col-12 mt-5 ">
          <div className="row p-2">
            <div
              className=" col-lg-2 col-md-12 col-12 m-0"
              style={{ background: "none" }}
              onClick={() => setitem("A")}
            >
              <div
                className={
                  item === "A"
                    ? "row justify-content-center mt-2 mb-5 p-4 topbtn2"
                    : "row justify-content-center mt-2 mb-5 p-4 topbtn"
                }
              >
                {" "}
                <h1 className="enterpreyhead"> Event OverView</h1>
              </div>
            </div>

            <div
              className=" col-lg-2 col-md-12 col-12 m-0"
              style={{ background: "none" }}
              onClick={() => setitem("B")}
            >
              <div
                className={
                  item === "B"
                    ? "row justify-content-center mt-2 mb-5 p-4 topbtn2"
                    : "row justify-content-center mt-2 mb-5 p-4 topbtn"
                }
              >
                <h1 className="enterpreyhead"> Schedule Exam</h1>
              </div>
            </div>

            <div
              className=" col-lg-2 col-md-12 col-12 m-0"
              style={{ background: "none" }}
              onClick={() => setitem("C")}
            >
              <div
                className={
                  item === "C"
                    ? "row justify-content-center mt-2 mb-5 p-4 topbtn2"
                    : "row justify-content-center mt-2 mb-5 p-4 topbtn"
                }
              >
                <h1 className="enterpreyhead"> Your Timeline</h1>
              </div>
            </div>
          </div>
          {item === "A" && (
            <>
              <div className="container p-0">
                <div className="row mt-4">
                  <div className="col-lg-8 col-md-8 col-12">
                    <div className="row">
                      <h1 className="overviewhead2">
                      Primary Attempt 
                        <div className="row">
                          <span>
                            <hr className="havochr ml-4"></hr>
                          </span>
                        </div>
                      </h1>
                      <div className="row p-4 ">
                        <p className="havocpara">
                        All paid users of their respective programs are granted their first certification exam attempt for free. You can schedule your exam at any time during your course and, if necessary, reschedule it once. Don't miss out – if you haven't purchased the program yet, seize the opportunity to start your certification journey today! 
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <h1 className="overviewhead2">
                      Retrial Quest
                        <div className="row">
                          <span>
                            <hr className="havochr ml-4"></hr>
                          </span>
                        </div>
                      </h1>
                      <div className="row p-4 ">
                        <p className="havocpara">
                        Didn't pass on your first try? No worries! You can opt for a second attempt by paying the exam fee. Take another shot at certification, and use this opportunity to learn, grow, and achieve your goals in your Retrial Quest. 
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-1 col-md-1 col-1 mt-0"></div>
                <div className="col-lg-3 col-md-9 col-12 mt-3">
                    <br></br>
                   
                  
                
                  <div className='row justify-content-center  mt-5' >
<div className='col-lg-12 col-md-12 col-10' id='havocsubmitbtn'>
<button className='btn' onClick={createinstanceforexam}><span><CiServer color='white' size={30} style={{marginRight:"1.2rem"}}/></span>Create Instance</button>
</div>
</div>
<div className='row justify-content-center  mt-5' >
<div className='col-lg-12 col-md-12 col-10' id='havocsubmitbtn'>
<button className='btn' onClick={checkinstancestatus} ><span><IoStatsChartOutline color='white' size={30} style={{marginRight:"1.2rem"}}/></span>Start Instance</button>
</div>
</div>
<div className='row justify-content-center  mt-5' >
<div className='col-lg-12 col-md-12 col-10' id='havocsubmitbtn'>
<button className='btn' onClick={stopinstanceforexam} ><span><CgCommunity color='white' size={30} style={{marginRight:"1.2rem"}}/></span>Stop Instance</button>
</div>
</div>
                  </div>
                </div>
              </div>
             {/*} <div className="container mt-5 mb-5">
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-12 mt-5 intershipdetaildiv2">
                    <div
                      className="row justify-content-center"
                      style={{ position: "relative" }}
                    >
                      <h2 className="justify-content-center mainfaqhead">
                        FAQ'S
                      </h2>

                      <div className="row mt-5 ">
                        {" "}
                        <hr className="faqhr"></hr>
                      </div>
                    </div>
                    <div className="row justify-content-center ">
                      <div className="col-lg-10 col-md-10 col mt-3">
                        <p className="mainfaqpara">
                          Please note that these FAQs provide general
                          information about the WebHack program. For more
                          detailed information and specific queries, it is
                          recommended to reach out to us at{" "}
                          <img
                            src={email1}
                            alt="email"
                            style={{
                              height: "30px",
                              marginLeft: ".3rem",
                              marginTop: "-1px",
                            }}
                          />
                        </p>
                      </div>
                    </div>

                    <div className="row justify-content-center mt-5">
                      <div className="col-lg-10 col-md-10 col-10">
                        {Faqs2.map((faq, index) => (
                          <div
                            className="row mt-5 mb-5"
                            key={index}
                            id="faqcards"
                          >
                            <div
                              className="col-lg-10 col-md-10 col-8"
                              onClick={() => toggle(index)}
                              key={index}
                              style={{ cursor: "pointer" }}
                            >
                              <h2 className="faqcardshead">{faq.question}</h2>
                            </div>
                            <div
                              className="col-lg-1 col-md-1 col-1 offset-1"
                              onClick={() => toggle(index)}
                              key={index}
                              style={{ cursor: "pointer" }}
                            >
                              {clicked === index ? (
                                <AiOutlineMinusCircle
                                  color="#e32f1c"
                                  size={30}
                                />
                              ) : (
                                <AiOutlinePlusCircle
                                  color="#e32f1c"
                                  size={30}
                                />
                              )}
                            </div>
                            {clicked === index ? (
                              <>
                                {" "}
                                <div className="container mt-1 p-3">
                                  <div className="row">
                                    <div className="col-lg-11 col-md-11 col-11 ">
                                      <p className="faqansercard">
                                        {faq.answer}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </>
                            ) : null}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
                            </div>*/}
            </>
          )}

          {item === "B" && (
            <>
              <div className="container mt-5 mb-5 ">
                <div className="row mb-5 justify-content-center">
                  {coursespaid?.length === 0 ? (
                    <div className="col-lg-12 col-md-9 col-10  " id="bookslotdiv">
                      <div className="row justify-content-center">
                        <div
                          className="col-lg-9 col-md-9 justify-content-center"
                          id="bookslotimage"
                        >
                          <h2 className="text-center overviewhead2 mt-4 mb-2">
                          Certification Awaits: Join a Program to Begin Your Journey
                            <div className="row justify-content-center">
                              <span>
                                <hr className="text-center alertcommonhr mb-2"></hr>
                              </span>
                            </div>
                          </h2>
                          <p className="slotbookingpara">
                          Currently, there is no active course subscription associated with your account. To participate in the certification exam, kindly enroll in one of our programs and unlock a wealth of knowledge and skills. Start your learning journey today!
                          </p>

<div className="row justify-content-center">
<div className="col-lg-4 mt-5">
                        <NavLink to="/masterplan">  <button className="btn slotbookbtn mb-4">
                            Buy Course
                          </button></NavLink>
                          </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-3 mt-4">
                          <img src={noslot} alt="img" />
                        </div>
                      </div>
                    </div>
                  ) : (
                    coursespaid?.map((course) => (
                      <div className="col-lg-4 col-md-4 col-12">
                        <div className="card" id="halloffamecard4">
                          <div
                            className="row justify-content-center  mb-2 p-0 pl-2 mt-5"
                            id="uniadvantegphotodic2"
                          >
                            <img
                              src={course?.cover_image}
                              style={{ height: "20rem" }}
                              id="check"
                              alt="curriculu"
                            />
                          </div>

                          <div className="row  p-4 mb-5">
                            <div className="col-12 mt-4">
                              <div className="row justify-content-center ">
                                <p className="advantagehead4  text-capital">
                                  {course?.coursename}
                                </p>
                              </div>
                              <div className="row justify-content-center">
                                <div
                                  className="col-lg-6 col-md-6 col-10 mt-4"
                                  id="takeexamdiv"
                                  onClick={() => handleBookSlot(course._id)}
                                >
                                  Book Your Slot
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  )}
                </div>
              </div>

              {/*<div className="container mt-5 mb-5">
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-12 mt-5 intershipdetaildiv2">
                    <div
                      className="row justify-content-center"
                      style={{ position: "relative" }}
                    >
                      <h2 className="justify-content-center mainfaqhead">
                        FAQ'S
                      </h2>

                      <div className="row mt-5 ">
                        {" "}
                        <hr className="faqhr"></hr>
                      </div>
                    </div>
                    <div className="row justify-content-center ">
                      <div className="col-lg-10 col-md-10 col mt-3">
                        <p className="mainfaqpara">
                          Please note that these FAQs provide general
                          information about the WebHack program. For more
                          detailed information and specific queries, it is
                          recommended to reach out to us at{" "}
                          <img
                            src={email1}
                            alt="email"
                            style={{
                              height: "30px",
                              marginLeft: ".3rem",
                              marginTop: "-1px",
                            }}
                          />
                        </p>
                      </div>
                    </div>

                    <div className="row justify-content-center mt-5">
                      <div className="col-lg-10 col-md-10 col-10">
                        {Faqs2.map((faq, index) => (
                          <div
                            className="row mt-5 mb-5"
                            key={index}
                            id="faqcards"
                          >
                            <div
                              className="col-lg-10 col-md-10 col-8"
                              onClick={() => toggle(index)}
                              key={index}
                              style={{ cursor: "pointer" }}
                            >
                              <h2 className="faqcardshead">{faq.question}</h2>
                            </div>
                            <div
                              className="col-lg-1 col-md-1 col-1 offset-1"
                              onClick={() => toggle(index)}
                              key={index}
                              style={{ cursor: "pointer" }}
                            >
                              {clicked === index ? (
                                <AiOutlineMinusCircle
                                  color="#e32f1c"
                                  size={30}
                                />
                              ) : (
                                <AiOutlinePlusCircle
                                  color="#e32f1c"
                                  size={30}
                                />
                              )}
                            </div>
                            {clicked === index ? (
                              <>
                                {" "}
                                <div className="container mt-1 p-3">
                                  <div className="row">
                                    <div className="col-lg-11 col-md-11 col-11 ">
                                      <p className="faqansercard">
                                        {faq.answer}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </>
                            ) : null}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
                            </div>*/}
            </>
          )}

          {item === "C" && (
            <>
              <div className="container">
                <div className="row justify-content-center">
                  {slotInfo === null ? (
                    <div className="col-lg-12 col-md-12 col-10" id="bookslotdiv">
                      <div className="row justify-content-center">
                        <div
                          className="col-lg-9 col-md-9 justify-content-center"
                          id="bookslotimage"
                        >
                          <h2 className="text-center overviewhead2 mt-4 mb-2">
                            No Slot Found{" "}
                            <div className="row justify-content-center">
                              <span>
                                <hr className="text-center alertcommonhr"></hr>
                              </span>
                            </div>
                          </h2>
                          <p className="slotbookingpara mt-3">
                          Currently, there is no active course subscription associated with your account. To participate in the certification exam, kindly enroll in one of our programs and unlock a wealth of knowledge and skills. Start your learning journey today!
                          </p>
<div className="row justify-content-center">
<div className="col-lg-4 col-md-4 mt-3">
                          <button className="btn slotbookbtn mb-4" onClick={()=>setitem("B")}>
                            Slot Book
                          </button>
                          </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-3 mt-4">
                          <img src={noslot} alt="img" />
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="col-lg-9 col-md-9 col-10" id="bookslotdiv">
                      <div className="row justify-content-center">
                        <div className="col-lg-3 col-md-3" id="bookslotimage">
                          <img src={slotInfo?.course[0]?.cover_image} alt="img" />
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <h2 className="text-center overviewhead2 mt-2">
                            {slotInfo?.course[0]?.coursename}
                          </h2>
                          <p className="slotbookingpara">
                          {slotInfo?.course[0]?.desc}
                          </p>

                          <div className="row">
                            <div className="col-lg-6">
                              <h4 className="slotbookdate">
                                Date:{" "}
                                {getDateOnly(
                                  slotInfo?.slot[0]?.courseId[0]?.booking_time
                                )}
                              </h4>
                            </div>
                            <div className="col-lg-6">
                              <h4 className="slotbookdate">Time: 8.00AM</h4>
                            </div>
                          </div>
                          <div className="row m-2 mt-4">
                            <div className="col-lg-4">
                              <button
                                className="btn slotbookbtn"
                                onClick={() => handleStartExam()}
                              >
                                Start Exam
                              </button>
                            </div>
                            <div className="col-lg-4">
                              <button
                                className="btn slotbookbtn"
                                onClick={() =>
                                  setopenFlagSubmitModal(!openFlagSubmitModal)
                                }
                              >
                                Submit Flag
                              </button>
                            </div>
                            <div className="col-lg-4">
                              <button
                                className="btn slotbookbtn"
                                onClick={() =>
                                  handleResult(
                                    slotInfo.slot[0].courseId[0].exam_id
                                  )
                                }
                              >
                                View Result
                              </button>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-2 col-md-2 ml-5">
                          <img src={Maccot} style={{height:"200px"}} alt="img" />

                        </div>
                        <div></div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default (Bookslot);
