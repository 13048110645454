import React from 'react';
import {motion} from 'framer-motion';


const WhyHackersprey = ({heading,hackerspreyPoint,conventionalPoint,headings,headerDesc}) => {
  return (
    <motion.div initial={{y:100,opacity:0}} whileInView={{y:0,opacity:1}} viewport={{once:true}} transition={{duration:1.5}} className='why-hackersprey'>
        <p className='training-heading'>{heading}</p>
        <div style={{display:"flex", justifyContent:"center"}}>
            <hr className="acwphr2"></hr>
          </div>
        <p className='training-description'>{headerDesc} </p>
        
        <div className='comparison-box'>
        <div className='table-container'>
           <div className='heading-card'>
            <p className='card-heading'></p>
            {headings.map((x,index)=>(
                <div key={index} className='card-points' >{x}</div>
            ))}
            
           </div>

        </div>
           <div className='table-container'>
           <div className='hackersprey-card'>
            <p className='card-heading'>Hackersprey's Program</p>
            {hackerspreyPoint.map((x,index)=>(
                <div key={index} className='card-points'>{x}</div>
            ))}
            
           </div>

        </div>
        <div className='table-container'>
           <div className='comparison-card'>
            <p className='card-heading'>Conventional Programs</p>
            {conventionalPoint.map((x,index)=>(
                <div  className='card-points' key={index}>{x}</div>
            ))}
            
           </div>

        </div>
    </div>
    </motion.div>
  )
}

export default WhyHackersprey