import React, { useState } from 'react'
import BinaryCurriculum2 from '../Dyanmicpages/BinaryCurriculum2/BinaryCurriculum2'
import { GrFormCheckmark } from "react-icons/gr";
import { FaAngleRight } from "react-icons/fa6";
import { TfiDownload } from "react-icons/tfi";
import { FaAngleDown } from 'react-icons/fa';

const curriculum = [
  {
    sectionName: "Active Directory",
    sectionDesc: [
      {
        topic: "Initial Access & Evasion",
        desc: "Techniques for external scanning, reverse and bind shells, evading Windows Defender, and bypassing UAC."
      },
      {
        topic: "Credential Dumping & Malware Persistence",
        desc: "Extracting Windows credentials, malware persistence strategies, and cracking credential vaults."
      },
      {
        topic: "Advanced Port Forwarding & Pivoting",
        desc: "Remote and dynamic port forwarding with SSH, and advanced traffic routing using Chisel."
      },
      {
        topic: "Browser & Email Credential Access",
        desc: "Dumping browser saved credentials and accessing internal email accounts."
      },
      {
        topic: "Spear Phishing & C2 Configurations",
        desc: "Preparing and executing spear phishing attacks, and advanced C2 setups with AMSI bypass."
      },
      {
        topic: "Privilege Escalation & Enumeration",
        desc: "Active Directory enumeration with PowerView, BloodHound, and RPCClient, and privilege escalation techniques including Kerberoasting and DC attacks."
      },



    ]
  },

  {
    sectionName: "Network Scanning",
    sectionDesc: [{
      topic: "Network Scanning Using NMAP: ",
      desc: "Introduction to NMAP scanning techniques."
    },
    {
      topic: "UDP Scan with NMAP",
      desc: "Basics of performing a UDP scan using NMAP."
    },
    {
      topic: "Telnet Scanning",
      desc: "Scanning with brute-forcing techniques."
    },
    {
      topic: "SMTP Reconnaissance",
      desc: "Techniques for gathering information from SMTP services."
    },
    {
      topic: "SMB Scanning",
      desc: "Comprehensive methods for scanning SMB services."
    },
    {
      topic: "Finger Service Reconnaissance",
      desc: "Using finger service for information gathering."
    },

    ]
  },

  {
    sectionName: "Web Exploitation",
    sectionDesc: [{
      topic: "INFORMATION GATHERING",
      desc: "WHOIS Enumeration, DNS Enumeration, and Active Information Gathering."
    },
    {
      topic: "BASICS OF HTTP",
      desc: "HTTP Fundamentals, Session Management, CORS, and HTTP Connection Vulnerabilities."
    },
    {
      topic: "INPUT HANDLING AND DISCLOSURE",
      desc: "Directory Listing, Parameter Pollution, Command Injection, and Web Cache Poisoning."
    },
    {
      topic: "CSRF & XSS",
      desc: "Cross-Site Request Forgery (GET/POST), Token Manipulation, Reflected, DOM-Based, and Stored XSS."
    },
    {
      topic: "INSECURE DESIGN",
      desc: "JWT Authentication Bypass, Price Manipulation, and Insecure Cart/Wallet Management."
    },
    {
      topic: "Advanced Topics",
      desc: "SQL Injection, XXE, IDOR, SSRF, Insecure Deserialization, SSTI, and RCE."
    }
    ]
  },



];

const benefits = [
  'Access to industry-recognized certifications upon completion',
  'Supplementary videos and tools to support and enhance learning',
  'Updated Curriculum with the latest technologies and advancements',
  'Real-World Hacking Challenges',
  'Practice labs for practical application of theoretical knowledge',
  'Acceleration of career growth opportunities',
  'Personalized mentorship and guidance',
  'Development of job-ready skills and competencies',
]
const TrainingCurriculum = ({ showModal }) => {
  const [showCourse, setShowCourse] = useState(0)
  return (
    <div className="training-cont int-observe ind-curriculum" data-name="3">
      <h3 className='training-heading5'>Course Curriculum Of Our 6 Months Industrial Training In Cybersecurity</h3>

      <div className='training-highlight-section4'>

        <div className='training-highlight-section-left-new'>

          <p className='training-desc2'>
            <p className={`${showCourse === 0 ? 'training-desc-week-click' : 'training-desc-week'}`} onClick={() => setShowCourse(0)}>Active Directory {showCourse === 0 ? <FaAngleDown /> : <FaAngleRight />}</p>
            <p className={`${showCourse === 1 ? 'training-desc-week-click' : 'training-desc-week'}`} onClick={() => setShowCourse(1)}>Network Scanning {showCourse === 1 ? <FaAngleDown /> : <FaAngleRight />}</p>
            <p className={`${showCourse === 2 ? 'training-desc-week-click' : 'training-desc-week'}`} onClick={() => setShowCourse(2)}>Web Exploitation {showCourse === 2 ? <FaAngleDown /> : <FaAngleRight />}</p>

          </p>
        </div>
        <div className='training-highlight-section-right4'>
          {curriculum.map((x, index) => {
            if (index === showCourse) {
              return x.sectionDesc.map((sec) => (
                <div className="display-flex">
                  <div className="lab-desc-list-icon-wrapper">
                    <GrFormCheckmark className="lab-desc-list-icon" />
                  </div>
                  <p className="training-list"><span>{sec.topic}</span> {sec.desc}</p>
                </div>
              ))
            }


          })}


        </div>

      </div>
      <div onClick={() => showModal()} className="download-btn-cont-training"><a className="download-btn-training"><TfiDownload />Program Syllabus</a></div>
    </div>
  )
}

export default TrainingCurriculum
