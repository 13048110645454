import React from 'react';
import "./MarqueueComponent.css"

const MarqueueComponent = () => {
  return (
    <div className='marqueue-wrapper'>
        <div className='marqueue-text fadeout-horizontal'>
            <div className='marqueue-text-track'>
            <p className='marqueue-text-item-1'>OSINT</p>
            <p  className='marqueue-text-item-2'>Bypassing Windows Defender</p>
            <p  className='marqueue-text-item-3'>UAC Bypass</p>
            <p  className='marqueue-text-item-4'>AV Evasion</p>
            <p  className='marqueue-text-item-1'>BYPASSING 2FA</p>
            <p  className='marqueue-text-item-2'>Network Scanning</p>
            <p  className='marqueue-text-item-3'>Phishing Campaigns</p>
            <p  className='marqueue-text-item-4'> Active Directory Attacks</p>
            <p  className='marqueue-text-item-1'>Port Scanning</p>
            <p  className='marqueue-text-item-2'>Kerberoasting</p>
            <p  className='marqueue-text-item-3'>ACL Exploits</p>
            <p  className='marqueue-text-item-4'>RDP Hijacking</p>
            <p  className='marqueue-text-item-1'>CSRF</p>
            <p  className='marqueue-text-item-2'>XSS</p>
            <p  className='marqueue-text-item-3'>SSRF</p>
            <p  className='marqueue-text-item-4'>XXE</p>
            <p  className='marqueue-text-item-1'>IDOR</p>



            
            <p className='marqueue-text-item-4'>OSINT</p>
            <p  className='marqueue-text-item-2'>Bypassing Windows Defender</p>
            <p  className='marqueue-text-item-3'>UAC Bypass</p>
            <p  className='marqueue-text-item-4'>AV Evasion</p>
            <p  className='marqueue-text-item-1'>BYPASSING 2FA</p>
            <p  className='marqueue-text-item-2'>Network Scanning</p>
            <p  className='marqueue-text-item-3'>Phishing Campaigns</p>
            <p  className='marqueue-text-item-4'> Active Directory Attacks</p>
            <p  className='marqueue-text-item-1'>Port Scanning</p>
            <p  className='marqueue-text-item-2'>Kerberoasting</p>
            <p  className='marqueue-text-item-3'>ACL Exploits</p>
            <p  className='marqueue-text-item-4'>RDP Hijacking</p>
            <p  className='marqueue-text-item-1'>CSRF</p>
            <p  className='marqueue-text-item-2'>XSS</p>
            <p  className='marqueue-text-item-3'>SSRF</p>
            <p  className='marqueue-text-item-4'>XXE</p>
            <p  className='marqueue-text-item-1'>IDOR</p>
            

        
    
            </div>
      
          
            
          
            
            </div>
         

    </div>
  )
}

export default MarqueueComponent