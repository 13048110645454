import React, { useEffect, useState,useRef } from "react";
import Footer from "../Footer/Footer";
import { GrFormCheckmark } from "react-icons/gr";

import "./BinaryExploit.css";
import { Helmet } from "react-helmet";
import BinaryLogo from "../../Assets/binary/sxplogo.png";
import LabImg from "../../Assets/binary/labimage.png";

import { FaBookOpen } from "react-icons/fa";

import BinaryCourseModal from "./BinaryCourseModal";

import BinaryDifferenceSection from "./BinaryDifferenceSection";

import Certificate from "../../Assets/binary/certificate.png"
import BinaryCurriculum from "./BinaryCurriculum/BinaryCurriculum";
import StackXploitFAQs from "./BinaryCurriculum/StackXploitFAQs";
import { BsClockFill } from "react-icons/bs";
import { RiComputerFill } from "react-icons/ri";
import { PiCertificateFill } from "react-icons/pi";
import { ScrollRestoration } from "react-router-dom";


import { Link } from "react-router-dom";
import ReactPlayer from 'react-player';
const faq = [
  {
    sectionName: "What Will I Learn in this Stack Exploitation Course?",
    sectionDesc:
      "In this Stack Exploitation course, you will learn Assembly Language, reverse engineering, Buffer overflow, shellcoding, format strings, return oriented programming, Sigreturn oriented programming, Ret2csu, PIE bypass, bypassing NX, and more.",
  },
  {
    sectionName: "For whom is this Stack Exploitation Course designed?",
    sectionDesc:
      "Our Stack Exploitation course is designed for students, working professionals, and anyone interested in advancing their knowledge and skills in cybersecurity and exploit development.",
  },
  {
    sectionName: "How long does it take to complete the StackXploit Cyber Security Online Course?",
    sectionDesc:
      "Our StackXploit Cyber Security Online Course  is offered with a minimum duration of 2 months. However, participants have the option to extend the program if needed, ensuring flexibility in achieving learning objectives.",
  },
  {
    sectionName: "Will solutions to the practice challenges be provided in the StackXploit Cyber Security course?",
    sectionDesc:
      "In Our StackXploit course Solutions will only be provided for course challenges, not for practice challenges.",
  },
  {
    sectionName: "Will I Receive a Certificate Upon Completion of this Stack Exploitaion Course?",
    sectionDesc:
      "Yes, upon finishing our Stack Exploitation Course, you'll receive a certificate indicating your completion. However, this certificate is not the professional Stack Exploitaion certification. The professional certification can be earned after successfully passing the 48-hour certification exam.",
  },
  {
    sectionName: "Is there an exam associated with the StackXploit Cyber Security course?",
    sectionDesc:
      " Yes, there is a 48-hour exam that you need to appear within one month after your subscription to this cyber security course ends. The exam tests your knowledge and practical skills in Stack Exploitation. To pass the exam, you must score at least 70% marks.",
  },
  {
    sectionName: "Do I need prior knowledge for the StackXploit (Stack Exploitation Course)?",
    sectionDesc:
      "Yes, having prior knowledge is beneficial for the StackXploit course. It is recommended that participants possess an understanding of the C Programming Language, knowledge of Python Scripting, a basic understanding of Assembly Language, and a working knowledge of the Linux Operating System. These foundational skills will help learners grasp the concepts and techniques covered in the course more effectively, enabling them to engage with the material and practical exercises with greater confidence.",
  },
  {
    sectionName: "How does Hackersprey StackExploit course differentiate itself from other cybersecurity courses?",
    sectionDesc:
      "StackExploit stands out by focusing specifically on buffer overflow, binary exploitation, and stack buffer overflow, providing in-depth knowledge and practical skills in these critical areas of cybersecurity.",
  },
  {
    sectionName: "How does the StackXploit Cyber Security Online Certification contribute to career advancement?",
    sectionDesc:
      "This StackXploit certification boosts career prospects by validating proficiency in key cybersecurity areas like buffer overflow and binary exploitation, making individuals more attractive to employers and opening doors to advanced job opportunities and higher salaries in the cybersecurity field.",
  },
  
];
const curriculum = [
  {
    sectionName: "Assembly Language",
    sectionDesc:
      "Gain a solid foundation in Assembly Language, covering its fundamentals, instruction formats, and basic instructions.Understand various addressing modes crucial for effective programming.",
  },
  {
    sectionName: "Fundamentals of Program Execution Flow",
    sectionDesc:
      "Delve into the intricacies of calling conventions for optimal program execution. Grasp the memory layout of C programs, critical for understanding and exploiting vulnerabilities. Gain insights into the call stack and stack frame to enhance program execution flow comprehension.",
  },
  {
    sectionName: "Reversing the Code",
    sectionDesc:
      "Become proficient in using IDA for reverse engineering tasks.Apply knowledge in practical scenarios, including reversing to system shell, analyzing and exploiting RAND function, and cracking seeds.",
  },
  {
    sectionName: "Insights into Integer Overflow Vulnerabilities",
    sectionDesc:
      "Explore the intricacies of integer overflow vulnerabilities. Engage in hands-on exercises to witness integer overflow vulnerabilities in action.",
  },
  {
    sectionName: "Arbitrary Memory Exploitation",
    sectionDesc:
      "Master the art of exploiting arbitrary memory with practical examples.",
  },
  {
    sectionName: "Buffer Overflow",
    sectionDesc:
      "Dive deep into the mechanics of buffer overflow, exploring the vulnerabilities and understanding how to control the RIP register for effective exploitation.",
  },
  {
    sectionName: "Bypassing Stack Canary Defenses",
    sectionDesc:
      "Gain insights into the purpose and implementation of stack canaries. Learn effective strategies to bypass stack canary defenses.",
  },
  {
    sectionName: "Shellcoding",
    sectionDesc:
      "Understand the intricacies of system calls and their role in shellcoding. Develop proficiency in creating custom shell code for binary exploitation. Explore real-world scenarios of exploiting binaries using shellcode. Learn techniques to bypass Seccomp for successful shellcode execution.Understand and adapt to buffer size dynamics in shellcode execution.",
  },
  {
    sectionName: "Format Strings",
    sectionDesc:
      "Exploit vulnerabilities like arbitrary read, arbitrary write, and overwriting GOT using format string techniques. Explore advanced scenarios of exploiting format string vulnerabilities, including FINI_ARRAY exploitation.",
  },
  {
    sectionName: "Return Oriented Programming",
    sectionDesc:
      "Grasp the concept and principles of Return-Oriented Programming. Learn techniques to bypass Position-Independent Executable (PIE) and leverage Ret2libc in ROP exploits. Explore the concept of stack pivoting and its applications in exploitation.",
  },
  {
    sectionName: "RET2CSU",
    sectionDesc:
      "Understand and master the techniques involved in Return-to-Csu (RET2CSU).",
  },
  {
    sectionName: "SIGRETURN Oriented Programming",
    sectionDesc:
      "Learn the basics and fundamental concepts of SIGRETURN-oriented programming.",
  },
];
const lab_desc = [
  "Understanding of C Programming Language",
  "Knowledge of Python Scripting",
  "Basic Understanding of Assembly Language",
  "Working knowledge of Linux Operating System",
 
  
];
const certificatepoints=[
  "Global Recognition",
  "Professional Acknowledgment",
  "Career Catalyst",
  "Industry Validation",
  "Skill Verification"

]
const choose_section = [
  {
    title: "Self Paced",
    description:
      "Engage in self-paced learning with the StackXploit Cyber Security course, Immerse yourself in hands-on learning with our practical lab sessions. Unlike traditional courses, our Stack Exploitation Course prioritizes real-world application.",
  },
  {
    title: "Comprehensive Curriculum",
    description:
      "Experience the seamless integration of theoretical knowledge and practical application. Our labs serve as the bridge between classroom concepts and real-life scenarios, enhancing your understanding of buffer overflow, stack buffer overflow, and binary exploitation vulnerabilities.",
  },
  {
    title: "Hands-On Practice",
    description:
      "Engage in hands-on labs after each section for practical application of theoretical knowledge in our StackXploit Cyber Security Course.",
  },
  {
    title: "Certification",
    description:
      "Access a variety of interactive learning materials tailored for the lab sessions in our StackXploit Cyber Security Course. From simulations to case studies, our course materials are designed to enhance your understanding.",
  },
];
const price_features = [
  "Two Months Access",
  "Instructional Videos",
  "45 Challenges",
  "Reading Resources",
  "Dedicated Access to Attacker & Victim Machine",
  "One Certification Attempt ",
 
];

const BinaryExploitation = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [openCourse, setOpenCourse] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  return (
    <div>
      {openCourse ? (
        <BinaryCourseModal closeModal={() => setOpenCourse(false)} />
      ) : null}
      <Helmet>
        <title>StackXploit: Exploiting Stacks in Linux</title>
        <link
          rel="canonical"
          href="https://www.hackersprey.com/stack-xploit-cyber-security-course"
        />
       <meta name="keywords" content="buffer overflow, binary exploitation, stack buffer overflow, Stack Exploitation Course, StackXploit Cyber Security course, buffer overflow vulnerability, binary exploitation course"></meta> 
        <meta
          name="description"
          content="Master binary exploitation with Hackerprey StackXploit. Navigate Linux stacks, exploit buffer overflows, and gain hands-on experience with CTF challenges" />
      <meta property="og:title" content=" StackXploit: Exploiting Stacks in Linux " />   
<meta property="og:description" content=" buffer overflow, binary exploitation, stack buffer overflow, Stack Exploitation Course, StackXploit Cyber Security course, buffer overflow vulnerability, binary exploitation course"/>   
<meta property="og:image" content={BinaryLogo} />   
<meta property="og:url" content="https://www.hackersprey.com/stack-xploit-cyber-security-course"/>  
  
<meta name="instagram:title" content=" StackXploit: Exploiting Stacks in Linux "/> 
<meta name="instagram:description" content="buffer overflow, binary exploitation, stack buffer overflow, Stack Exploitation Course, StackXploit Cyber Security course, buffer overflow vulnerability, binary exploitation course."/>  
<meta name="instagram:image:alt" content=" StackXploit: Exploiting Stacks in Linux " />  
<meta name="instagram:card" content="summary" />  
<meta name="instagram:site" content="@d.hackersprey" />
 

<meta property="og:type" content="website"/>  
<meta property="og:title" content=" StackXploit: Exploiting Stacks in Linux " />  
<meta property="og:description" content=" buffer overflow, binary exploitation, stack buffer overflow, Stack Exploitation Course, StackXploit Cyber Security course, buffer overflow vulnerability, binary exploitation course " />  
<meta property="og:url" content="https://www.hackersprey.com/stack-xploit-cyber-security-course" />  
<meta property="og:image" content={BinaryLogo} />  

<meta name="twitter:card" content="summary"/>
<meta name="twitter:title" content="StackXploit: Exploiting Stacks in Linux | Hackerprey"/>
<meta name="twitter:description" content="Buffer overflow, binary exploitation, stack buffer overflow, Stack Exploitation Course, StackXploit Cyber Security course, buffer overflow vulnerability, binary exploitation course."/>
<meta name="twitter:image" content={BinaryLogo}/>
 

       
      </Helmet>
      <div className="binary-container">
      <section className="hero-section mb-5">
      <div className="hero-desc mt-5">
        <h1 className="hero-header mt-5">
          <span className=" heading-hero">S</span>tack
          <span className=" heading-hero">X</span>ploit
        </h1>
        <p className="hero-para pb-6">
          StackXploit Program is an immersive self-paced learning experience
          tailored for mastering linux exploitation techniques, including
          stack-based methodologies. Dive deep into the intricacies of
          exploiting linux binaries with a focus on buffer overflow and
          binary exploitation. Learn to generate your own payloads and gain
          hands-on experience in bypassing diverse security mechanisms
          commonly found in linux binaries through real-world practice
          challenges. Embark on this journey to acquire unparalleled
          expertise in linux exploitation.
        </p>
       
      </div>

      <div className="img-container mt-5">
      
        <img src={BinaryLogo} className="binary-logo " />
       
      </div>
    </section>
    <section className="video-section">
    <p className="why-stack-section-header2">
    Start Your <span >Stack Exploitation </span>Journey Today
          </p>
          <div className="red-line-class">
            <hr className="binaryhr"></hr>
          </div>
    
    <div className="video-responsive-cont">
      <div className="video-resp">
    <iframe width="560" height="315" src="https://www.youtube.com/embed/HJi7M5DuoqI?si=rrNFuS_S9NN_Jhzk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
    </div>
    </div>

    
    </section>
         
        <section className="why-stack-section">
          <p className="why-stack-section-header">
            Why To Choose <span >StackXploit</span>
          </p>
          <div className="red-line-class">
            <hr className="binaryhr"></hr>
          </div>
        
          <div className="why-stack-section-card-wrapper">
            {choose_section.map((x,index) => (
              <div className="why-stack-section-card">
                <div className="why-stack-section-icon-wrapper">
               {index===0 ? <BsClockFill className="why-stack-section-icon" />: index===1 ? <FaBookOpen className="why-stack-section-icon" />: index===2 ? <RiComputerFill className="why-stack-section-icon" />:index===3? <PiCertificateFill className="why-stack-section-icon" />:null}  
                </div>
                <p className="why-stack-section-card-header">{x.title}</p>
                <p className="why-stack-section-desc">{x.description}</p>
              </div>
            ))}
          </div>
        </section>

      {/*}  <section className="course-overview">
          <p className="course-overview-head">Course Overview</p>
          <FaCircleChevronRight />
          <div className="course-overview-desc">
            {course_section.map((x, index) => (
              <div
                onMouseEnter={() => setCardIndex(index)}
                onClick={() => setOpenCourse(true)}
                onMouseLeave={() => setCardIndex("")}
                className="course-first-card"
              >
                <div className="assembly-background">
                  <p className="assembly" style={{ fontFamily: CustomFont }}>
                    {x.title}
                  </p>
                </div>
                {cardIndex === index ? (
                  <img src={NewTest2} className="course-card-logo" />
                ) : (
                  <img src={NewTest} className="course-card-logo" />
                )}
              </div>
            ))}
          </div>
          <div></div>
                </section> */}
        <BinaryCurriculum course="binary" curriculum={curriculum}/>

       
        <p className="lab-card-left-header">
        Get Certified with Advanced <span className="color-red">Binary Exploitation</span> Skills
        </p>
        <div className="red-line-class">
            <hr className="binaryhr"></hr>
          </div>
        <section className="lab-section">
          <div className="lab-card">
            <div className="lab-card-left">
              <p className="lab-card-left-desc">
              Elevate your expertise in cyber security through our comprehensive StackXploit Cyber Security course. All participants will receive a Certificate of Participation, and those achieving a score of 60% or higher in the exam will be awarded a Certificate of Achievement.
              </p>
              <div className="lab-list-container">
                {certificatepoints.map((x) => (
                  <div className="lab-desc-list">
                    <div className="lab-desc-list-icon-wrapper">
                      <GrFormCheckmark className="lab-desc-list-icon" />
                    </div>
                    <p className="lab-list-text">{x}</p>
                  </div>
                ))}
              </div>
            </div>
            <div className="lab-card-right">
              <img src={Certificate} className="certificate-img"/>
            </div>
          </div>
        </section>
        <BinaryDifferenceSection />
        <p className="pricing-header">
        Program Fee
        </p>
        <div className="red-line-class">
            <hr className="binaryhr"></hr>
          </div>

        <section className="pricing-section mb-5">
          <div className="pricing-section-card mb-5">
            <div className="pricing-section-card-left">
              <p className="pricing-section-card-head">What's Included</p>
             
             
              <div className="price-feature-list">
                {price_features.map((x) => (
                  <p className="price-list-item">
                    {" "}
                    <GrFormCheckmark className="price-list-icon" /> {x}
                  </p>
                ))}
              </div>
            </div>
            <div className="pricing-section-card-right">
        
              <p className="pricing-inside-card-text">
                
              </p>
              <p className="pricing-card-price">₹24,850</p>
              <p className="pricing-card-price2">$300</p>
              <div className="btn-wrapper">
                {" "}
               <Link className="card-price-link" to="/checkout/65d2dec668dee08cb3c038e8"> <p className="card-price-button">Buy Now</p></Link>
              </div>
            </div>
          </div>
        </section>
        <p className="lab-card-left-header ">
       Pre-Requisites
        </p>
        <div className="red-line-class">
            <hr className="binaryhr"></hr>
          </div>
        <section className="lab-section">
          <div className="lab-card">
            <div className="lab-card-left">
              <p className="lab-card-left-desc">
              To maximize your learning potential and ensure a smooth progression through the Binary Exploitation Course, it's essential that participants meet the following prerequisites:
              </p>
              <div className="lab-list-container">
                {lab_desc.map((x) => (
                  <div className="lab-desc-list">
                    <div className="lab-desc-list-icon-wrapper">
                      <GrFormCheckmark className="lab-desc-list-icon" />
                    </div>
                    <p className="lab-list-text">{x}</p>
                  </div>
                ))}
              </div>
            </div>
            <div className="lab-card-right">
              <img src={LabImg} className="certificate-img"/>
            </div>
           
          </div>
        </section>
        <StackXploitFAQs faq={faq} heading="StackXploit FAQ's"/>
      </div>
    
      <Footer />
    </div>
  );
};

export default BinaryExploitation;
