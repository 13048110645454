import React,{useEffect, useState,useRef} from 'react'
import "./Topbar.css";
import Sidebarbtn from "../../../Assets/Landing page/Group 154.svg";
import Home from "../../../Assets/Dashboard/Home.svg";
import Notification from "../../../Assets/Dashboard/Notifications.svg";
import Search from "../../../Assets/Dashboard/Search.svg";
import { AiOutlineDown,AiOutlineHome } from "react-icons/ai";
import User from "../../../Assets/images/dp3.png"
import { NavLink,useLocation, useNavigate } from "react-router-dom";
import { RiArrowDropRightLine, RiSettings5Fill } from "react-icons/ri";
import { IoLogOutOutline } from "react-icons/io5";
import Tooltip from "@material-ui/core/Tooltip";
import { CgProfile } from "react-icons/cg";
import {FaSearch} from "react-icons/fa"; 
import flag from "../../../Assets/Dashboard/bugbounty.png";
import { event } from 'jquery';
import Homeicon from "../../../Assets/Dashboard/Home.svg"
import Profileicon from "../../../Assets/Dashboard/Users_1.svg"
import Courseicon from "../../../Assets/Dashboard/Courses 2.svg"
import Settingicon from "../../../Assets/Dashboard/Settings.svg";
import Challengeicon from "../../../Assets/Dashboard/Challenges.svg"
import Labicon from "../../../Assets/Dashboard/Labs.svg";
import supporticon from "../../../Assets/Dashboard/Support-Queries.svg";
import Videoicon from "../../../Assets/Dashboard/Videos.svg";
import Flag from "../../../Assets/prodile/VIP.svg";
import Edit from "../../../Assets/Entity/Lab/Edit.svg";
import bookslot from "../../../Assets/Dashboard/bookslot.png";
import axios from 'axios';
import { Nav } from 'react-bootstrap';
import Uni from "../../../Assets/Dashboard/uni.png"
import { makeStyles } from '@material-ui/core/styles';
import {AiFillWarning} from "react-icons/ai";
import { useDispatch, useSelector } from 'react-redux';
import { removeUserDetails } from '../../../redux/ActionCreators';


const useStyles = makeStyles({
  tooltip: {
    background: 'rgba(249, 245, 245, 0.09)',
    color:"white",
    padding:"1rem",
    fontSize:"1.2rem",
    fontFamily: "Saira, sans-serif"
  },
});
const Topbar = (props) => {
  const[uni,setuni]=useState("");
  const navigate=useNavigate()
  const user=useSelector(state=>state.user)
  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowDrop(false);
          setShowDrop2(false)
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  const location = useLocation();
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);
  //destructuring pathname from location
  const { pathname } = location;
  const path=pathname;
  const splitLocation = pathname.split("/"); 
  const classes = useStyles();
const[notistyle,setnotistyle]=useState("header__serach");
 const[styles,setstyles]=useState("sidebaricons")
  const[username,setusername]=useState("");
  const[userdata,setuserdata]=useState([]);
 
  const [showDrop, setShowDrop] = useState(false);
  const [showDrop2, setShowDrop2] = useState(false);
  const[showsidebar,setsidebar]=useState(false);
  const[showsidebar2,setsidebar2]=useState(false);
  const[warning,setwarning]=useState(false);
  const[unishow,setunishow]=useState(false);
  const [logoutshow,setlogoutshow]=useState(false);
  const dispatch=useDispatch()

  const fetchuni = async () => {


    
    try{
      const res = await fetch(`${API_Key}/getuniversityuser`,{
          method:"GET",
          headers:{
            mode: 'cors',
            'Access-Control-Allow-Origin':`${API_Key}`,
            'Content-Type': 'application/json',
            'Access-Control-Allow-Credentials': true,
            
          },
          credentials:"include",
          //withCredentials: true,
      }).then(async(response)=>{
        const data = await response.json();

 if(response.status===200){
setunishow(true);
setlogoutshow(true);
 }
      }).catch((err)=>{
  console.log(err);
      })
     
  
    
    }catch(err){
    console.log(err);
    
    
    } 
    
    }
    useEffect(() => {
    fetchuni();
    },[] );
 
  let barName;
  let image;
  if (path.includes("dashcourses")) {
    barName = "Courses";
    image=Courseicon;
  } else if (path.includes("coursedesc")) {
    barName = "Courses";
    image=Courseicon;
  } else if (path.includes("dashmastercourses")) {
    barName = "Courses";
    image=Courseicon;
  } else if (path.includes("chapterdesc")) {
    barName = "Courses";
    image=Courseicon;
  } else if (path.includes("challenges") ) {
    barName = "Challenges";
    image=Challengeicon;
   
  } else if (
    
    (path.includes("challengebycat") )
  ) {
    barName = "Challenges";
    image=Challengeicon;
  } else if (path.includes("challengedesc")) {
    barName = "Challenges";
    image=Challengeicon;
    } else if (path.includes("setting")) {
    barName = "Settings";
    image=Settingicon;
    } else if (path.includes("raisequery")) {
    barName = "Support";
    image=supporticon;
  } else if (path.includes("Labs")) {
    barName = "Labs";
    image=Labicon
  } else if (path.includes("Labsdesc") || path.includes("Labsdesc")) {
    barName = "Labs";
    image=Labicon
  }  else if (path.includes("userhome")) {
    barName = "Dashboard";
    image=Homeicon;
  }
  else if (path.includes("Profile")) {
    barName = "Profile";
    image=Profileicon;
  } else if (path.includes("changepassword")) {
    barName = "Profile";
    image=Profileicon;
  } else if (path.includes("editprofile")) {
    barName = "Profile";
    image=Profileicon;
  } 
  else if(path.includes("modaltest")){
    barName="Popup";
    image=supporticon
  }else if(path.includes("masterplan") || path.includes("subscription")){
    barName="Subscription";
    image=supporticon
  }
  else if(path.includes("extend") || path.includes("extend")){
    barName="Subscription";
    image=supporticon
  }
  else if(path.includes("hackmasterseries") || path.includes("hackmasterseries/overview")){
    barName="CTF";
    image=flag
  }
  else if(path.includes("bookslot") || path.includes("bookslot")){
    barName="bookslot";
    image=supporticon
  }
  else if(path.includes("notification")){
    barName="Notification";
    image=Notification
  }
  else if(path.includes("unidetails")){
    barName="University";
    image=Uni
  }
   else {
    barName = "";
  }

  const handleDrop = (event) => {
    setShowDrop(!showDrop);
    setShowDrop2(false)
  
  };
 
  
  const handleDrop2 = (event) => {
    setnotistyle("header__serach2")
    setShowDrop2(!showDrop2);
    setShowDrop(false)
 
  };
  const Showsidebar = (event)=>{
    setsidebar(!showsidebar);
  }
  
  const closesidebar =(event)=>{

   setsidebar2(!showsidebar2);
  


  
  }
  
  const API_Key = process.env.REACT_APP_API_URL;
  const usersnames = async()=>{
    try{
      const res = await fetch(`${API_Key}/UserTopbar`,{
          method:"GET",
          headers:{
            mode: 'cors',
            'Access-Control-Allow-Origin':`${API_Key}`,
            'Content-Type': 'application/json',
            'Access-Control-Allow-Credentials': true,
          },
          credentials:"include"
         
      });
      const data = await res.json([]);
   
      setusername(data);
      setuserdata(data);
    
  
      if(res.status !== 200 ){
          const error = new Error(res.error);
          throw error;
          
      }
      
   
  
  
  }catch(err){
  
    //navigate("/signin");
  
  } 
  }
  useEffect(()=>{
   usersnames();
      },[])
      const userswarning = async()=>{
        try{
          const res = await fetch(`${API_Key}/checkuser`,{
              method:"GET",
              headers:{
                mode: 'cors',
                'Access-Control-Allow-Origin':`${API_Key}`,
                'Content-Type': 'application/json',
                'Access-Control-Allow-Credentials': true,
              },
              credentials:"include"  
          });
          if(res.status === 200 ){
             setwarning(true);  
          }else{
            setwarning(false);
          }
      }catch(err){
        console.log(err);
        //navigate("/signin");
      
      } 
      }
      useEffect(()=>{
       userswarning();
          },[warning])
const handleLogout=(logouttype)=>{
  let logouturl="logout";
  if(logouttype==="universitylogout"){
    logouturl='universitylogout1'
  }
  fetch(`${API_Key}/${logouturl}`,{
    method:'GET',
    headers:{
        Accept: 'application/json',
        mode:"cors",
                'Access-Control-Allow-Origin':`${API_Key}`, 
                'Content-Type': 'application/json',
                'Access-Control-Allow-Credentials': true
      },
      credentials:"include",
   
}).then((res)=>{
    dispatch(removeUserDetails());
    if(logouttype==='universitylogout'){
      navigate('/unilogin')
    }
    else{
      navigate('/signin');
    }
   


if(res.status !==200){
const Error = new Error (res.Error);
throw Error;
}
}).catch((err) =>{



})




}
  



  return (
    <div> <header class="header">
  
    <div class="header__container">
      <button className="btn header__img"  onClick={Showsidebar} >  <img src={Sidebarbtn} alt="" />
      {showsidebar ?
        
        <section id="set">
        <div className='container' >
        <div className='row'>
        <div className='col-lg-6 col-2'>
        <button className="btn header__img "  >  <img src={Sidebarbtn}  alt="" /></button></div>
        <div className='col-lg-1 offset-3 col-1'>
       </div>
      
        </div>
       

        <div className='row mt-5 nav__dropdown ml-1'>
     
        <NavLink    className= "sidebarnavlinks " activeClassName='navact'  to="/userhome"   > <div className='col-lg-3 col'><div className={styles}><img src={Homeicon} style={{display:"inline",height:"1.3rem",marginTop:"1rem"}} alt="home"/></div></div></NavLink>
        <div className='col-lg-4 col  '> <NavLink className="sidebarnavlinks" to="/userhome"><h2 className='sidebarnames'>Dashboard</h2> </NavLink></div>
        <div className='col-lg-3  col ml-5'></div>
        </div>
        <div className='row mt-5 ml-1'>
        <NavLink  className="sidebarnavlinks" to="/Profile" activeClassName='navact' > <div className='col-lg-3 col'><div className='sidebaricons'><img src={Profileicon} style={{display:"inline",height:"1.3rem",marginTop:"1rem"}} alt="Profile"/></div></div></NavLink>
        <div className='col-lg-4 col  '> <NavLink  className="sidebarnavlinks" to="/Profile"><h2 className='sidebarnames'>Profile</h2> </NavLink></div>
        <div className='col-lg-3  col ml-5'></div>
        </div>
      
        <div className='row mt-5 ml-1 nav__dropdown'>
       
        <NavLink className="sidebarnavlinks" activeClassName='navact' to="/dashcourses"><div className='col-lg-3 col nav__items'><div className='sidebaricons'><img src={Courseicon} style={{display:"inline",height:"1.3rem",marginTop:"1rem"}} alt="Profile"/></div></div></NavLink>
        <div className='col-lg-4 col nav__items '> <NavLink  className="sidebarnavlinks" to="/dashcourses"><h2 className='sidebarnames'>Courses</h2> </NavLink></div>
    <div className='col-lg-3  col ml-4' style={{float:"right"}}></div>
   

 
 
</div>
<div className='row mt-5 ml-1 nav__dropdown'>
       
<NavLink className="sidebarnavlinks" activeClassName='navact' to="/hackmasterseries"><div className='col-lg-3 col nav__items'><div className='sidebaricons'><img src={flag} style={{display:"inline",height:"1.3rem",marginTop:"1rem"}} alt="Profile"/></div></div></NavLink>
<div className='col-lg-6 col nav__items '> <NavLink  className="sidebarnavlinks" to="/hackmasterseries"><h2 className='sidebarnames'>CTF</h2> </NavLink></div>
<div className='col-lg-3  col ml-4' style={{float:"right"}}></div>




</div>
 {user && user.userinfo && user.userinfo.universityname ? <div className='row  ml-1 mt-5 nav__dropdown'>
     
<NavLink className="sidebarnavlinks" to="/unidetails"> <div className='col-lg-3 col'><div className='sidebaricons'><img src={Uni} style={{display:"inline",height:"1.1rem",marginTop:"1rem"}} alt="home"/></div></div></NavLink>
<NavLink className="sidebarnavlinks" to="/unidetails"> <div className='col-lg-4 col  '><h2 className='sidebarnames'>University</h2> </div></NavLink>
        <div className='col-lg-3  col ml-5'></div>
      </div> :null}
   <div className='row ml-1 mt-5 nav__dropdown'>
     
        <NavLink  className="sidebarnavlinks" to="/masterplan" activeClassName='navact'> <div className='col-lg-3 col'><div className='sidebaricons'><img src={Challengeicon} style={{display:"inline",height:"1.1rem",marginTop:"1rem"}} alt="challenges"/></div></div></NavLink>
        <NavLink className="sidebarnavlinks" to="/masterplan"> <div className='col-lg-4 col  '> <h2 className='sidebarnames'>Subscription</h2> </div></NavLink>
        <div className='col-lg-3  col ml-5'></div>
      </div>
        <div className='row ml-1 mt-5 nav__dropdown'>
     
        <NavLink  className="sidebarnavlinks" activeClassName='navact' to="/Labs">  <div className='col-lg-3 col'><div className='sidebaricons'><img src={Labicon} style={{display:"inline",height:"1.3rem",marginTop:"1rem"}} alt="home"/></div></div></NavLink>
        <NavLink className="sidebarnavlinks" to="/Labs"> <div className='col-lg-4 col  '> <h2 className='sidebarnames'>Labs</h2> </div></NavLink>
        <div className='col-lg-3  col ml-5'></div>
        </div>
        { /* <div className='row ml-1 mt-5 nav__dropdown'>
      
         <NavLink  className="sidebarnavlinks" to="/bookslot">  <div className='col-lg-3 col'><div className='sidebaricons'><img src={bookslot} style={{display:"inline",height:"1.1rem",marginTop:"1rem"}} alt="home"/></div></div></NavLink>
     <NavLink className="sidebarnavlinks" to="/bookslot"> <div className='col-lg-4 col  '> <h2 className='sidebarnames'>Exam</h2> </div></NavLink>
         <div className='col-lg-3  col ml-5'></div>
      </div> 
       */}
        <div className='row ml-1 mt-5 nav__dropdown'>
     
        <NavLink className="sidebarnavlinks" activeClassName='navact' to="/raisequery"> <div className='col-lg-3 col'><div className='sidebaricons'><img src={supporticon} style={{display:"inline",height:"1.3rem",marginTop:"1rem"}} alt="support"/></div></div></NavLink>
        <NavLink  className="sidebarnavlinks" to="/raisequery">  <div className='col-lg-4 col  '><h2 className='sidebarnames'>Support</h2> </div></NavLink>
        <div className='col-lg-3  col ml-5'></div>
        </div>
        <div className='row ml-1 mt-5 nav__dropdown'>
     
        <NavLink  className="sidebarnavlinks"  activeClassName='navact' to="/setting">   <div className='col-lg-3 col'><div className='sidebaricons'><img src={Settingicon} style={{display:"inline",height:"1.3rem",marginTop:"1rem"}} alt="settings"/></div></div></NavLink>
        <NavLink className="sidebarnavlinks" to="/setting"> <div className='col-lg-4 col  '> <h2 className='sidebarnames'>Settings</h2> </div></NavLink>
         <div className='col-lg-3  col ml-5'></div>
         </div>
       {unishow ?null:<div className='row mt-5 justify-content-center'>
       <h2 className='hackersversionhead text-center'>HackersPrey V1.2.4<span className='text-center '><hr className='hackerversionhr'></hr></span></h2>
        </div> }  
        
        </div>
        </section>
        :null}
        {showsidebar2 ?
         <div className='container-fluid' style={{display:"flex"}}>
         <div className='row'>
         <div className='col-lg-2 col-2 mr-5'>
         <section id="set2">
         <div className='container' >
         <div className='row'>
         <div className='col-lg-6 col-2'>
         <button className="btn header__img "  >  <img src={Sidebarbtn}  alt="" /></button></div>
         <div className='col-lg-1 offset-3 col-1'>
         <div className='topbarsearchbtn'  onClick={closesidebar}><FaSearch color="white" size={11} style={{marginTop:".9rem"}}/></div></div>
       
         </div>
        
 
         <div className='row mt-5 nav__dropdown ml-1'>
      
         <NavLink  className="sidebarnavlinks" to="/userhome"> <div className='col-lg-3 col'><div className='sidebaricons'><img src={Homeicon} style={{display:"inline",height:"1.3rem",marginTop:"1rem"}} alt="home"/></div></div></NavLink>
         <div className='col-lg-4 col  '> <NavLink className="sidebarnavlinks" to="/Labs"><h2 className='sidebarnames'>Dashboard</h2> </NavLink></div>
         <div className='col-lg-3  col ml-5'></div>
         </div>
         <div className='row mt-5 ml-1'>
         <NavLink  className="sidebarnavlinks" to="/Profile"> <div className='col-lg-3 col'><div className='sidebaricons'><img src={Profileicon} style={{display:"inline",height:"1.3rem",marginTop:"1rem"}} alt="Profile"/></div></div></NavLink>
         <div className='col-lg-4 col  '> <NavLink  className="sidebarnavlinks" to="/Profile"><h2 className='sidebarnames'>Profile</h2> </NavLink></div>
         <div className='col-lg-3  col ml-5'></div>
         </div>
       
         <div className='row mt-5 ml-1 nav__dropdown'>
        
         <NavLink className="sidebarnavlinks" to="/dashcourses" ><div className='col-lg-3 col nav__items'><div className='sidebaricons'><img src={Courseicon} style={{display:"inline",height:"1.3rem",marginTop:"1rem"}} alt="Profile"/></div></div></NavLink>
         <div className='col-lg-4 col nav__items '> <NavLink  className="sidebarnavlinks" to="/dashcourses"><h2 className='sidebarnames'>Courses</h2> </NavLink></div>
     <div className='col-lg-3  col ml-4' style={{float:"right"}}><div className='sidebariconsarrow'><RiArrowDropRightLine color='white' style={{marginTop:".1rem"}} size={14}/></div></div>
    
 
  
  
 </div>
 <div className='row  ml-1 mt-5 nav__dropdown'>
      
 <NavLink className="sidebarnavlinks" to="/Labs" > <div className='col-lg-3 col'><div className='sidebaricons'><img src={Videoicon} style={{display:"inline",height:"1.3rem",marginTop:"1rem"}} alt="home"/></div></div></NavLink>
 <NavLink className="sidebarnavlinks" to="/Labs"> <div className='col-lg-4 col  '><h2 className='sidebarnames'>Videos</h2> </div></NavLink>
         <div className='col-lg-3  col ml-5'></div>
         </div>
        {/*} <div className='row ml-1 mt-5 nav__dropdown'>
      
         <NavLink  className="sidebarnavlinks" to="/challenges"> <div className='col-lg-3 col'><div className='sidebaricons'><img src={Challengeicon} style={{display:"inline",height:"1.1rem",marginTop:"1rem"}} alt="challenges"/></div></div></NavLink>
         <NavLink className="sidebarnavlinks" to="/challenges"> <div className='col-lg-4 col  '> <h2 className='sidebarnames'>Challenges</h2> </div></NavLink>
         <div className='col-lg-3  col ml-5'></div>
        </div>*/}
         <div className='row ml-1 mt-5 nav__dropdown'>
      
         <NavLink  className="sidebarnavlinks" to="/Labs">  <div className='col-lg-3 col'><div className='sidebaricons'><img src={Labicon} style={{display:"inline",height:"1.1rem",marginTop:"1rem"}} alt="home"/></div></div></NavLink>
         <NavLink className="sidebarnavlinks" to="/Labs"> <div className='col-lg-4 col  '> <h2 className='sidebarnames'>Labs</h2> </div></NavLink>
         <div className='col-lg-3  col ml-5'></div>
         </div>
         
         <div className='row ml-1 mt-5 nav__dropdown'>
      
         <NavLink className="sidebarnavlinks" to="/raisequery"> <div className='col-lg-3 col'><div className='sidebaricons'><img src={supporticon} style={{display:"inline",height:"1.1rem",marginTop:"1rem"}} alt="support"/></div></div></NavLink>
         <NavLink  className="sidebarnavlinks" to="/raisequery">  <div className='col-lg-4 col  '><h2 className='sidebarnames'>Support</h2> </div></NavLink>
         <div className='col-lg-3  col ml-5'></div>
         </div>
         <div className='row ml-1 mt-5 nav__dropdown'>
      
         <NavLink  className="sidebarnavlinks" to="/setting">   <div className='col-lg-3 col'><div className='sidebaricons'><img src={Settingicon} style={{display:"inline",height:"1.1rem",marginTop:"1rem"}} alt="settings"/></div></div></NavLink>
         <NavLink className="sidebarnavlinks" to="/setting"> <div className='col-lg-4 col  '> <h2 className='sidebarnames'>Settings</h2> </div></NavLink>
          <div className='col-lg-3  col ml-5'></div>
          </div>
          <div className='row mt-5 justify-content-center'>
         <h2 className='hackersversionhead text-center'>HackersPrey V1.2.4<span className='text-center '><hr className='hackerversionhr'></hr></span></h2>
          </div>
         
         </div>
         </section>
         </div>
         
         <div className='col-lg-2 col offset-5 ml-3'>
         <section className='searchdiv p-5'>
      <div className='container-fluid'>
      <div className='row justify-content-center'>
      <div className='col-lg-10'>
      <input type="text" placeholder='Search ..' className='searchdivinput'/>
      </div>
      </div>
      <div className='row justify-content-center'>
      <div className='col-lg-6 col-md-6 col'><p className='searchdivhead' >Recents<span ><hr className='searchdivheadhr'></hr></span></p></div>
      <div className='col-lg-6 col-md-6 col'><p className='searchdivhead'>Notifications</p></div>
      </div>
      <div className='row mt-3 justify-content-center'>
      <div className='col-lg-11 col'>
      <div className='row'>
      <div className='col-3'><img src={Flag} alt="flag" /></div>
      <div className='col-7'><h1 className='searchresulthead'>Flag succesfull!</h1><span className='searchresulspan2'>Challenges</span></div>
      <div className='col-1'><p className='searchresulspan'>13m</p></div>
      </div>
      
      </div>
      
  
      </div>
      <div className='row mt-5 justify-content-center'>
      <div className='col-lg-11 col'>
      <div className='row'>
      <div className='col-3'><img src={Flag} alt="flag" /></div>
      <div className='col-7'><h1 className='searchresulthead'>Flag succesfull!</h1><span className='searchresulspan2'>Challenges</span></div>
      <div className='col-1'><p className='searchresulspan'>13m</p></div>
      </div>
      
      </div>
      
  
      </div>
      <div className='row mt-5 justify-content-center'>
      <div className='col-lg-11 col'>
      <div className='row'>
      <div className='col-3'><img src={Flag} alt="flag" /></div>
      <div className='col-7'><h1 className='searchresulthead'>Flag succesfull!</h1><span className='searchresulspan2'>Challenges</span></div>
      <div className='col-1'><p className='searchresulspan'>13m</p></div>
      </div>
      
      </div>
      
  
      </div>
      <div className='row mt-5 justify-content-center'>
      <div className='col-lg-11 col'>
      <div className='row'>
      <div className='col-3'><img src={Flag} alt="flag" /></div>
      <div className='col-7'><h1 className='searchresulthead'>Flag succesfull!</h1><span className='searchresulspan2'>Challenges</span></div>
      <div className='col-1'><p className='searchresulspan'>13m</p></div>
      </div>
      
      </div>
      
  
      </div>
      <div className='row mt-5 justify-content-center'>
      <div className='col-lg-11 col'>
      <div className='row'>
      <div className='col-3'><img src={Flag} alt="flag" /></div>
      <div className='col-7'><h1 className='searchresulthead'>Flag succesfull!</h1><span className='searchresulspan2'>Challenges</span></div>
      <div className='col-1'><p className='searchresulspan'>13m</p></div>
      </div>
      
      </div>
      
  
      </div>
      <div className='row mt-5 justify-content-center'>
      <div className='col-lg-11 col'>
      <div className='row'>
      <div className='col-3'><img src={Flag} alt="flag" /></div>
      <div className='col-7'><h1 className='searchresulthead'>Flag succesfull!</h1><span className='searchresulspan2'>Challenges</span></div>
      <div className='col-1'><p className='searchresulspan'>13m</p></div>
      </div>
      
      </div>
      
  
      </div>
      <div className='row mt-5 justify-content-center'>
      <div className='col-lg-11 col'>
      <div className='row'>
      <div className='col-3'><img src={Flag} alt="flag" /></div>
      <div className='col-7'><h1 className='searchresulthead'>Flag succesfull!</h1><span className='searchresulspan2'>Challenges</span></div>
      <div className='col-1'><p className='searchresulspan'>13m</p></div>
      </div>
      
      </div>
      
  
      </div>
      <div className='row mt-5 justify-content-center'>
      <div className='col-lg-11 col'>
      <div className='row'>
      <div className='col-3'><img src={Flag} alt="flag" /></div>
      <div className='col-7'><h1 className='searchresulthead'>Flag succesfull!</h1><span className='searchresulspan2'>Challenges</span></div>
      <div className='col-1'><p className='searchresulspan'>13m</p></div>
      </div>
      
      </div>
      
  
      </div>
      <div className='row mt-5 justify-content-center'>
      <div className='col-lg-11 col'>
      <div className='row'>
      <div className='col-3'><img src={Flag} alt="flag" /></div>
      <div className='col-7'><h1 className='searchresulthead'>Flag succesfull!</h1><span className='searchresulspan2'>Challenges</span></div>
      <div className='col-1'><p className='searchresulspan'>13m</p></div>
      </div>
      
      </div>
      
  
      </div>
      <div className='row mt-5 justify-content-center'>
      <div className='col-lg-11 col'>
      <div className='row'>
      <div className='col-3'><img src={Flag} alt="flag" /></div>
      <div className='col-7'><h1 className='searchresulthead'>Flag succesfull!</h1><span className='searchresulspan2'>Challenges</span></div>
      <div className='col-1'><p className='searchresulspan'>13m</p></div>
      </div>
      
      </div>
      
  
      </div>
      <div className='row mt-5 justify-content-center'>
      <div className='col-lg-11 col'>
      <div className='row'>
      <div className='col-3'><img src={Flag} alt="flag" /></div>
      <div className='col-7'><h1 className='searchresulthead'>Flag succesfull!</h1><span className='searchresulspan2'>Challenges</span></div>
      <div className='col-1'><p className='searchresulspan'>13m</p></div>
      </div>
      
      </div>
      
  
      </div>
      
      </div>
         </section>
         </div>
         </div>
         </div>
        :null}
      </button>
      
     
  <p className="barname ml-4"><span className='imgbackend'>  <img src={image}/></span>{barName}</p>
  <a href="#" className="header__logo"></a>
  <a href="#" className="header__logo"></a>
        <a href="#" className="header__logo"></a>
        <a href="#" className="header__logo"></a>
        <a href="#" className="header__logo"></a>
        <a href="#" className="header__logo"></a>
        <a href="#" className="header__logo"></a>
        <a href="#" className="header__logo"></a>
        <a href="#" className="header__logo"></a>
        <a href="#" className="header__logo"></a>
        <a href="#" className="header__logo"></a>
        <a href="#" className="header__logo"></a>
  <div ><span className={notistyle} onClick={handleDrop2} > <Tooltip title="Notification" classes={classes}><img src={Notification} className="topbarimage" style={{height:"1.3rem",cursor:"pointer"}}/></Tooltip> 

 {showDrop2 ? (

  <div className="box3 p-2" ref={wrapperRef}>
    <ul className="list-unstyled5">
    {userdata.notificationtypesubscription.length ===0 ? <h1>No New  Alerts</h1>: [...userdata.notificationtypesubscription].reverse().slice(0,3).map((noti,id)=>(
      <NavLink to="/notification" className="NavLink" style={{textDecoration:"none"}}> <li>


      <div className='row p-2'>
      <div className='col-3'> <img src={userdata.photo || User} style={{height:"4rem",borderRadius:"50%"}} alt="img"/></div>
      <div className='col-9'> <h1 className='notifichead'>{noti.querytype} <br></br><span className='notispan'>{noti.category}</span></h1>

      </div>
      </div>
     </li></NavLink>

    )) }
    
      
     
   

      </ul>
      <NavLink to="/notification" className="NavLink"  style={{textDecoration:"none"}}><div className='row '>
      <div className='col-11 ' id='notificationbtn'>
     <h1>View All</h1>
     </div>
      </div></NavLink> 
     
      </div>
      
      

      )
:null}
</span>  </div>
  

  <div ><NavLink to="/feedback" activeClassName='feedback' ><span className="header__serach m-2">  <Tooltip title="Feedback" classes={classes}><img src={Edit} className="topbarimage" style={{height:"1rem",cursor:"pointer"}}/></Tooltip></span></NavLink> </div>
  
       <div className='username'><img src={username.photo || User} style={{
        height: "4rem",
        width: "4rem",
        borderRadius:"50%"

       
      }} alt="user" /> <span
       style={{ display: "inline-block" ,cursor:"pointer"}}
       className="userimagename"
       onClick={handleDrop}
     >
       <p>{username.fname}</p>
     </span>
     <span className="topbtndiv header__img">
                      <button
                        id="topbarbtn"
                        className="btn"
                        onClick={handleDrop}
                      >
                        <AiOutlineDown color="white" size={12} />
                      </button>
                      {showDrop ? (

                    
                        <div className="box2 p-5"  ref={wrapperRef} >
                          <ul className="list-unstyled">
                          <NavLink to="/" style={{textDecoration:"none"}}>  <li>
                              <AiOutlineHome className="icon" />
                              <span>
                                <NavLink
                                  to="/"
                                  className="NavNAv offset-2"
                                >
                                 Home
                                </NavLink>
                              </span>
                            </li></NavLink>
                            <NavLink to="/Profile" style={{textDecoration:"none"}}>  <li>
                              <CgProfile className="icon" />
                              <span>
                                <NavLink
                                  to="/Profile"
                                  className="NavNAv offset-2"
                                >
                                  Profile
                                </NavLink>
                              </span>
                  <span className='ml-3'> {warning ? <Tooltip title="Incomeplte Profile" classes={classes}><AiFillWarning color='red' className='icon2' size={18}/></Tooltip>: null}</span>
                            </li></NavLink>

                            <NavLink to="/setting" style={{textDecoration:"none"}}><li>
                              <RiSettings5Fill className="icon" />
                              <span>
                                <NavLink
                                  to="/setting"
                                  className="NavNAv offset-2"
                                >
                                  Settings
                                </NavLink>
                              </span>
                            </li></NavLink>

                          {logoutshow ?<div className='logout-topbar-class'  onClick={()=>handleLogout("universitylogout")}  style={{textDecoration:"none"}}> <li>
                          <IoLogOutOutline className="icon" />Logout
                        </li></div>
                        :
                        <div className='logout-topbar-class' onClick={()=>handleLogout("logout")} style={{textDecoration:"none"}}> <li>
                          <IoLogOutOutline className="icon" />  <NavLink
                        
                          className="NavNAv offset-2"
                        >Logout</NavLink>
                          
                        </li></div>}  
                          </ul>
                        </div>
                
                      ) : null}
                      </span>
     </div>
    
        
     <div class="header__toggle">
     <i class='bx bx-menu' id="header-toggle"></i>
 </div>
        
    </div>
</header>

</div>

  )
}

export default Topbar