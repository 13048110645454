import React from "react";
import AcwLogoImg from "../../Assets/courses/acw.png";
import CurriculumImg from "../../Assets/acwassets/open-book.png";
import GraduationImg from "../../Assets/acwassets/graduation.png";
import MentorshipImg from "../../Assets/acwassets/mentorship.png";
import CommandLineImg from "../../Assets/acwassets/command-line.png";
import Dashboard from "../../Assets/Dashboard/Dashboard10.png";
import Hacker from "../../Assets/acwassets/hacker.png";
import code from "../../Assets/acwassets/code.png";
import Learning from "../../Assets/acwassets/self-learning.png";
import Placement from "../../Assets/acwassets/job-offer.png";
import { FaGraduationCap } from "react-icons/fa6";
import { GrCertificate } from "react-icons/gr";
import { CiVideoOn } from "react-icons/ci";
import { HiOutlineCommandLine } from "react-icons/hi2";
import "./ACWPHeroSection.css";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { SlBookOpen } from "react-icons/sl";

const ACWPHeroSection = ({showCallbackForm,setIsCallbackFormRegister}) => {
 
  const handleClick=()=>{
    showCallbackForm();
    setIsCallbackFormRegister();
  }
  return (
    <motion.section
      initial={{ y: 50, opacity: 0 }}
      whileInView={{ y: 0, opacity: 1 }}
      viewport={{ once: true }}
      transition={{ duration: 1 }}
      className="acwp-hero-section-container"
    >
      <div className="hero-section-left-container">
        <h3>Master Red Teaming With</h3>
        <hr />
        <h1>Advance Cyber Warfare Program</h1>
        <p className="acwp-hero-section-desc">
        Unlock the future of cybersecurity with our Advance Cyber Warfare Program, a cutting-edge red teaming course designed for those who aspire to master the art of offensive security. This program offers unparalleled insights into the tactics, techniques, and procedures used in real-world cyber warfare, making it the ideal red team cybersecurity course for professionals and enthusiasts aiming to stay ahead in the ever-evolving digital landscape. 
        </p>
        <div className="hero-section-points">
          <div className="hero-section-points-wrapper">
            <img src={Hacker} width="20" height="20" />
            <p>Develop By Red Teamers</p>
          </div>
          <div className="hero-section-points-wrapper">
            <img src={code} width="18" height="18" />
            <p>24 hours dedicated labs</p>
          </div>
          <div className="hero-section-points-wrapper">
            <img src={Learning} width="25" height="25" />
            <p>Self-paced learning</p>
          </div>
          <div className="hero-section-points-wrapper">
            <img src={Placement} width="25" height="25" />
            <p>Placement Support</p>
          </div>
         
        </div>
        {/* <div className="hero-section-metric-points">
          <div className="hero-section-metric-points-first">
       
            <p style={{ fontSize: "1.5rem" }}></p>
          </div>
          <div className="hero-section-metric-points-second">
           
            <p style={{ fontSize: "1.5rem" }}></p>
          </div>
          <div className="hero-section-metric-points-third">
           
            <p style={{ fontSize: "1.5rem" }}></p>
          </div>
          <div className="hero-section-metric-points-fourth">
            
            <p style={{ fontSize: "1.5rem" }}></p>
          </div>
        </div> */}
      </div>

      <div className="hero-section-right-container">
        <div className="purple-hue"></div>
        <div className="green-hue"></div>
        <div className="yellow-hue"></div>
        <div className="hero-section-right-container-card-container">
          <img className="card-img" src={Dashboard} />
          <p className="coming-soon-text">Coming Soon!</p>
          <p className="hero-section-price">
            ₹20,000<span>+GST</span>
          </p>
          <div className="hero-whats-included">
            <h1>What's in the course</h1>
            <div className="hero-whats-included-points">
              <CiVideoOn width="35px" height="35px" />
              <p>10 hours+ Video Content</p>
            </div>
            <div className="hero-whats-included-points">
              <HiOutlineCommandLine width="35px" height="35px" />
              <p>Real World Hacking Scenarios</p>
            </div>
            <div className="hero-whats-included-points">
              <SlBookOpen width="35px" height="35px" />
              <p>Most relevant course curriculum</p>
            </div>
            <div className="hero-whats-included-points">
              <GrCertificate width="35px" height="35px" />
              <p>Industry Recognised Certification</p>
            </div>
          
           
          </div>
       
         
            <button onClick={()=>handleClick()}>Register Now</button>
          
          <div className="hero-section-registration-desc">
            <span><strong>2500+</strong> Students Already Registered!</span>
            <span>Don't miss out! Hurry up and secure your spot now by registering.</span>
            
            </div>
        </div>
      </div>
    </motion.section>
  );
};

export default ACWPHeroSection;
