import './App.css';
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle";
import Home from './Components/Home/Home';
import { useEffect } from 'react';
import { Routes, Route } from 'react-router';
import Webinar from './Components/Webinar/Webinar';

import Refundpolicy from './Components/Commonpages/Refundpolicy';
import Faq from './Components/Commonpages/Faq';
import Termsofservice from "./Components/Commonpages/Termsofservice";
import Dataprivacy from './Components/Commonpages/Dataprivacy';
import Signup from './Components/Authpages/Signup';
import Login from "./Components/Authpages/Login";
import Forgot from './Components/Authpages/Forgot';
import Resetpassword from './Components/Authpages/Resetpassword';
import Contactus from './Components/Commonpages/Contactus';
import Aboutus from './Components/Commonpages/Aboutus';
import Blog from './Components/Dyanmicpages/Blog';
import Blogdesc from './Components/Dyanmicpages/Blogdesc';
import Course from './Components/Dyanmicpages/Course';
import Editprofile from './Components/Dashboard/Profile/Editprofile';
import Changepassword from './Components/Dashboard/Profile/Changepassword';
import Feedback from './Components/Dashboard/Feedback/Feedback';
import Notification from './Components/Dashboard/Feedback/Notification';
import RaiseQuery from './Components/Dashboard/Feedback/RaiseQuery';

import Setting from './Components/Dashboard/Setting/Setting';
import Welcome from './Components/Dashboard/Welcomescreen/Welcome';
import Labs from './Components/Dashboard/Entities/Labs';
import DashCourses from "./Components/Dashboard/Entities/DashCourses";
import Dashmastercourses from './Components/Dashboard/Entities/Dashmastercourses';
import Labsdesc from './Components/Dashboard/Entities/Labsdesc';
import Profile from './Components/Dashboard/Profile/Profile';
import Dashcoursedesc from './Components/Dashboard/Entities/Dashcoursedesc';
import Challenges from './Components/Dashboard/Entities/Challenges';
import Chapterdesc from './Components/Dashboard/Entities/Chapterdesc';
import Subscription from './Components/Dashboard/Feedback/Subscription';
import Video from './Components/Dashboard/Entities/Video';
import Challengedesc from './Components/Dashboard/Entities/Challengedesc';
import Test from './Components/Dashboard/Entities/Test';
import Challengebycategory from './Components/Dashboard/Entities/Challengebycategory';
import Usershome from './Components/Dashboard/Feedback/Usershome';
import Activateemail from './Components/Authpages/Activateemail';
import Requestaccepted from './Components/Authpages/Requestaccepted';
import Business from './Components/Commonpages/Business';
import AOS from 'aos';
import 'aos/dist/aos.css'
import Corporrate from './Components/Commonpages/Corporrate';
import Webpwn from './Components/Dyanmicpages/Webpwn';
import Webdrub from './Components/Dyanmicpages/Webdrub';
import Comingsoon from './Components/Commonpages/Comingsoon';
import Errorpage from './Components/Authpages/Error';
import Internshipdetail from './Components/Dyanmicpages/Internshipdetail';
import Leaderboard from './Components/Dyanmicpages/Leaderboard';
import Revex from './Components/Dyanmicpages/Revex';
import Extendsubscription from './Components/Dashboard/Feedback/Extendsubscription';
import Havoc from './Components/Dashboard/Entities/Havoc';
import Registrationsucess from './Components/Authpages/Registrationsucess';
import Subscriptionsucess from './Components/Authpages/Subscriptionsucess';
import Subscriptionsuccesswithoutauth from './Components/Authpages/Subscriptionsuccesswithoutauth';
import Havoccommon from './Components/Dyanmicpages/Havoccommon';
import Hackerhavocoverview from './Components/Dashboard/Entities/Hackerhavocoverview';
import Havocleaderboard from './Components/Dashboard/Entities/Havocleaderboard';
import UniLogin from "./Components/Authpages/UniLogin";
import Uni from './Components/Dashboard/University/Uni';
import University from "./Components/Dyanmicpages/University";
import Halloffame from './Components/Dyanmicpages/Halloffame';
import VerifyPayment from './Components/Dashboard/Feedback/VerifyPayment';
import Halloffameuser from './Components/Dyanmicpages/Halloffameuser';
import Mastersubscription from './Components/Dashboard/Feedback/Mastersubscription';
import VerifyPayment2 from './Components/Dashboard/Feedback/VerifyPayment2';
import IndustrialTrainingPage from './Components/IndustrialTraining/IndustrialTrainingPage';
import ProtectedRoute from './ProtectedRoute';
import NavbarComponentWrapper from './Components/NavbarComponentWrapper';
import BinaryExploitation from './Components/Dyanmicpages/BinaryExploitation';
import WebPwnStatic from './StaticPages/WebPwnStatic';
import Events from './Components/Dyanmicpages/Events';
 import Bookslot from './Components/Dashboard/Bookslot/Bookslot';
import Eventsctf from './Components/Dyanmicpages/Eventsctf';
import Eventsdetails from './Components/Dyanmicpages/Eventsdetails';
import SummerTrainingPage from './Components/SummerTraining/SummerTrainingPage';
import Subscriptionsucess2 from './Components/Authpages/SubscriptionSuccess2';
import SummerTrainingInside from './Components/SummerTraining/SummerTrainingInside';



import DashCoursesAcw from './Components/Dashboard/Entities/DashCoursesAcw';

import AcwPrize from './Components/Acw/AcwPrize';
import ACWPage from './Components/ACWP/ACWPage';
import { ScrollRestoration } from 'react-router-dom';
import VerifyCertificate from './Components/VerifyCertificate/VerifyCertificate';


const App = () => {
  document.addEventListener("contextmenu", (event) => {
    event.preventDefault();
  });



  useEffect(() => {
    AOS.init();
  }, [])


  return (
    <>

      <Routes>



        <Route element={<ProtectedRoute />}>
          <Route path='/summer-training' element={<SummerTrainingInside />} />
          <Route path="/feedback" element={<Feedback />} />
          <Route path="/userhome" element={<Usershome />} />
          <Route path="/Profile" element={<Profile />} />
          <Route path="/masterplan" element={<Mastersubscription />} />
          <Route path="/hackmasterseries/team" element={<Hackerhavocoverview />} />
          <Route path="/hackmasterseries/leaderboard" element={<Havocleaderboard />} />
          <Route path="/hackmasterseries" element={<Havoc />} />


          <Route path="/Paymentsuccess" element={<Subscriptionsuccesswithoutauth />} />
          <Route path="/extendplan/:course_id" element={<Extendsubscription />} />

          <Route path="/raisequery" element={<RaiseQuery />} />
          <Route path="/editprofile" element={<Editprofile />} />
          <Route path="/changepassword" element={<Changepassword />} />
          <Route path="/subscription/:course_id" element={<Subscription />} />
          <Route path="/verifysubscription/:plan_id" element={<VerifyPayment />} />
          <Route path="/chapterdesc/:chapter_id" element={<Chapterdesc />} />
          <Route path="/challenges/:course_id" element={<Challenges />} />
          <Route path="/Labsdesc/:labs_id" element={<Labsdesc />} />
          <Route path="/dashcourses" element={<DashCourses />} />
          <Route path="/dashmastercourses/:course_id" element={<Dashmastercourses />} />
          <Route path="/coursedesc/:course_id" element={<Dashcoursedesc />} />
          <Route path="/Welcome" element={<Welcome />} />
          <Route path="/setting" element={<Setting />} />
          <Route path="/notification" element={<Notification />} />
          <Route path="/Labs" element={<Labs />} />
          <Route path="/challengedesc/:chall_id" element={<Challengedesc />} />
          <Route path="/unidetails" element={<Uni />} />
          <Route path="/challengebycat/:chall_id" element={<Challengebycategory />} />
          <Route path="/bookslot"  element={<Bookslot/>} /> 
        </Route>

        <Route element={<NavbarComponentWrapper />}>
        <Route path="/verify-certificate" element={<VerifyCertificate/>} />
          <Route path="/success" element={<Registrationsucess />} />
         
          <Route path="/subscriptionsuccess" element={<Subscriptionsucess />} />
          <Route path="/industrial-training-success" element={<Subscriptionsucess2 />} />
          <Route path='/summer-training-in-cybersecurity' element={<SummerTrainingPage />} />



          <Route path="/resetpass" element={<Resetpassword />} />
          <Route path="/forgotpass" element={<Forgot />} />
          <Route path="/resetpass" element={<Resetpassword />} />
          <Route path='/stack-xploit-cyber-security-course' element={<BinaryExploitation />} />
          <Route path="/" element={<Home />} />
          <Route path="/webpwn-professional-web-hacking-course" element={<Webpwn />} />
          <Route path="/Webdrub" element={<Webdrub />} />
          <Route path="/business" element={<Business />} />
          <Route path="/corporate" element={<Corporrate />} />

          <Route path="/activateemail/:token" element={<Activateemail />} />
          <Route path="/requestaccepted/:token" element={<Requestaccepted />} />
          <Route path="/Video" element={<Video />} />

          <Route path="/privacypolicy" element={<Refundpolicy />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/termsofservice" element={<Termsofservice />} />
          <Route path="/dataprivacy" element={<Dataprivacy />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/signin" element={<Login />} />
          <Route path="/unilogin" element={<UniLogin />} />
          <Route path="/contactus" element={<Contactus />} />
          <Route path="/aboutus" element={<Aboutus />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/:blog_id" element={<Blogdesc />} />
          <Route path="/eventdetails/:event_id" element={<Eventsdetails />} />
          <Route path="/course" element={<Course />} />
          <Route path="/revex" element={<Revex />} />
          <Route path="/comingsoon" element={<Comingsoon />} />
          {/*<Route path="/webhack"  element={<Internshipdetail} />*/}
          <Route path="/leaderboard" element={<Leaderboard />} />


          <Route path="/university" element={<University />} />
          <Route path="/bugbounty" element={<Halloffame />} />
          <Route path="/halloffame" element={<Halloffameuser />} />
          <Route path="/best-cyber-security-online-course" element={<WebPwnStatic />} />
       
          <Route path="/events" element={<Events />} />
          <Route path="/eventsctf/:eventType" element={<Eventsctf />} />
          <Route path="/testi" element={<Internshipdetail />} />
          <Route path="/eventdetails/:event_id" element={<Eventsdetails />} />
          <Route path="/ctfhackmaster/:event_id" element={<Havoccommon />} />
          <Route path="/checkout/:plan_id" element={<VerifyPayment2 />} />
          <Route path='/webinar' element={<Webinar />} />


          <Route path='/acwp-red-teaming-training-course' element={<ACWPage/>} />
          
        
          <Route path='/industrial-training-in-cybersecurity' element={<IndustrialTrainingPage />} />


          <Route path='/acwcourseprice' element={<AcwPrize />} />

          <Route path='/coursedescacw' element={<DashCoursesAcw />} />
          <Route path='/acwcourseprice' element={<AcwPrize />} />


        </Route>
        <Route path="*" element={<Errorpage />} />
      </Routes>

    </>
  );
}

export default App;
