import React from 'react'
import { GrFormCheckmark } from "react-icons/gr";
import IMAGES from '../../Assets/industrial.png';
import {motion} from 'framer-motion';
import ACWCERT from '../../Assets/courses/acwpcertificate.png'
import LEFTIMG from '../../Assets/acwassets/bg-effect.svg';
import RIGHTIMG from '../../Assets/acwassets/bg-effect-right.svg';
import { RiArrowRightSLine } from "react-icons/ri";
import './SummerTrainingPage.css';
const certificatepoints = [
"Global Recognition",
"Professional Acknowledgment",
"Career Catalyst",
"Industry Validation",
"Skill Verification",
"Career Catalyst",
]
const SummerTrainingCertificate = ({heading,content,type,onClick}) => {
    return (
        <div className={` int-observe ind-certificate ${type==="acw" ? "blue-bg bg-effect acw-certificate-container-wrapper" : "red-bg training-highlight-section3"}`} data-name="5">
          
             <div className='training-highlight-section-right acwp-margin'> 
    <motion.img initial={{scale:0.7,opacity:0.3}} whileInView={{scale:1,opacity:1}} viewport={{once:true}} transition={{duration:0.8}} src={type==="acw" ? ACWCERT :IMAGES} className={`${type==="acw" ? "" :"certi-image"}`}/>
               
        </div>
        <motion.div initial={{opacity:0.3}} whileInView={{scale:1,opacity:1}} viewport={{once:true}} transition={{duration:2}} className={`${type==='acw' ? 'acw-right-section-container-cert': 'training-highlight-section-left'}`}> 
        <h3  style={{cursor:"pointer"}}    className={`${type==="acw" ? "acwp-heading2" :"training-heading"}`}>{heading}</h3>
        <p className={`${type==="acw" ? "acwp-certificate-desc" :"training-desc4"}`}>{content}
    </p>
    
    {type==="acw" ?
    <div className='acwp-certificate-points-wrapper'> {certificatepoints.map((x) => (
        <div className="acwp-certificate-points">
          <div className="acwp-certificate-icon-wrapper">
            <GrFormCheckmark className="acwp-certificate-icon" />
          </div>
          <p className="acwp-certificate-points-para">{x}</p>
        </div>
      ))}</div>
    :<></>}
      {type==="acw" ? <div className='acwp-certification-btn-container' ><p onClick={()=>onClick()} className='acwp-certification-btn'>Enroll in ACWP Program <RiArrowRightSLine className='acwp-certification-btn-icon'/></p></div>:<></>}
        </motion.div>
        
       
    
    </div>
   
      )
}

export default SummerTrainingCertificate
