import React, { useState, useEffect, useRef } from "react";
import Doc from "../../../Assets/Entity/Lab/Doc.svg";
import { MdKeyboardArrowDown } from "react-icons/md";
import { BsCloudDownload } from "react-icons/bs";
import { useLocation, useNavigate, Link, useParams } from "react-router-dom";
import Gotolink from "../../../Assets/Entity/Lab/Go To Link (1).svg";
import User from "../../../Assets/images/Mask_Group_19.png";
import ReactStars from "react-rating-stars-component";
import Edit from "../../../Assets/Entity/Lab/Edit.svg";
import AddReview from "../Modals/AddReview";
import Adddifficulty from "../Modals/Adddifficulty";
import Submitflag from "../Modals/Submitflag";
import Loading from "../Modals/Loading";
import Challengeicon from "../../../Assets/Dashboard/Challenges.svg";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { IoPlayCircleOutline } from "react-icons/io5";
const Chapterdesc = (props) => {
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [showNextButton, setShowNextButton] = useState(false);
  const[presignedurl,setpresignedurl]=useState([]);

  const videoRef = useRef(null);

  useEffect(() => {
    const handleFullscreenChange = () => {
      const isInFullscreen = document.fullscreenElement === videoRef.current;
      setIsFullscreen(isInFullscreen);
    };

    const handleTimeUpdate = () => {
      const video = videoRef.current;
      if (video && !isNaN(video.duration) && video.duration > 0) {
        if (isFullscreen && video.duration - video.currentTime <= 5) {
          setShowNextButton(true);
        } else {
          setShowNextButton(false);
        }
      }
    };

    // Add event listeners
    document.addEventListener("fullscreenchange", handleFullscreenChange);
    if (videoRef.current) {
      videoRef.current.addEventListener("timeupdate", handleTimeUpdate);
    }

    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
      if (videoRef.current) {
        videoRef.current.removeEventListener("timeupdate", handleTimeUpdate);
      }
    };
  }, [isFullscreen]);

  useEffect(() => {
    const video = videoRef.current;
    const onMetadataLoaded = () => {
      console.log("Video metadata loaded:", video.duration);
    };

    if (video) {
      video.addEventListener("loadedmetadata", onMetadataLoaded);
    }

    return () => {
      if (video) {
        video.removeEventListener("loadedmetadata", onMetadataLoaded);
      }
    };
  }, []);


  const topicsContainerRef = useRef(null);
  const scrollPositionRef = useRef(null);
  const [Resource, setResource] = useState(true);
  const [chapter, setchapter] = useState([]);
  const [sidechapter, setsidechapter] = useState([]);
  const [course, setcourse] = useState([]);
  const [show, setshow] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpen2, setModalOpen2] = useState(false);
  const [modalOpen3, setModalOpen3] = useState(false);
  const [modalOpen4, setModalOpen4] = useState(false);
  const [selectedChapter, setSelectedChapter] = useState(null);
  const [clickablenot, setclickablenot] = useState(false);
 
  const { chapter_id } = useParams();
  const navigate = useNavigate();
  const [userdata, setuserdata] = useState("");
  const API_Key = process.env.REACT_APP_API_URL;
  const location = useLocation();
  console.log(location, "location");

  const onPrevButtonPressed = () => {
    if (course.chapters.length === 0) {
      for (let i = 0; i < course.sections.length; i++) {
        for (let j = 0; j < course.sections[j].sectionContents.length; j++) {
          if (course?.sections[i]?.sectionContents[j]?._id == selectedChapter?._id) {
            console.log("1")
            if (j == 0) {
              console.log("2")
              if (i != 0) {
                console.log("3")
                setSelectedChapter(
                  course.sections[i - 1].sectionContents[
                    course.sections[i - 1].sectionContents.length - 1
                  ]
                );
                setClicked(clicked-1)
              }
            } else {
             console.log("4")
              setSelectedChapter(course.sections[i].sectionContents[j - 1]);
            }
          }
        }
      }
    } else {
      for (let i = 0; i < course.chapters.length; i++) {
        if (course?.chapters[i]?._id === selectedChapter._id) {
          if (i !== 0) {
            setSelectedChapter(course.chapters[i - 1]);
          }
        }
      }
    }
  };
  const onNextButtonPressed = () => {
    if (course.chapters.length === 0) {
      for (let i = 0; i < course.sections.length; i++) {
        for (let j = 0; j < course.sections[i].sectionContents.length; j++) {
          if (
            course.sections[i].sectionContents[j]._id === selectedChapter._id
          ) {
            if (j + 1 === course.sections[i].sectionContents.length) {
              if (i + 1 === course.sections.length) {
                setClicked(0)
                setSelectedChapter(course.sections[0].sectionContents[0]);
              } else {
                setClicked(clicked+1)
                setSelectedChapter(course.sections[i + 1].sectionContents[0]);
              }
            } else {
              setSelectedChapter(course.sections[i].sectionContents[j + 1]);
            }
          }
        }
      }
    } else {
      for (let i = 0; i < course.chapters.length; i++) {
        if (course.chapters[i]._id === selectedChapter._id) {
          if (i + 1 !== course.chapters.length) {
            setSelectedChapter(course.chapters[i + 1]);
          }
        }
      }
    }
  };
  const fetchuser = async () => {
    try {
      const res = await fetch(`${API_Key}/userdata`, {
        method: "GET",
        headers: {
          mode: "cors",
          "Access-Control-Allow-Origin": `${API_Key}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Credentials": true,
        },
        credentials: "include",
      });
      const data = await res.json();

      setuserdata(data);

      if (res.status !== 200) {
        const error = new Error(res.error);
        throw error;
      }
    } catch (err) {
      console.log(err);
      //navigate("/signin");
    }
  };
  useEffect(() => {
    fetchuser();
  }, []);
  const fetchpresignedurl = async () => {
    let chapterid;
    if(selectedChapter===null){
      chapterid=chapter_id
    }
    else{
      chapterid=selectedChapter._id
    }
    try {
      const res = await fetch(`${API_Key}/getcourse_video/`+chapterid, {
        method: "POST",
        body: JSON.stringify({
          courseId:courseid
        }),
        headers: {
          mode: "cors",
          "Access-Control-Allow-Origin": `${API_Key}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Credentials": true,
        },
        credentials: "include",
      });
      const data = await res.json();

setpresignedurl(data.presignedUrl);
     

      if (res.status !== 200) {
        const error = new Error(res.error);
        throw error;
      }
    } catch (err) {
      console.log(err);
      //navigate("/signin");
    }
  };
  useEffect(() => {
    fetchpresignedurl();
  }, [selectedChapter]);

  let courseid = location.state.courseid;
  let sectionid=location.state.sectionid;
  const [courseidnew, setcourseidnew] = useState(courseid);
  const [courseids, setcourseids] = useState(courseid);
  const [chaptercou, setchapterco] = useState("");
  const [clicked, setClicked] = useState(sectionid);

  useEffect(() => {
    //setModalOpen4(true);
    const fetchchapters = async () => {
      const requestData = {
        chapterid: chapter_id,
        courseid: courseid,
      };

      try {
        const response = await fetch(`${API_Key}/getchapter`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            mode: "cors",
            "Access-Control-Allow-Origin": `${API_Key}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Credentials": true,
          },
          credentials: "include",
          body: JSON.stringify(requestData),
        });

        if (response.status === 200) {
          const responseData = await response.json();
          setchapter(responseData);
          setchapterco(responseData.chapterid || responseData._id);
          setvideourl(responseData.video || responseData.contentVideo);
          //setModalOpen4(false);
        } else if (response.status === 403) {
          //navigate("/signin");
        } else {
          // Handle non-OK response
          console.error("Error:", response.statusText);
          // setModalOpen3(true);
        }
      } catch (error) {
        // Handle fetch error
        console.error("Fetch Error:", error);
        // setModalOpen3(true);
      }
    };

    fetchchapters();
  }, []);

  const fetchCoursedesc = async () => {
    try {
      const res = await fetch(`${API_Key}/getcourse/` + courseidnew, {
        method: "GET",
        headers: {
          mode: "cors",
          "Access-Control-Allow-Origin": `${API_Key}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Credentials": true,
        },
        credentials: "include",
      });
      const data = await res.json();

      setcourse(data);
      if (data.chapters && data.chapters.length !== 0) {
        data.chapters.map((chapter) => {
          if (chapter._id === chapter_id) {
            setSelectedChapter(chapter);
          }
        });
      } else {
        data.sections.map((section) => {
          section.sectionContents.map((content) => {
            if (content._id == chapter_id) {
              setSelectedChapter(content);
            }
          });
        });
      }

      if (res.status !== 200) {
        const error = new Error(res.error);
        throw error;
      }
    } catch (err) {
      console.log(err);
      //navigate("/signin");
    }
  };
  useEffect(() => {
    fetchCoursedesc();
  }, []);
  useEffect(() => {
    const topicElement = document.getElementById(selectedChapter?._id);

    if (topicElement) {
      window.scrollTo(0, scrollPositionRef.current);
      topicElement.scrollIntoView({
        behavior: "smooth",

        block: "start",
      });
    }
  }, [selectedChapter]);
  const fetchsidechapter = async () => {
    try {
      const res = await fetch(`${API_Key}/getcourse_chapter/` + courseid, {
        method: "GET",
        headers: {
          mode: "cors",
          "Access-Control-Allow-Origin": `${API_Key}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Credentials": true,
        },
        credentials: "include",
      });
      const data = await res.json();

      setsidechapter(data);

      if (res.status !== 200) {
        const error = new Error(res.error);
        throw error;
      }
    } catch (err) {
      console.log(err);
      //navigate("/signin");
    }
  };
  useEffect(() => {
    fetchsidechapter();
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const category = "course";
  const [videourl, setvideourl] = useState("");
  const fetchvideo = async () => {
    try {
      const res = await fetch(`${API_Key}/getcourse_chapter/` + courseid, {
        method: "GET",
        headers: {
          mode: "cors",
          "Access-Control-Allow-Origin": `${API_Key}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Credentials": true,
        },
        credentials: "include",
      });
      const data = await res.json();

      setvideourl(data.video);

      if (res.status !== 200) {
        const error = new Error(res.error);
        throw error;
      }
    } catch (err) {
      console.log(err);
      //navigate("/signin");
    }
  };
  useEffect(() => {
    fetchvideo();
  }, []);

  return (
    <div>
      {modalOpen && (
        <AddReview
          id={courseid}
          setOpenModal={setModalOpen}
          category="course"
        />
      )}
      {modalOpen2 && (
        <Submitflag
          id={props.match.params.challenges_id}
          setOpenModal={setModalOpen2}
        />
      )}
      {modalOpen3 && (
        <Adddifficulty
          id={courseid}
          category={category}
          setOpenModal={setModalOpen3}
        />
      )}
      {modalOpen4 && <Loading setOpenModal={setModalOpen4} />}

      <div className="background_dashboard p-1">
        <div className="Entitycommontopper2">
          <div className="container-fluid pb-5 ">
            <div className="row ml-5 pb-5">
              <div className="col-lg-8 col-md-8 col-12  ">
                <div className="row">
                  <div className="col-lg-8">
                    <h1 className="nowplayhead">
                      Now Playing-
                      <span>
                        {" "}
                        {selectedChapter?.chaptername ||
                          selectedChapter?.contentName}{" "}
                      </span>
                    </h1>
                    <br></br>
                    <br></br>
                  </div>

                  <div className="col-lg-4  ">
                    <div className="row justify-content-center pt-5  ">
                      <div className="col ">
                        <button
                          className="btn nextbtn"
                          onClick={onPrevButtonPressed}
                        >
                          Previous
                        </button>
                      </div>
                      <div className="col ">
                        <button
                          className="btn nextbtn"
                          onClick={onNextButtonPressed}
                        >
                          Next
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-10 ">
                <br></br>
                <br></br>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid ">
          <div className="row ">
            <div className="col-lg-9 col-md-9 col-12 p-5 ">
              <div className="row col-lg-12  justify-content-center">
                <div className="videodiv text-center">
                  {/*<ReactPlayer url={chapter.video} controls  width={"100%"}/>*/}
                  {/*<div className='videodiv2'>
  <div class="words word-1">
  <span>{userdata.email}</span>
  <span>{userdata.fname}</span>
  <span>{userdata.phone }</span>
 
</div>

  
    </div>*/}
    <video
    ref={videoRef}
    width={"100%"}
    src={presignedurl}
    controls
      //crossOrigin="anonymous"
    controlsList="nodownload"
    onEnded={onNextButtonPressed}
    //crossOrigin="anonymous"
  >
    <track
      className="subtitle-overlay"
      kind="subtitles"
      src={selectedChapter?.subtitle}  // Ensure this is a valid URL pointing to a .vtt file
      srcLang="en"
      label="English"
    
      default  // Optional: remove this if it causes issues
    />
   
  </video>
 
     {/* Show "Next Video" button in fullscreen mode when video is near the end */}
   

                </div>
              </div>
              {isFullscreen && showNextButton && (
                <button
                  className={`btn next-video-button ${showNextButton ? "show" : ""}`}
                  onClick={onNextButtonPressed}
                >
                  Next Video
                </button>
              )}
              <div className="row col-lg-11 chapterdescdiv ">
                <div className="text-align-left ">
                  <h1 className="Chapterhead2 ">
                    {selectedChapter?.chaptername ||
                      selectedChapter?.contentName}
                  </h1>
                  <hr className="reviewshr2"></hr>
                </div>
                <div className="col-lg-2 col-md-6 col mt-5 offset-4 float-right"></div>
                <div className="row col-lg-12">
                  <p className="labdescpara">
                    {selectedChapter?.content ||
                      selectedChapter?.contentDescription}
                  </p>
                 
                </div>

                <div className="row mt-5 pl-5 mb-5">
                  <p className="labreviewshead">Reviews</p>

                  <hr className="reviewshr"></hr>
                  {course.reviews &&
                    course.reviews.map((review, id) => (
                      <div className="col-lg-12 col-md-12 col-12">
                        <div className="row">
                          <div className="col-lg-5 col">
                            <div className="row">
                              <div className="col-lg-8 col p-3">
                                <img
                                  src={User}
                                  style={{ height: "4rem" }}
                                  alt="user"
                                />
                                <span className="labreviewhead">
                                  {review.uid}
                                </span>
                                <span className="labreviewhead2">13m</span>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-3 col-6 offset-lg-4 p-5">
                            <ReactStars
                              count={5}
                              edit={false}
                              size={18}
                              isHalf={true}
                              emptyIcon={<i className="far fa-star"></i>}
                              halfIcon={<i className="fa fa-star-half-alt"></i>}
                              fullIcon={<i className="fa fa-star"></i>}
                              activeColor="#ffa534"
                              value={review.rating}
                              color="lightgray"
                            />
                          </div>
                        </div>
                        <div className="row col-lg-12">
                          <p className="labreviewpara">{review.review}</p>
                        </div>
                        <hr className="reviewshr"></hr>
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <div
              className="col-lg-3 col-md-3 col-12 justify-content-center "
              id="chapterdiv"
            >
              <div id="chapterdiv2" className="row">
                <h1 className="chapterdivhead">Topics</h1>

                {course.chapters && (
                  <div className="col-12 col-lg-12 ">
                    {course?.chapters?.map((chapter) => (
                      <div
                        ref={topicsContainerRef}
                        id={chapter._id}
                        onClick={() => setSelectedChapter(chapter)}
                        className={
                          selectedChapter?._id === chapter?._id
                            ? "slider-background chapter-slider-component"
                            : "chapter-slider-component"
                        }
                      >
                        <p className="chapterpara mt-3">
                          {" "}
                          {chapter.chaptername}
                        </p>
                      </div>
                    ))}
                  </div>
                )}

                {course.sections && (
                  <div className="col-12 col-lg-12 ">
                    {course?.sections?.map((chapter, index) => (
                      <>
                        {clicked === index ? (
                          <div
                            ref={topicsContainerRef}
                            id={chapter._id}
                            className={
                              selectedChapter?._id === chapter?._id
                                ? "slider-background chapter-slider-component border-class"
                                : "chapter-slider-component border-class"
                            }
                          >
                            <div
                              onClick={() => setClicked(index)}
                              className={
                                clicked === index
                                  ? "section-slider-head-selected mt-3"
                                  : "section-slider-head mt-3 "
                              }
                            >
                              {" "}
                              {chapter.sectionName}
                              <MdOutlineKeyboardArrowDown />{" "}
                            </div>
                            {clicked === index ? (
                              <div>
                                {chapter.sectionContents.map((x) => (
                                  <div
                                    className={
                                      selectedChapter?._id === x?._id
                                        ? "selected-section-slider-chapter"
                                        : "section-slider-chapter"
                                    }
                                    onClick={() => setSelectedChapter(x)}
                                  >
                                    {selectedChapter?._id === x?._id ? (
                                      <IoPlayCircleOutline className="selected-video-playback-icon" />
                                    ) : (
                                      <IoPlayCircleOutline className="video-playback-icon" />
                                    )}
                                    {x.contentName}
                                  </div>
                                ))}
                              </div>
                            ) : null}
                          </div>
                        ) : (
                          <div
                            ref={topicsContainerRef}
                            id={chapter._id}
                            className={
                              selectedChapter?._id === chapter?._id
                                ? "slider-background chapter-slider-component"
                                : "chapter-slider-component"
                            }
                          >
                            <div
                              onClick={() => setClicked(index)}
                              className={
                                clicked === index
                                  ? "section-slider-head-selected mt-3"
                                  : "section-slider-head mt-3 "
                              }
                            >
                              {" "}
                              {chapter.sectionName}
                              <MdOutlineKeyboardArrowDown />{" "}
                            </div>
                            {clicked === index ? (
                              <div>
                                {chapter.sectionContents.map((x) => (
                                  <div
                                    className={
                                      selectedChapter?._id === x?._id
                                        ? "selected-section-slider-chapter"
                                        : "section-slider-chapter"
                                    }
                                    onClick={() => setSelectedChapter(x)}
                                  >
                                    {selectedChapter?._id === x?._id ? (
                                      <IoPlayCircleOutline className="selected-video-playback-icon" />
                                    ) : (
                                      <IoPlayCircleOutline className="video-playback-icon" />
                                    )}
                                    {x.contentName}
                                  </div>
                                ))}
                              </div>
                            ) : null}
                          </div>
                        )}
                      </>
                    ))}
                  </div>
                )}
              </div>

             {!course.Personalcourse && <div id="resourcecard" className="row  mb-5">
                <div className="col-lg-12 col-md-12 col-12 Raviewscard2">
                  <div className="row">
                    <div className="col-lg-3 col">
                      <h1 className="resourcehead pt-4 pb-2">Resources</h1>
                    </div>
                    <div className="col-lg-1 col pt-3 pb-2 offset-7">
                      <div
                        className="Labbtn "
                        onClick={() => setResource(!Resource)}
                      >
                        <MdKeyboardArrowDown
                          className="span"
                          color="white"
                          size={15}
                          style={{ marginTop: "-2.4rem" }}
                        />
                      </div>
                    </div>
                    {Resource && (
                      <div className="row    pt-3 pr-5 pb-5  ">
                        <div className="col">
                          <div className="row">
                            <div className="col-lg-3 col-3">
                              <img
                                src={Doc}
                                alt="doc"
                                style={{ borderRadius: ".4rem" }}
                              />
                            </div>
                            <div className="col-lg-9 col-9" id="reddiv">
                              <div className="row">
                                <div className="col-lg-9 col-8">
                                  <h6 id="reddivhead">
                                    {selectedChapter?.chaptername}
                                  </h6>
                                </div>

                                {selectedChapter?.image === "" ? (
                                  <div className="col-lg-2 col-2 ">
                                    <h6 id="reddivhead">
                                      {" "}
                                      <BsCloudDownload size={20} />
                                    </h6>
                                  </div>
                                ) : (
                                  <a href={selectedChapter?.image} download>
                                    {" "}
                                    <div className="col-lg-2 col-2 ">
                                      <h6 id="reddivhead">
                                        {" "}
                                        <BsCloudDownload size={20} />
                                      </h6>
                                    </div>
                                  </a>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>}

              {selectedChapter?.chapterid === "" ? (
                <div className="row justify-content-center col-lg-12 submitflagbtn">
                  <span className="mr-3">
                    <img
                      src={Gotolink}
                      style={{ height: "2.5rem", marginTop: ".4rem" }}
                      alt="write"
                    />
                  </span>
                  <h1 className="enterpreyhead"> No Challenge</h1>
                </div>
              ) : (
                <Link
                  to={`/challengedesc/${selectedChapter?.chapterid}`}
                  style={{ textDecoration: "none" }}
                >
                  {" "}
                  <div
                    disable="true"
                    className="row justify-content-center col-lg-12 submitflagbtn"
                    id={course._id}
                  >
                    <span className="mr-3">
                      <img
                        src={Gotolink}
                        style={{ height: "2.5rem", marginTop: ".4rem" }}
                        alt="write"
                      />
                    </span>
                    <h1 className="enterpreyhead"> Access the Challenge</h1>
                  </div>
                </Link>
              )} 
             
              {!course.Personalcourse && <div
                className="row col-lg-12 justify-content-center mt-5"
                id="writereviewbtn"
                onClick={() => setModalOpen(true)}
              >
                <span className="mr-3">
                  <img src={Edit} style={{ height: "1.5rem" }} alt="write" />
                </span>
                Write a Review
              </div>}
              {!course.Personalcourse && <div
                className="row col-lg-12 justify-content-center mt-5"
                id="writereviewbtn"
                onClick={() => setModalOpen3(true)}
              >
                <span className="mr-3">
                  <img
                    src={Challengeicon}
                    style={{ height: "1.5rem" }}
                    alt="write"
                  />
                </span>
                Rate Difficulty
              </div>}

              <div className="row"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Chapterdesc;
