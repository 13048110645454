import React, { useState, useEffect } from "react";
import {ImCross} from "react-icons/im";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import Alreadyslotbbok from "./Alreadyslotbbok";

import InvalidDate from "./InvalidDate";
import Bookslotsuccess from "./Bookslotsuccess";
const API_Key = process.env.REACT_APP_API_URL;
const BookingModal = ({ setOpenModal, courseId }) => {
  console.log(courseId);
  const [bookingDate, setbookingDate] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpen2, setModalOpen2] = useState(false);
  const [modalOpen3, setModalOpen3] = useState(false);
  useEffect(() => {}, [bookingDate]);

  const onBookingSlot = () => {
    if (bookingDate === "") {
   setModalOpen2(true);
    } else {
      const data = {
        courseId: courseId,
        examDate: new Date(bookingDate),
      };
      console.log(data, "data");
      fetch(`${API_Key}/bookyour_examSlot`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          mode: "cors",
          "Access-Control-Allow-Origin": `${API_Key}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Credentials": true,
        },
        credentials: "include",
        body: JSON.stringify(data),
      })
        .then((response) => {
        
          if (response.status === 200) {
            //navigate("/welcome")
        setModalOpen3(true);
          } else if (response.status === 403) {
 
           setModalOpen(true);
          }
        })

        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };
  const handleSubmit = (e) => {
    const slotDate = new Date(e);
    console.log(slotDate.getDay(), "date");
    if (new Date() <= slotDate) {
      if (slotDate.getDay() === 6) {
        setbookingDate(slotDate);
      } else {
        setbookingDate("");
      }
    } else {
      setbookingDate("");
    }
  };
  return (
    <div>
      {modalOpen && <Alreadyslotbbok setOpenModal={setModalOpen} />}
      {modalOpen2 && <InvalidDate setOpenModal={setModalOpen2} />}
      {modalOpen3 && <Bookslotsuccess setOpenModal={setModalOpen3} />}
      <section className="modalBackground justify-content-center">
        <div className="container  justify-content-center">
          <div className="row justify-content-center">
            <div className="col-lg-8 col-md-10 col-10  modalContainer">
            
            <div className="heading-div">
            <ImCross  onClick={() => {
            setOpenModal(false);
          }} fontSize={7} className="mt-5"/>
              </div>
              <h2 className="text-center alertmainhead2">
                Book Your Slot{" "}
                <div className="row justify-content-center">
                  <span>
                    <hr className="text-center alertcommonhr"></hr>
                  </span>
                </div>
              </h2>
           
       
       
     
           

              <div className="row justify-content-center">
                <div className="col-lg-10 col-md-6  col-9 ">
                  <div className="row justify-content-center mt-2">
                    <div className="col-lg-8 col-md-8 col mb-5 mt-5">
                      <Calendar
                        onChange={(e) => handleSubmit(e)}
                        value={bookingDate}
                        className="custom-calendar"
                      />
                    </div>

                    <div className="col-lg-4 col-md-4 mt-5 justify-content-center">
                      <br></br> <br></br>
                      <p className="time-block">8:00 AM</p>
                      <p className="exam-btn " onClick={() => onBookingSlot()}>
                        Book Exam Slot
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default BookingModal;
