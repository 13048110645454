import React, { useState } from 'react';
import './VerifyCertificate.css';

const VerifyCertificate = () => {
  const [input,setInput]=useState('');
  
  return (
    <div className='bg-body'>
    <div className='verify-certificate-container'>
        <h1 className='heading'>Verify Certificate</h1>
        <h3 className='sub-heading'>Enter the Certificate ID to verify the certificate</h3>
   
    </div>
    </div>
  )
}

export default VerifyCertificate