import React, { useEffect, useState } from 'react';
import ReactDOM from "react-dom";
import { IoMdClose } from "react-icons/io";
import { Formik, Field, ErrorMessage, Form } from 'formik';
import * as Yup from "yup";
import axios from 'axios';
import "react-country-state-city/dist/react-country-state-city.css";
import {
  GetCountries,
  GetState,
  GetCity,
  GetLanguages,
  GetRegions,
  GetPhonecodes
} from "react-country-state-city";
import 'yup-phone'
const initialValues = {
  name: '',
  email: '',
  mobilenumber: '',
  query: '',
  city:"",
  profession:"",
  country:"",
  state:"",
}
const validationSchema = Yup.object().shape({
  name: Yup.string()
    .matches(/^[a-zA-Z\s]*$/, "Name cannot contain special characters or numbers")
    .required("Required"),
  email: Yup.string().email("Invalid email").required("Required"),
  mobilenumber: Yup.string()
    .phone('IN', true, "Enter valid Mobile Number")
    .required("Required"),
  query: Yup.string()
    .matches(/^[a-zA-Z\s]*$/, "Query cannot contain special characters or numbers")
    .when("isCallbackFormRegister", {
      is: false, // When isCallbackFormRegister is false
      then: Yup.string().required("Required"),
      otherwise: Yup.string().notRequired(), // Otherwise, optional
    }),
  city: Yup.string().matches(/^[a-zA-Z\s]*$/, "Enter valid city"),
  profession: Yup.string().required("Required"),
  country:Yup.string().required("Required")
});




const ACWPModal = ({ isSyllabusDownload, switchSyllabus, onClick,isCallbackFormRegister,setIsCallbackFormRegister }) => {
  const [successData, setSuccessData] = useState(false);
  const [loading,setLoading]=useState(false)
  const [error,setError]=useState("");
  const [countriesList,setCountriesList]=useState([]);
  const [statesList,setStatesList]=useState([]);
  const [cityList,setCityList]=useState([]);
  const [countryid, setCountryid] = useState(null);
  const [stateid, setstateid] = useState(0);
  
  const handleSubmit = async (values,{resetForm}) => {
    setLoading(true);
    setError('')
    setSuccessData(false);
    try{
      const data = {
        fullname: values.name,
        email: values.email,
        phoneNumber: values.mobilenumber,
        query: values.query,
        country:values.country,
        state:values.state,
        city:values.city,
        profession:values.profession,
        registerFormFilled:isCallbackFormRegister,
      }
      if(countryid!==null && statesList.length!==0){
        if(data.state===""){
          setLoading(false);
          setError("State is Required");
          return
        }
      }
      if(countryid!==null && statesList.length!==0 && cityList.length!==0){
        if(data.city===""){
          setLoading(false);
          setError("City is Required");
          return
        }
      }
    
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/acw_query_about_course_training`, data);
      if (response.data.success) {
        setLoading(false);
        setError('')
        setSuccessData(true);

        if (isSyllabusDownload) {
          const link = document.createElement('a');
          link.href = 'https://hp-sbox-public-content.s3.ap-south-1.amazonaws.com/ACWP_Syllabus_final.pdf';
          link.target = '_blank';
          link.rel = 'noopener noreferrer';
          link.style.display = 'none';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          switchSyllabus()
        }
        resetForm();
      } 
           // After success, automatically close the modal after 5 seconds
           setTimeout(() => {
            onClick();  // This will close the modal
          }, 5000); // Close after 5 seconds

    }
    catch(error){
      setLoading(false);
      setSuccessData(false);
      setError("Some error Occured")
    }
  
  }
  const closeModal = (e) => {
    if (e.target.className === "summer-training-modal") {
      onClick();
    }
  }
 
  const handleClose=()=>{
    onClick();
    setIsCallbackFormRegister();

  }

  useEffect(() => {
    
    const fetchCountries=async()=>{
      const result=await GetCountries();
      setCountriesList(result);
      
    }
    fetchCountries();
  },[])

  return ReactDOM.createPortal(
    <div className='summer-training-modal' onClick={(e) => closeModal(e)}>
      <div className='summer-training-cont2'>
        <IoMdClose className='summer-training-icon' onClick={() => handleClose()} />
        <p className='summer-training-modal-heading'>{isCallbackFormRegister ? "Register Now":'Speak to a Counsellor'}</p>
        {successData ?
         isCallbackFormRegister ? 
         <p className='message-text'>Registration Successfull</p>
          : <p className='message-text'> Submitted Successfully. Our team will get back to you shortly.</p> 
           :null}
        
        {error !=="" ? <p className='message-text2'>{error}</p> : null}
        <Formik   enableReinitialize    validationContext={{ isCallbackFormRegister }}  initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
          {(formik) => {
       
            return (
              <Form className='summer-modal-form'>
                {/*  {register ? <p className="webinar-success-component">Registration Successfull!!</p> : null} */}
               
                <div>
                  <label className='summer-modal-label-field'>Name<span style={{color:'red',fontSize:'12px'}}>*</span></label>
                  <Field className="summer-modal-input-field" type="text" id="name" name="name" placeHolder="Name" />
                  <ErrorMessage component="div" className="summer-modal-error" name="name" ></ErrorMessage>
                </div>
               <div>
                  <label className='summer-modal-label-field'>Email<span style={{color:'red',fontSize:'12px'}}>*</span></label>
                  <Field className="summer-modal-input-field" type="text" id="email" name="email" placeHolder="Email" />
                  <ErrorMessage component="div" className="summer-modal-error" name="email" ></ErrorMessage>
                </div>
                <div>
                  <label className='summer-modal-label-field'>Mobile Number<span style={{color:'red',fontSize:'12px'}}>*</span></label>
                  <Field className="summer-modal-input-field" type="text" id="mobilenumber" name="mobilenumber" placeHolder="Mobile Number" />
                  <ErrorMessage component="div" className="summer-modal-error" name="mobilenumber" ></ErrorMessage>
                </div>
                <div>
                  <label className='summer-modal-label-field'>Current Role<span style={{color:'red',fontSize:'12px'}}>*</span></label>
                  <Field as="select" className="summer-modal-input-field"  id="profession" name="profession" placeHolder="Profession">
                  <option value="">Select Your Role</option>
                  <option value="Student">Student</option>
                  <option value="Working Professional">Working Professional</option>
                  </Field>
                  <ErrorMessage component="div" className="summer-modal-error" name="profession" ></ErrorMessage>
                </div>
               
             
                <div>
                  <label className='summer-modal-label-field'>Country<span style={{color:'red',fontSize:'12px'}}>*</span></label>
                  <Field disabled={countriesList.length===0} onChange={async(e)=>{
                  try{
                    formik.setFieldValue("country", e.target.value);
                    setCountryid(parseInt(e.target.selectedOptions[0].getAttribute("data-id")))
                
                    const result=await GetState(parseInt(e.target.selectedOptions[0].getAttribute("data-id"))); 
                  
                    setCityList([]);
                     setStatesList(result);
                  }
                  catch(error){
                    console.log(error)
                  }
                 
                  
                  }

                  } as="select" className="summer-modal-input-field"  id="country" name="country" placeHolder="Country">
                  <option value="">Select Your Country</option>
                  {countriesList.map((country)=>{
                    
                    return <option data-id={country.id} value={country?.name}>{country?.name}</option>
                  })}
                    </Field>
                  <ErrorMessage component="div" className="summer-modal-error" name="country" ></ErrorMessage>
                </div>
              
                <div className='acwp-form-grp-modal'>
                <div style={{width:'50%'}}>
                  <label className='summer-modal-label-field'>State</label>
                  <Field disabled={statesList.length===0}  onChange={async(e)=>{
                  try{
                    formik.setFieldValue("state", e.target.value);
                    const result=await GetCity(countryid,parseInt(e.target.selectedOptions[0].getAttribute("data-id"))); 
                
                     setCityList(result);
                  }
                  catch(error){
                    console.log(error)
                  }
                 
                  
                  }

                  } as="select" className="summer-modal-input-field"  id="state" name="state" placeHolder="State">
                  <option value="">Select State</option>
                  {statesList.map((state)=>{
                    return <option data-id={state?.id} value={state?.name}>{state?.name}</option>
                  })}
                    </Field>
                  <ErrorMessage component="div" className="summer-modal-error" name="country" ></ErrorMessage>
                </div>
                <div style={{width:'50%'}}>
                  <label className='summer-modal-label-field'>City</label>
                  <Field disabled={cityList.length===0} onChange={async(e)=>{
                  try{
                    formik.setFieldValue("city", e.target.value);
                  }
                  catch(error){
                    console.log(error)
                  }
                  }

                  }   as="select" className="summer-modal-input-field"  id="city" name="city" placeHolder="City">
                  <option value="">Select City</option>
                  {cityList?.map((city)=>{
                    return <option data-id={city?.id} value={city?.name}>{city?.name}</option>
                  })}
                    </Field>
                  <ErrorMessage component="div" className="summer-modal-error" name="country" ></ErrorMessage>
                </div>


                </div>
                <div>
                {!isCallbackFormRegister ? <div>
                  <label className='summer-modal-label-field'>Query</label>
                  <Field as="textarea" className="summer-modal-input-field" id="query" name="query" placeHolder="Query" />
                  <ErrorMessage component="div" className="summer-modal-error" name="query" ></ErrorMessage>
                </div> :<></>}
                <p >Fields marked with ( <span style={{color:'red',fontSize:'12px'}}>*</span> ) are required.</p>
                </div>
                <button type="submit" className="web-register-btn3" disabled={!formik.isValid}>{loading ? "Submitting..." :"Submit"}</button>

              </Form>
            )
          }}
        </Formik>
      </div>
    </div>, document.getElementById("modal-root")
  )
}

export default ACWPModal;
