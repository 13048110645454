import React, { useState, useEffect, useRef } from "react";
import { ImCross } from "react-icons/im";




import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Submitflagsucess from "../Submitflagsucess";
import Loading from "../Loading";
import Wentwrong from "../Wentwrong";
import Submitalreadysuccess from "../Submitalreadysuccess";
import Wrongflagsubmiited from "../Wrongflagsubmiited";
import Pleasestartchallenge from "../Pleasestartchallenge";
import Toomanysttemptsforflag from "../Toomanysttemptsforflag";


const Examsubmitflags = ({ setOpenModal, id }) => {
  const navigate = useNavigate();
  const [user, setuser] = useState([]);
  const [modalopen, setmodalopen] = useState(false);
  const [modalopen2, setmodalopen2] = useState(false);
  const [modalopen3, setmodalopen3] = useState(false);
  const [modalopen4, setmodalopen4] = useState(false);
  const [modalopen5, setmodalopen5] = useState(false);
  const [modalopen6, setmodalopen6] = useState(false);
  const [modalopen7, setmodalopen7] = useState(false);
  const API_Key = process.env.REACT_APP_API_URL;
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    trigger,
  } = useForm();

  const [Flag, setFlag] = useState({
    challengeid: id,
    flag: "",
  });

  const handleaddcat = (event) => {
    event.preventDefault();

    setFlag((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };
  const onSubmit = (data) => {
    setmodalopen2(true);

    const res = fetch(`${API_Key}/submit_exam_flag`, {
      method: "POST",
      body: JSON.stringify({
        examid: id,
        examflag: data.flag,
      }),
      headers: {
        "Content-Type": "application/json",
        mode: "cors",
        "Access-Control-Allow-Origin": `${API_Key}`,
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
      credentials: "include",
    }).then((res) => {
      if (res.status === 200) {
        setmodalopen(true);
        setmodalopen2(false);
      } else if (res.status === 403) {
        setmodalopen4(true);
        setmodalopen2(false);
      } else if (res.status === 404) {
        setmodalopen5(true);
        setmodalopen2(false);
      } else if (res.status === 405) {
        setmodalopen6(true);
        setmodalopen2(false);
      } else if (res.status === 429) {
        setmodalopen7(true);
        setmodalopen2(false);
      }
    });
  };

  return (
    <div>
      {modalopen && <Submitflagsucess setOpenModal={setmodalopen} />}
      {modalopen2 && <Loading setOpenModal={setmodalopen2} />}
      {modalopen3 && <Wentwrong setOpenModal={setmodalopen3} />}
      {modalopen4 && <Submitalreadysuccess setOpenModal={setmodalopen4} />}
      {modalopen5 && <Wrongflagsubmiited setOpenModal={setmodalopen5} />}
      {modalopen6 && <Pleasestartchallenge setOpenModal={setmodalopen6} />}
      {modalopen7 && <Toomanysttemptsforflag setOpenModal={setmodalopen7} />}
      <section className="modalBackground justify-content-center">
        <div className="container  justify-content-center">
          <div className="row justify-content-center ">
            <div className="col-lg-8 col-md-10 col-10  modalContainer">
              <div
                className="mt-5 crossbtnarrow "
                style={{ cursor: "pointer" }}
              >
                <button
                  onClick={() => {
                    setOpenModal(false);
                  }}
                >
                  <div className="text-center">
                    <ImCross
                      style={{
                        height: ".6rem",
                        marginLeft: "-1.9rem",
                        marginTop: ".5rem",
                      }}
                    />
                  </div>
                </button>
              </div>
              <h2 className="text-center alertmainhead2">
                Submit Exam Flag{" "}
                <div className="row justify-content-center">
                  <span>
                    <hr className="text-center alertcommonhr"></hr>
                  </span>
                </div>
              </h2>

              <div className="row justify-content-center">
                <div className="col-lg-10 col-md-6  col-9 ">
                  <p className="resetpara">
                    If challenge is complete please submit flag to gain
                    score/ranking otherwise it will be useless.
                  </p>

                  <div className="row justify-content-center mt-2">
                    <form
                      className="col-lg-12"
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <div className="form-input ">
                        <div className="col">
                          <input
                            name="flag"
                            type="text"
                            className={`form-control  ${
                              errors.flag && "invalid"
                            }`}
                            placeholder="XXXXX-XXXXX-XXXXX-XXXXX-XXXXX"
                            onChange={handleaddcat}
                            {...register("flag", {
                              required: "flag is Required",
                              pattern: /^[a-zA-Z .,_\n 0-9 {}]*$/,
                              message: "Flag is Required",
                            })}
                            onKeyUp={() => {
                              trigger("flag");
                            }}
                          />
                          {errors.flag && (
                            <small className="errormes ml-2 ">
                              {errors.flag.message}
                            </small>
                          )}
                        </div>
                      </div>
                      <div className="row justify-content-center">
                        <button className=" btn saveratebtn">
                          {" "}
                          SUBMIT FLAG
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Examsubmitflags;
