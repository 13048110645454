import React, { useState,useEffect } from 'react'
import 'swiper/css';


import {Swiper,SwiperSlide} from "swiper/react";

import quote from "../../Assets/testimonial/ios-quote.svg";

import { Pagination,EffectCoverflow } from "swiper";
import User from "../../Assets/images/IMG_1936.jpg"
import Moment from "react-moment";
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import employ from "../../Assets/Entity/HalloffameUI/asset6.png"
import vulnerbility from "../../Assets/Entity/HalloffameUI/icon1.png"
import sharevideo from "../../Assets/Entity/HalloffameUI/icon2.png";
import getintouch from "../../Assets/Entity/HalloffameUI/icon3.png"
import getintuch from "../../Assets/Entity/HalloffameUI/asset5.png";
import Record from "../../Assets/Landing page/arrow 2.png";
import mascot from "../../Assets/Entity/HalloffameUI/maleicon.png"
import femalemascot from "../../Assets/Entity/HalloffameUI/femaleicon.png"
import designation from "../../Assets/Entity/HalloffameUI/maincard1.png"
import linkedin from "../../Assets/Entity/HalloffameUI/maincard2.png"
import impact from "../../Assets/Entity/HalloffameUI/maincard3.png";
import location from "../../Assets/Entity/HalloffameUI/maincard4.png"

const Halloffameuser = () => {
    const[testimonial,settestimonial]=useState([]);
    const API_Key = process.env.REACT_APP_API_URL;
    const toUpperCaseFilter = (d) => {
        return d.toUpperCase();
    };
    useEffect(() => {
      const gettestimonial = async() => {
        try{
          const res = await fetch(`${API_Key}/gettestimonial`,{
              method:"GET",
              headers:{
                mode: 'cors',
                'Access-Control-Allow-Origin':`${API_Key}`,
                'Content-Type': 'application/json',
                'Access-Control-Allow-Credentials': true,
              },
              credentials:"include"
             
          });
          const data = await res.json([]);
         
          settestimonial(data);
        
      
          if(res.status !== 200 ){
              const error = new Error(res.error);
              throw error;
              
          }
          
       
      
      
      }catch(err){
        console.log(err);
        //navigate("/signin");
      
      } 
      
      
      };
  
      gettestimonial();
    }, []);
   
    useEffect(() => {
      window.scrollTo(0, 0)
    }, [])
  return (
    <>
  
    <section className="Dynamicbackground">
    <div className="container mt-5">
    <div class="Heading-main">
      <span className='spanleaderboard'>HALL OF FAME</span>
      <h2>HALL OF FAME</h2>
      <hr className="offset-md-5 offset-4"></hr>
    </div>
   
  </div>
    </section>
    

<br></br><br></br>
    <div className='container mt-5 mb-5'>
    
          
              


             <Swiper
             style={{
                "--swiper-pagination-color": "#e32f1c",
                "--swiper-pagination-bullet-inactive-color": "white",
                "--swiper-pagination-bullet-inactive-opacity": "2",
                "--swiper-pagination-bullet-size": "10px",
                "--swiper-pagination-bullet-margin":"10px",
                "--swiper-pagination-bullet-horizontal-gap": "6px"
           
              }}
             slidesPerView={"auto"}
             effect={"coverflow"}
             spaceBetween={0}
             grabCursor={true}
             pagination={{
               clickable: true,
              
               
             }}
             coverflowEffect={{
                rotate: 100,
                stretch: 0,
                depth: 100,
                modifier: 1,
                slideShadows: true,
              }}

             
              modules={[EffectCoverflow, Pagination]}


            
           >
        
         <div className='row '>
           <SwiperSlide>
           <div className='row mt-5'>
<br></br><br></br><br></br>
           <div className='col-lg-4 col-md-4 col-12'>
           <div className='card' id='halloffamecard2'>
        
         
       
           <div className='row justify-content-center  mb-2 p-0 pl-2 mt-5' id='uniadvantegphotodic2'>
           
           <img src={femalemascot}  style={{height:"20rem"}}  id="check" alt="curriculu"/>
           </div>
       
          
           <div className='row  p-4 mb-5' >
           <div className='col-12'>
           <div className='row'><p className='advantagehead4 pl-5' >Bipin Rai</p></div>
       
           <div className='row pl-5'>
           <div className='designationhead'><span><img src={designation} style={{height:"2rem",marginRight:"1rem"}} alt="src"/></span>Freelancer</div>    <div className='designationhead'><span><img src={location} style={{height:"2rem",marginRight:"1rem"}} alt="src"/></span>Karnataka</div>
          
           </div>
           <div className='row pl-5'>
          <a target='_blank' style={{textDecoration:"none"}} href='https://www.linkedin.com/in/bipin-rai-67ab65126/?originalSubdomain=in'><div className='designationhead pt-2'><span><img src={linkedin} style={{height:"2rem",marginRight:"1rem",marginTop:"-1rem"}} alt="src"/></span>Linkedin Account </div></a>
          
           </div>
           <div className='row pl-5'>
           <div className='designationhead'><span><img src={impact} style={{height:"2rem",marginRight:"1rem"}} alt="src"/></span>Impact Of vulnerability</div> 
          <div className='impactdiv3'><p>Low</p></div>
           </div>
           </div>
           
           
          
           
           </div>
           
          
           </div>
           </div>
           <div className='col-lg-4 col-md-4 col-12'>
           <div className='card' id='halloffamecard4'>
        
         
       
           <div className='row justify-content-center  mb-2 p-0 pl-2 mt-5' id='uniadvantegphotodic2'>
           
           <img src={femalemascot}  style={{height:"20rem"}}  id="check" alt="curriculu"/>
           </div>
       
          
           <div className='row  p-4 mb-5' >
           <div className='col-12'>
           <div className='row'><p className='advantagehead4 pl-5' >Vijay Sagoo</p></div>
       
           <div className='row pl-5'>
           <div className='designationhead'><span><img src={designation} style={{height:"2rem",marginRight:"1rem"}} alt="src"/></span>Pentester</div>    <div className='designationhead'><span><img src={location} style={{height:"2rem",marginRight:"1rem"}} alt="src"/></span>Chandigarh</div>
          
           </div>
           <div className='row pl-5'>
         <a  target='_blank' style={{textDecoration:"none"}} href='https://www.linkedin.com/in/vijay-sagoo-a90149193/'>  <div className='designationhead'><span><img src={linkedin} style={{height:"2rem",marginRight:"1rem",marginTop:"-1rem"}} alt="src"/></span>Linkedin Account</div></a>
          
           </div>
           <div className='row pl-5'>
           <div className='designationhead'><span><img src={impact} style={{height:"2rem",marginRight:"1rem"}} alt="src"/></span>Impact Of vulnerability</div> 
          <div className='impactdiv'><p>Hard</p></div>
           </div>
           </div>
           
           
          
           
           </div>
           
          
           </div>
           </div>
           <div className='col-lg-4 col-md-4 col-12'>
           <div className='card' id='halloffamecard3'>
        
         
       
           <div className='row justify-content-center  mb-2 p-0 pl-2 mt-5' id='uniadvantegphotodic2'>
           
           <img src={femalemascot}  style={{height:"20rem"}}  id="check" alt="curriculu"/>
           </div>
       
          
           <div className='row  p-4 mb-5' >
           <div className='col-12'>
           <div className='row'><p className='advantagehead4 pl-5' >Piyush Garg</p></div>
       
           <div className='row pl-5'>
           <div className='designationhead'><span><img src={designation} style={{height:"2rem",marginRight:"1rem"}} alt="src"/></span>Security Analyst</div>    <div className='designationhead'><span><img src={location} style={{height:"2rem",marginRight:"1rem"}} alt="src"/></span>Noida</div>
          
           </div>
           <div className='row pl-5'>
          <a  target='_blank' style={{textDecoration:"none"}} href='https://www.linkedin.com/in/piyush-garg-368101185/'> <div className='designationhead'><span><img src={linkedin} style={{height:"2rem",marginRight:"1rem",marginTop:"-1rem"}} alt="src"/></span>Linkedin Account</div></a>
          
           </div>
           <div className='row pl-5'>
           <div className='designationhead'><span><img src={impact} style={{height:"2rem",marginRight:"1rem"}} alt="src"/></span>Impact Of vulnerability</div> 
          <div className='impactdiv2'><p>Medium</p></div>
           </div>
           </div>
           
           
          
           
           </div>
           
          
           </div>
           </div>
           
           
           </div></SwiperSlide>
        
      
         
         

 
         
      
         
           </div>
         </Swiper>
         
   
               </div>

    <Footer/>
    
    </>
  )
}

export default Halloffameuser