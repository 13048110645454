import React from "react";
import "./IndustrialTraining.css";
import { motion } from "framer-motion";




const IndustrialBenefits = ({benefits,heading,description}) => {
  return (
    <div  className="industrial-benefits-container int-observe ind-highlights"  data-name="2">
      <motion.div  initial={{ opacity: 0.1,y:50 }}
      viewport={{once:true}}
          whileInView={{ opacity: 1,y:0 }}
          transition={{ duration: 0.7 }} className="heading-container">
        
        <p className="acwp-heading">
        {heading}
        </p>
        <div style={{display:'flex',justifyContent:'center'}}>
            <hr className="acwphr2"></hr>
          </div>
      </motion.div>
      <motion.p initial={{ opacity: 0.1,y:50 }}
          whileInView={{ opacity: 1,y:0 }}
          viewport={{once:true}}
          transition={{ duration: 0.7 }} className="description">
        {description}
      </motion.p>
      <motion.div initial={{ opacity: 0.1,y:50 }}
      viewport={{once:true}}
          whileInView={{ opacity: 1,y:0 }}
          transition={{ duration: 0.7 }} className="card-container">
        {benefits.map((x,index) => (
          <div key={index} className="card">
            <div className={`${index%2===0 ? 'bg-flip-purple' :'bg-flip-red'} card__content`}>
              <div className="card__front">
                <p className="card__subtitle">{x.subtitle}</p>
                <h3 className="card__title">{x.title}</h3>
              </div>

              <div className={`${index%2===0 ? 'bg-flip-red' :'bg-flip-purple'} card__back`}>
                <p className="card__body">
                {x.description}
                </p>
              </div>
            </div>
          </div>
        ))}
      </motion.div>
    </div>
  );
};

export default IndustrialBenefits;
