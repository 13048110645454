import React from 'react';
import { GrFormCheckmark } from "react-icons/gr";
const benefits=[
    'Access to industry-recognized certifications upon completion',
    'Supplementary videos and tools to support and enhance learning',
    'Updated Curriculum with the latest technologies and advancements',
    'Real-World Hacking Challenges',
    'Practice labs for practical application of theoretical knowledge',
    'Acceleration of career growth opportunities',
    'Personalized mentorship and guidance',
    'Cloud Access For Hacking Challenges',
    'Development of job-ready skills and competencies',
]

const TrainingHighlights = () => {
  return (
    <div className='training-highlight-section'>
        <div className='training-highlight-section-left'> 
        <h3 className='training-heading'>Benefits of Doing This Summer Training Program</h3>
        <p className='training-desc'>By enrolling in our summer internship in cyber security, you can unlock a host of advantages that will significantly enrich your educational journey and propel your career in the cybersecurity domain. Our intensive 6-week summer training in cybersecurity covers fundamental principles and an extensive array of topics, empowering you to cultivate essential skills. Engaging in our cybersecurity training program offers numerous benefits</p>
        </div>
        <div className='training-highlight-section-right'> 
        {benefits.map((x,index)=>(
 <div key={index} className="display-flex">
 <div className="lab-desc-list-icon-wrapper">
   <GrFormCheckmark className="lab-desc-list-icon" />
 </div>
 <p className="training-list">{x}</p>
</div>  
        ))}
       
               
        </div>
  
    </div>
  )
}

export default TrainingHighlights
