import React, { useEffect, useState } from "react";
import Congrats from "../../../Assets/Modals/Deactivate Account.png";
import DownloadFiles from "../../../Assets/Settings/Download Config.svg";
import Loading from "../Modals/Loading";
import Instancesuccess from "../Modals/Instancesuccess";
import Wentwrong from "../Modals/Wentwrong";
import Machinestartsuccess from "../Modals/Machinestartsuccess";
import Machinestopsuccess from "../Modals/Machinestopsuccess";
import Resetvpnsucces from "../Modals/Resetvpnsucces";
import Wentwrong2 from "../Modals/WentWrong2";
import Loading3 from "../Modals/Loading3";
import { useDispatch } from "react-redux";


const NetworkComponent = ({
  usedetail,
  file,
  setfile,
  scrf,
  instance,
  setinstance,
  setRerenderP,
}) => {
  const dispatch=useDispatch();
  const API_Key = process.env.REACT_APP_API_URL;
  const [refr, setrefr] = useState(false);
  const [instanceitem, setinstanceItem] = useState("");
  const [modalOpen6, setModalOpen6] = useState(false);
  const [modalOpen5, setModalOpen5] = useState(false);
  const [modalOpen8, setModalOpen8] = useState(false);
  const [modalOpen9, setModalOpen9] = useState(false);
  const [modalOpen7, setModalOpen7] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [wentWrong2model, setWentWrong2Model] = useState(false);
  const [modalOpen2, setModalOpen2] = useState(false);
  const Createinstance = async (event) => {
    setModalOpen6(true);
    event.preventDefault();
    fetch(`${API_Key}/create_instance`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        mode: "cors",
        "X-CSRF-TOKEN": scrf,
        "Access-Control-Allow-Origin": `${API_Key}`,
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
      credentials: "include",
    })
      .then(async (response) => {
        console.log(response.status, "mmmmmmmmmmmmmmmmmmmmm");
        if (response.status === 402) {
          setModalOpen6(false);
          const data = await response.json();
          setWentWrong2Model(true);
          setErrorMsg(data.message);

          console.log("C!");
        }
        if (response.status === 404) {
          setModalOpen6(false);
          const data = await response.json();
          setWentWrong2Model(true);
          setErrorMsg(data.message);
          setRerenderP();
          setrefr(!refr);
        }
        if (response.status === 403) {
          setModalOpen6(false);
          setWentWrong2Model(true);
          const data = await response.json();
          setErrorMsg(data.message);
          console.log("C2");
        }
        if (response.status === 200) {
          setModalOpen5(true);
          setModalOpen6(false);
          setRerenderP();
          setrefr(!refr);

          const noti = {
            message: "Your Instance  has been Created Successfully . ",
            category: "Settings",
            querytype: "Instance Created",
          };

          fetch(`${API_Key}/notification`, {
            method: "POST",
            headers: {
              Accept: "application/json",
              mode: "cors",

              "Access-Control-Allow-Origin": `${API_Key}`,
              "Content-type": "application/json",

              "Access-Control-Allow-Credentials": true,
            },
            credentials: "include",
            body: JSON.stringify(noti),
          });
        }
      })

      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
        setModalOpen2(true);
      });
  };
  const startinstance = async (event) => {
    setModalOpen6(true);
    event.preventDefault();
    fetch(`${API_Key}/create_start_instance`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        mode: "cors",
        "X-CSRF-TOKEN": scrf,
        "Access-Control-Allow-Origin": `${API_Key}`,
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
      credentials: "include",
    })
      .then(async (response) => {
        if (response.status === 402) {
          setModalOpen6(false);
          setWentWrong2Model(true);
          const data = await response.json();
          setErrorMsg(data.message);
        }
        if (response.status === 404) {
          setModalOpen6(false);
          const data = await response.json();
          setWentWrong2Model(true);
          setErrorMsg(data.message);
          setRerenderP();
          setrefr(!refr);
        }
        if (response.status === 403) {
          setModalOpen6(false);
          setWentWrong2Model(true);
          const data = await response.json();
          setErrorMsg(data.message);
        }
        if (response.status === 200) {
          setModalOpen8(true);
          setModalOpen6(false);
          setTimeout(() => {
            setModalOpen5(false);
          }, 7000);
        }
      })

      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
        setModalOpen2(true);
      });
  };
  const stopinstance = async (event) => {
    setModalOpen6(true);
    event.preventDefault();
    fetch(`${API_Key}/stop_instance`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        mode: "cors",
        "X-CSRF-TOKEN": scrf,
        "Access-Control-Allow-Origin": `${API_Key}`,
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
      credentials: "include",
    })
      .then(async (response) => {
        if (response.status === 402) {
          setModalOpen6(false);
          setWentWrong2Model(true);
          const data = await response.json();
          setErrorMsg(data.message);
        }
        if (response.status === 403) {
          setModalOpen6(false);
          setWentWrong2Model(true);
          const data = await response.json();
          setErrorMsg(data.message);
        }
        if (response.status === 404) {
          setModalOpen6(false);
          const data = await response.json();
          setWentWrong2Model(true);
          setErrorMsg(data.message);
          setRerenderP();
          setrefr(!refr);
        }
        if (response.status === 200) {
          setModalOpen9(true);
          setModalOpen6(false);
          setTimeout(() => {
            setModalOpen5(false);
          }, 7000);
        }
      })

      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
        setModalOpen2(true);
      });
  };
  const Resetvpn = async () => {
    setModalOpen6(true);
    fetch(`${API_Key}/terminate_instance`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        mode: "cors",
        "X-CSRF-TOKEN": scrf,
        "Access-Control-Allow-Origin": `${API_Key}`,
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
      credentials: "include",
    })
      .then(async (response) => {
        if (response.status === 402) {
          const data = await response.json();
          setErrorMsg(data.message);
          setModalOpen6(false);
          setWentWrong2Model(true);
        }
        if (response.status === 403) {
          const data = await response.json();
          setErrorMsg(data.message);
          setModalOpen6(false);
          setWentWrong2Model(true);
        }
        if (response.status === 404) {
          setModalOpen6(false);
          const data = await response.json();
          setWentWrong2Model(true);
          setErrorMsg(data.message);
          setRerenderP();
          setrefr(!refr);
        }
        if (response.status === 200) {
          setModalOpen7(true);
          setModalOpen6(false);
          setRerenderP();
          setrefr(!refr);

          const noti = {
            message: "Your VPN has been Reset Successfully . ",
            category: "Settings",
            querytype: "VPN Reset",
          };

          fetch(`${API_Key}/notification`, {
            method: "POST",
            headers: {
              Accept: "application/json",
              mode: "cors",
              "Access-Control-Allow-Origin": `${API_Key}`,
              "Content-type": "application/json",

              "Access-Control-Allow-Credentials": true,
            },
            credentials: "include",
            body: JSON.stringify(noti),
          });
        }
      })

      //Then with the error genereted...
      .catch((error) => {
        setModalOpen2(true);
      });
  };
  const Downloadstackvpn = async (event) => {
    setModalOpen6(true);
    event.preventDefault();
    fetch(`${API_Key}/download-vpn-config`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        mode: "cors",
        "X-CSRF-TOKEN": scrf,
        "Access-Control-Allow-Origin": `${API_Key}`,
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
      credentials: "include",
    })
      .then(async (response) => {
        if (response.status === 402) {
          const data = await response.json();
          setErrorMsg(data.message);
          setModalOpen6(false);
          setWentWrong2Model(true);
        }
        if (response.status === 404) {
          setModalOpen6(false);
          const data = await response.json();
          setWentWrong2Model(true);
          setErrorMsg(data.message);
          setRerenderP();
          setrefr(!refr);
        }
        if (response.status === 403) {
          const data = await response.json();
          setErrorMsg(data.message);

          setModalOpen6(false);
          setWentWrong2Model(true);
        }
        if (response.status === 200) {
          const blob = await response.blob();

          // Create a link element and trigger the download
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = "stack-exploit.ovpn"; // Set the desired file name
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
          setModalOpen5(true);
          setModalOpen6(false);
          setTimeout(() => {
            setModalOpen5(false);
          }, 7000);
          const noti = {
            message: "Your Instance  has been Created Successfully . ",
            category: "Settings",
            querytype: "Instance Created",
          };

          fetch(`${API_Key}/notification`, {
            method: "POST",
            headers: {
              Accept: "application/json",
              mode: "cors",

              "Access-Control-Allow-Origin": `${API_Key}`,
              "Content-type": "application/json",

              "Access-Control-Allow-Credentials": true,
            },
            credentials: "include",
            body: JSON.stringify(noti),
          });
        }
      })

      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
        setModalOpen2(true);
      });
  };
  console.log(instance, "instance is inside");

  return (
    <>
      {modalOpen6 && <Loading3 setOpenModal={setModalOpen6} />}
      {modalOpen5 && <Instancesuccess setOpenModal={setModalOpen5} />}
      {modalOpen2 && <Wentwrong setOpenModal={setModalOpen2} />}
      {modalOpen8 && <Machinestartsuccess setOpenModal={setModalOpen8} />}
      {modalOpen9 && <Machinestopsuccess setOpenModal={setModalOpen9} />}
      {modalOpen7 && <Resetvpnsucces setOpenModal={setModalOpen7} />}
      {wentWrong2model && (
        <Wentwrong2
          setWentWrong2ModelC={() => setWentWrong2Model(false)}
          errorMsg={errorMsg}
        />
      )}
      <div>
        <div className="container">
          <div className="row p-3">
            <div className="col-lg-12 col-md-6 col-12  ">
              {usedetail?.allcourseData?.length === 0 ? (
                <div className="row justify-content-center ">
                  <div
                    className="col-lg-9 col-md-10 col-10 p-1 mt-4 "
                    id="istancebtndiv"
                  >
                    <div className="row p-5">
                      <div className="col-lg-4 col-md-5 col-4  ">
                        <div className="justify-content-center pl-5">
                          <img
                            src={Congrats}
                            style={{ height: "45vh" }}
                            alt="sorry"
                          />
                        </div>
                      </div>
                      <div className="col-lg-8 col-md-5 col-8   ">
                        <div className="mt-2 crossbtnarrow"></div>
                        <div className="justify-content-center">
                          <h2 className="text-center alertmainhead2">
                            Unlock Premium Content!!
                            <div className="row justify-content-center">
                              <span>
                                <hr className="text-center alertcommonhr"></hr>
                              </span>
                            </div>
                          </h2>
                        </div>
                        <p className="resetpara">
                          Unlock the full potential of our Networks module with
                          premium access! Upgrade now for exclusive features and
                          enhanced network capabilities.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <div
                    className="row justify-content-center"
                    id="istancebtndiv"
                  >
                    {usedetail?.allcourseData?.map((x) => {
                      if (x.coursename === "WebPwn") {
                        return (
                          <div
                            className="col-lg-6 col-md-6 col text-center"
                            onClick={() => setinstanceItem("A")}
                          >
                            <button
                              className={
                                instanceitem === "A"
                                  ? "btn instancedivbtn"
                                  : "btn instancedivbtn2"
                              }
                            >
                              Webpwn
                            </button>
                          </div>
                        );
                      } else {
                        return (
                          <div
                            className="col-lg-6 col-md-6 col text-center"
                            onClick={() => setinstanceItem("B")}
                          >
                            <button
                              className={
                                instanceitem === "B"
                                  ? "btn instancedivbtn"
                                  : "btn instancedivbtn2"
                              }
                            >
                              StackXploit
                            </button>
                          </div>
                        );
                      }
                    })}
                  </div>
                  {instanceitem === "A" && (
                    <div id="istancebtndiv2">
                      {" "}
                      <div className="row mt-5 ">
                        <div className="col-lg-9 col-12">
                          <h2 className="setttingdiv2head">
                            Download Configuration File
                          </h2>
                          <p className="setttingdiv2para">
                            If you want to run the challenges in PreyGround,
                            please download and connect to VPN before starting
                            your challenges. If you have not subscribed to the
                            course, please subscribe first to get access to the
                            challenges
                          </p>
                        </div>
                        <div className="col-lg-3 col-10 mt-3 mb-3">
                          {file === "" ? (
                            <button
                              className="btn deactivatebtn2"
                              disabled="true"
                            >
                              <span>
                                <img
                                  src={DownloadFiles}
                                  style={{
                                    display: "inline",
                                    height: "1.5rem",
                                    marginRight: ".3rem",
                                  }}
                                  alt="src"
                                />
                                Download
                              </span>{" "}
                            </button>
                          ) : (
                            <a href={file} download>
                              <button className="btn deactivatebtn2">
                                <span>
                                  <img
                                    src={DownloadFiles}
                                    style={{
                                      display: "inline",
                                      height: "1.5rem",
                                      marginRight: ".3rem",
                                    }}
                                    alt="src"
                                  />
                                  Download
                                </span>{" "}
                              </button>
                            </a>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  {instanceitem === "B" && (
                    <div id="istancebtndiv2">
                     
                        <div>
                          <div className="row mt-5 ">
                            <div className="col-lg-9  col-12">
                              <h2 className="setttingdiv2head">
                                Start Machine
                              </h2>
                              <p className="setttingdiv2para">
                                To launch the attacker & victim machine, click
                                the START button.{" "}
                              </p>
                            </div>

                            <div className="col-lg-3 col-10 mt-3 mb-3">
                              <button
                                className="btn deactivatebtn2"
                                onClick={startinstance}
                              >
                                <span> Start Machine</span>
                              </button>
                            </div>
                          </div>

                          <div className="row mt-5 ">
                            <div className="col-lg-9  col-12">
                              <h2 className="setttingdiv2head">Stop Machine</h2>
                              <p className="setttingdiv2para">
                                To stop the attacker & victim machine, click the
                                STOP button .
                              </p>
                            </div>
                            <div className="col-lg-3 col-10 mt-3 mb-3">
                              <button
                                className="btn deactivatebtn2"
                                onClick={stopinstance}
                              >
                                <span>Stop Machine</span>
                              </button>
                            </div>
                          </div>

                          <div className="row mt-5">
                            <div className="col-lg-9 col-12">
                              <h2 className="setttingdiv2head">
                                Reset Infrastructure
                              </h2>
                              <p className="setttingdiv2para">
                                Clicking the RESET INFRA button will delete your
                                current attacker and victim machines, and new
                                machines will be created.{" "}
                              </p>
                            </div>
                            <div className="col-lg-3 col-10 mt-3 mb-3">
                              <button
                                className="btn deactivatebtn2"
                                onClick={Resetvpn}
                              >
                                <span>RESET INFRA</span>
                              </button>
                            </div>
                          </div>
                          <div className="row mt-5 ">
                            <div className="col-lg-9 col-12">
                              <h2 className="setttingdiv2head">
                                Download VPN Config File
                              </h2>
                              <p className="setttingdiv2para">
                                After launching the machines, download the VPN
                                Config File and use an OPEN VPN CLIENT to
                                connect to the network of attacker and victim
                                machines.
                              </p>
                            </div>
                            <div className="col-lg-3 col-10 mt-3 mb-3">
                              <button
                                className="btn deactivatebtn2"
                                onClick={Downloadstackvpn}
                              >
                                <span>
                                  <img
                                    src={DownloadFiles}
                                    style={{
                                      display: "inline",
                                      height: "1.5rem",
                                      marginRight: ".3rem",
                                    }}
                                    alt="src"
                                  />
                                  Download
                                </span>{" "}
                              </button>
                            </div>
                          </div>
                        </div>
                      
                    </div>
                  )}
                </>
              )}

              {/*<div className='row mt-5'>
               <div className='col-lg-9  col-8'>
           <h2 className='setttingdiv2head'>Reset Configuration File</h2>
           <p className='setttingdiv2para'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
           
               </div>
               <div className='col-lg-3 col-4'>
               <div className='settinghoverbtn ' onClick={Resetvpn} >
            
               <img src={ResetVpn} style={{height:"2rem",marginTop:"-1rem"}} alt="resetvpn" />
               
             
               </div>
          
               </div>
               
          </div>*/}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NetworkComponent;