import React, { useState, useEffect } from 'react';
import { RiArrowDropRightLine } from "react-icons/ri";
import vip from "../../../Assets/prodile/VIP.svg";
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import Loading from '../Modals/Loading';
import Paymentidverify from '../Modals/Paymentidverify';
// Import background images
import Activedirectrybackground from "../../../Assets/courses/new cards/Active Directory/bg.png";
import Netwrkscaanningbg from "../../../Assets/courses/new cards/network scanning/bg.png";
import webexploit from "../../../Assets/courses/new cards/web exploitation/bg.png";
import activedirename from "../../../Assets/courses/new cards/Active Directory/name.png";
import netwrkscan from "../../../Assets/courses/new cards/network scanning/name.png";
import webexploitname from "../../../Assets/courses/new cards/web exploitation/name.png";
const Dashmastercourses = (props) => {
  const params = useParams();
  const [assocourse, setassocourse] = useState([]);
  const [modal, setopenmodal] = useState(false);
  const [modal2, setopenmodal2] = useState(false);

  const API_Key = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  console.log("started hit");

  const fetchCourseaccess = async () => {
    setopenmodal(true);
    let course_id = params.course_id;

    try {
      const res = await fetch(`${API_Key}/mastecourseaccess/` + course_id, {
        method: "GET",
        headers: {
          mode: "cors",
          "Access-Control-Allow-Origin": `${API_Key}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Credentials": true,
        },
        credentials: "include",
      });
      const data = await res.json();
      console.log(data.associatedCourses, "sukhdata");
      setassocourse(data.associatedCourses);
      setopenmodal(false);

      if (res.status !== 200) {
        const error = new Error(res.error);
        throw error;
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchCourseaccess();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Function to get background image based on course name
  const getCourseBackgroundImage = (courseName) => {
    if (courseName.includes("Active Directory")) {
      return `url(${Activedirectrybackground})`; // Background image for Active Directory
    } else if (courseName.includes("Network Scanning")) {
      return `url(${Netwrkscaanningbg})`; // Background image for Network Scanning
    } else if (courseName.includes("Web Exploitation")) {
      return `url(${webexploit})`; // Background image for Web Exploitation
    }
    return "#FFFFFF"; // Default background color if no match
  };
  // Function to get course name image based on course name
  const getCourseNameImage = (courseName) => {
    if (courseName.includes("Active Directory")) {
      return activedirename; // Image for Active Directory
    } else if (courseName.includes("Network Scanning")) {
      return netwrkscan; // Image for Network Scanning
    } else if (courseName.includes("Web Exploitation")) {
      return webexploitname; // Image for Web Exploitation
    }
    return null; // Default if no match
  };

  return (
    <div>
      {modal && <Loading setOpenModal={setopenmodal} />}
      {modal2 && <Paymentidverify setOpenModal={setopenmodal2} />}

      <div className="background_dashboard p-1">
        <div className="Entitycommontopper">
          <div className="container">
            <div className="row justify-content-center p-0">
              <div className="col-lg-8 col-md-8 col-12">
                <h1 className="Entitycommonhead">ACW<span><hr className="Entitycommonheadhr" /></span></h1>
                <p className="Entitycommonheadpara">
                  We believe in practical knowledge, performing challenges, identifying issues, and above all self-learning. Instead of focusing only on theoretical knowledge in our web penetration testing course, we have come up with real-world challenges to make you a certified professional.
                </p>
              </div>
              <div className="col-lg-3 col-md-4 col-12 mt-3 offset-1">
                <img src='/Courses Tri.svg' alt="Courses" style={{ backgroundSize: "cover" }} />
              </div>
            </div>
          </div>
        </div>

        {/* Grid View */}
      {/* Grid View */}
      <div className="container mt-5 p-0">
      <div className="row">
        {assocourse.map((course, id) => (
          <div className="col-lg-4 col-md-6 col-12 p-3" key={id}> {/* Adjusted column size for better layout */}
            <NavLink to={course.is_mastercourse ? "/setting/" + course._id : "/coursedesc/" + course._id}>
              <div
                style={{
                  backgroundImage: getCourseBackgroundImage(course.coursename),
                  backgroundSize: 'cover',
                  backgroundPosition: 'center', // Ensures the background image stays centered
                  borderRadius: "10px",
                  padding: "0",
                  marginBottom: "20px",
                  overflow: "hidden",
                  display: 'flex',
                  flexDirection: 'column',
                  height:"85vh", // Set a fixed minimum height for the card
                }}
              >
                <div className="row no-gutters">
                 
                  <div className="col-lg-12">
               <div className='row justify-content-center'>
               <img
               src={getCourseNameImage(course.coursename)}
               alt={course.coursename}
               style={{
                 maxWidth: '80%',
                 height: 'auto',
                 padding: "20px",
                 marginTop:"30px",
           
                 objectFit: "cover",
               }}
             />
               </div>
                    {/* Course Cover Image */}
                    <div className="mastercourseimage" style={{ padding: 0, margin: 0 }}>
                      <img
                        src={course.cover_image}
                        alt="course"
                        style={{
                          display: 'block',
                          width: '100%',
                          height: 'auto',
                          objectFit: "contain", 
                        position:"bottom",
                        padding:"0rem",
                        marginTop:"5.5rem"
                       
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </NavLink>
          </div>
        ))}
      </div>
    </div>
    

      
      
      </div>
    </div>
  );
};

export default Dashmastercourses;