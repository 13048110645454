import React, { useEffect, useState } from "react";
import Footer from "../Footer/Footer";
import Navbar from "../Navbar/Navbar";
import webpwnimg from "../../Assets/Entity/webpwnui/mainlogo.png";
import Record from "../../Assets/Landing page/arrow 2.png";
import email1 from "../../Assets/Dashboard/Mails/Screenshot 2023-06-12 at 9.30.18 AM.png";
import { NavLink } from "react-router-dom";
import Dot from "../../Assets/images/Activity.svg";
import "../Dashboard/Feedback/Common.css";
import { Helmet } from "react-helmet";

const getColorClassBasedOnValidity = (validity) => {
    switch (validity) {
        case 1:
            return "card-basic";
        case 2:
            return "card-vip";
        case 3:
            return "card-enterprise";
        default:
            return "";
    }
};
const convertValidityToDays = (validity) => {
    switch (validity) {
        case 1:
            return 30;
        case 2:
            return 60;
        case 3:
            return 90;
        default:
            return 0;
    }
};
const getColorbuttonClassBasedOnValidity = (validity) => {
    switch (validity) {
        case 1:
            return "basicplanbutton";
        case 2:
            return "vipplanbutton";
        case 3:
            return "enterpriseplanbutton";
        default:
            return "";
    }
};
const getColorspanClassBasedOnValidity = (validity) => {
    switch (validity) {
        case 1:
            return "yellowcolor";
        case 2:
            return "bluecolor";
        case 3:
            return "greencolor";
        default:
            return "";
    }
};
const API_Key = process.env.REACT_APP_API_URL;
function AcwPrize() {


    const [clicked, setClicked] = useState(false);
    const [plans, setplans] = useState([]);

    const toggle = (index) => {
        if (clicked === index) {
            return setClicked(null);
        }

        setClicked(index);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    useEffect(() => { }, []);
    const getplans = async () => {
        let courseid = "66dbdea0b35516d17bdead56";
        try {
            const res = await fetch(`${API_Key}/getplanwithoutauth/` + courseid, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": `${API_Key}`,
                    "Access-Control-Allow-Credentials": "true",
                },
                credentials: "include",
            });
            const data = await res.json([]);
            console.log(data, "data");
            const order = [1, 3, 2];
            data.sort(
                (a, b) => order.indexOf(a.validity) - order.indexOf(b.validity)
            );
            setplans(data);
            if (res.status !== 200) {
                const error = new Error(res.error);
                throw error;
            }
        } catch (err) {
            console.log(err);
        }
    };
    useEffect(() => {
        getplans();
    }, []);

    const sortedPlans = [
        ...plans?.filter((plan) => plan.validity === 1),
        ...plans?.filter((plan) => plan.validity === 3),
        ...plans?.filter((plan) => plan.validity === 2),
    ];



    return (
        <div>
            <section className="webpwnmainprize mb-5">
                <div className="container">
                    <div className="row justify-content-center ">
                        <h2 className="webpwnhead10 ">Choose the right plan for you</h2>
                    </div>
                    <div className=" row justify-content-center mt-2 ">
                        <hr className="universityhr"></hr>
                    </div>
                    <br></br>
                    <br></br>
                    <br></br>

                    <div className="row justify-content-center p-1  ">
                        {sortedPlans?.map((plans, id) => (
                            <div className="col-lg-3 col-10 m-2" key={id}>
                                <div
                                    className={`p-3 ${getColorClassBasedOnValidity(
                                        plans?.validity
                                    )}`}
                                >
                                    <div className="row justify-content-center">
                                        <div className="col-lg-5 col mt-3">
                                            {" "}
                                            <img
                                                src={plans?.image}
                                                alt="cyber security online course"
                                            />
                                        </div>
                                        <div className="col-lg-7 col pt-3 mt-3">
                                            {" "}
                                            <h2 className="subplanhead">{plans.name}</h2>
                                            <h6 className="subplanpara ">
                                                <span
                                                    className={`btn  ${getColorspanClassBasedOnValidity(
                                                        plans?.validity
                                                    )}`}
                                                >
                                                    ({convertValidityToDays(plans.validity)} days)
                                                </span>
                                            </h6>
                                        </div>
                                    </div>
                                    <div className="row  justify-content-center mt-2">
                                        <div className="col-lg-12">
                                            <hr className="subplanhr"></hr>
                                        </div>
                                    </div>
                                    <div className="row  justify-content-center mt-1">
                                        <div className="col-lg-12 col-12 ">
                                            {plans.features &&
                                                plans.features.map((feature, id) => (
                                                    <ul>
                                                        <li className="subplanfeaturepara">
                                                            <span className="mr-3">
                                                                {" "}
                                                                <img
                                                                    src={Dot}
                                                                    alt="cyber security online certification"
                                                                    style={{ height: "1.5rem" }}
                                                                />
                                                            </span>
                                                            {feature.feature}
                                                        </li>
                                                    </ul>
                                                ))}
                                        </div>
                                    </div>
                                    <div className="row justify-content-center ">
                                        <div className="col-lg-8 mb-5">
                                            <NavLink to={"/checkout/" + plans._id}>
                                                <button
                                                    className={`btn  ${getColorbuttonClassBasedOnValidity(
                                                        plans.validity
                                                    )}`}
                                                >
                                                    RS.{plans.price}/-
                                                </button>
                                            </NavLink>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>

            <Footer />
        </div>

    )
}

export default AcwPrize

